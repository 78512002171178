import Cookies from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getUserViews, validateLogin } from "../../api/authenitcationApi";
import "../../assets/styles/main.scss";
import "../../assets/styles/pages/login.scss";
import { LoginForm } from "../../components/Forms";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { setAppHeight } from "./subs/helpers";

export const Login = withRouter((props: RouteComponentProps): JSX.Element => {
  const { location, history } = props;
  const [user, setFormUser] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setAppHeight();
    if (typeof window !== "undefined") {
      // @ts-ignore
      window._chatlio.hide();
    }
  }, []);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = evt;
    setFormUser({ ...user, [name]: value });
  };

  const handleSubmit = useCallback(
    () => (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);

      const { target } = event;
      const formData = new FormData(target as HTMLFormElement);
      //@ts-ignore
      const formObj: Record<string, string> = Object.fromEntries(formData);

      validateLogin(formObj)
        .then((response) => {
          if ("error" in response) {
            switch (response.error) {
              case "invalid_username":
              case "invalid_password":
                setError("Invalid credentials, please ensure that you have entered the correct username and password.");
                break;
              case "account_frozen":
                setError('Oops, you\'ve had too many failed login attempts! Please click on "Forgotten your password"');
                setDisabled(true);
                break;
              default:
                setError(response.error);
            }
          } else {
            const { preferred_url } = response;
            const { search } = location;
            const searchParams = new URLSearchParams(search);
            const redirectValue = searchParams.get("redirect");

            for (const [key, value] of Object.entries(response)) {
              // Sets cookie to expire a few minutes less than a day (approx. 3 mins less)
              // This is to prevent ever making api calls with an expired token.
              // HACK: To mitigate network delay in setting up the cookie in the frontend
              Cookies.set(key, value as string, { expires: 0.998, secure: true, sameSite: "strict" });
            }

            // Flash messages shown on every login if it exists
            // Track so we don't show every time a page is reloaded
            localStorage.setItem("latestFlashMsgSeen", "false");
            localStorage.setItem("expiredToken", "false");

            eventTracking(MixpanelEvents.login);

            // Set user views
            getUserViews(response.email, response.access_token)
              .then((userDetails) => {
                const userGroup = userDetails.views[0].name;
                let userViews = [
                  ...userDetails.views.flatMap((row) => row.views.map(({ view }: { view: string }) => view)),
                  "user_profile",
                  "profile_preferences",
                ];

                if (userViews.includes("admin")) {
                  userViews = userViews.reduce((result: string[], view) => {
                    if (view !== "contact") result.push(view);
                    if (view === "info") {
                      result.push(view);
                    }

                    return result;
                  }, []);
                }

                userViews.push("theme_btn");
                userViews.push("release_notes");
                userViews.push("visit_to_lead_tool")
                userViews.push("serious_buyer")
                userViews.push("clv")
                userViews.push("streamlit");

                Cookies.set("market_group", userDetails.groups.map((group) => group.name).join(","));
                Cookies.set("group", userGroup, { secure: true, sameSite: "strict" });
                Cookies.set("views", JSON.stringify(userViews), { secure: true, sameSite: "strict" });
              })
              .then(() => {
                if (redirectValue) {
                  const [redirectPathname, redirectSearchString] = redirectValue?.split("?") as Array<string>;
                  history.push({ pathname: redirectPathname, search: redirectSearchString });
                } else {
                  if (preferred_url) {
                    const [pathname, searchString] = preferred_url.split("?");
                    history.push({ pathname, search: `?${searchString}` });
                  } else {
                    const pathname = "/";
                    history.push({ pathname });
                  }
                }
                window.location.reload();
              });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [setError, history, location]
  );

  return (
    <div className={"pageWrapper"} id={"loginPage"}>
      <div className="dashboard" id="login_dashboard">
        <p id="IESupportMessage" />
        <div id="container">
          <LoginForm onChange={handleChange} onSubmit={handleSubmit()} user={user} error={error} loading={loading} disabled={disabled} />
        </div>
      </div>
    </div>
  );
});
