export const VOC_BTN_FILTERS = [
  {
    id: "website_btn",
    name: "Website",
    navigateTo: "/voc/website",
    tooltipText: "Customers satisfaction and commentary on multiple pages within the Nissan website for AMIEO markets.",
  },
  {
    id: "ccs_btn",
    name: "CCS Vital Signs",
    navigateTo: "/ccs_vital_signs/app_ratings",
  },
  {
    id: "quick_voc_sales_btn",
    name: "Quick VOC Sales",
    navigateTo: "/voc/quick_voc_sales",
    tooltipText: "Customers satisfaction on Sales within AMIEO markets, exploring data by market, dealer and model.",
  },
  {
    id: "quick_voc_after_sales_btn",
    name: "Quick VOC After Sales",
    navigateTo: "/voc/quick_voc_after_sales",
    tooltipText: "Customers satisfaction on Services within AMIEO markets, exploring data by market, dealer and model.",
  },
  {
    id: "cec_voc_btn",
    name: "CEC VOC",
    navigateTo: "/voc/cec_voc",
    tooltipText: "Customers satisfaction with Customer Experience Center in AMIEO markets.",
  },
  {
    id: "voc_rsa_btn",
    name: "VOC RSA",
    navigateTo: "/voc/voc_rsa",
  },
  {
    id: "google_rating_btn",
    name: "Google Rating",
    navigateTo: "/voc/google_rating",
  },
  {
    id: "some_btn",
    name: "SoMe",
    navigateTo: "/voc/some",
  },
  {
    id: "customer_journey_voc_btn",
    name: "Customer Journey VOC",
    navigateTo: "/voc/customer_journey_voc",
    tooltipText:
      "Summary view of all VOC data (TRY, BUY, and USE combined) to compare and analyze the reviews from different journeys in one page.",
  },
];

export const TOP_CATEGORIES = ["overall rating", "people stated they reached their objective on the website", "vlp"];

export const DEALER_DEEP_DIVE_METRICS = [
  "Top 5 dealers by rating",
  "Bottom 5 dealers by rating",
  "Top 5 dealers by volume",
  "Bottom 5 dealers by volume",
  "Top 5 dealers by Resp%",
  "Bottom 5 dealers by Resp%",
];

export const MODEL_DEEP_DIVE_METRICS = [
  "All models",
  "Top 5 models by rating",
  "Bottom 5 models by rating",
  "Top 5 models by volume",
  "Bottom 5 models by volume",
  "Top 5 models by Resp%",
  "Bottom 5 models by Resp%",
];
