import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getMarketDeepDiveTable } from "../../../../api/quickVocSalesApi";
import { useSortBy, useTable } from "react-table";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { NoDataMsg } from "../../../../components/AppMessages";

interface Props {
  queryFn: () => Record<string, any>;
  columnsToExclude?: Array<string>;
}

const MarketDeepDiveTable = withRouter((props: RouteComponentProps & Props) => {
  const { history, queryFn, columnsToExclude } = props;

  const [tableData, setTableData] = useState<Array<any>>([]);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["marketDeepDiveTableData", history.location.search],
    queryFn: queryFn,
    initialData: [],
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  useEffect(() => {
    if (data instanceof Array) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const cols = useMemo(
    () => [
      {
        Header: "",
        accessor: "market",
      },
      {
        Header: "Volume of Responses",
        accessor: "volume",
        Cell: ({ value }: { value: number | null; row: any }) => {
          return getValueCellContent(value);
        },
      },
      {
        Header: "Resp%",
        accessor: "response_rate",
        Cell: ({ value }: { value: number | null; row: any }) => {
          return getValueCellContent(value, true);
        },
      },
      {
        Header: "Monthly Satisfaction Score / R3M Satisfaction Score",
        accessor: "monthly_satisfaction_score",
        Cell: ({ value, row: { original } }: { value: number | null; row: any }) => {
          return (
            <div className="satisfaction-score-cell">
              <Ratings
                value={original?.pts_r3m_satisfaction_score ? original?.pts_r3m_satisfaction_score : "n/a"}
                isPercentageValue={false}
              />
              <span
                className={`${original?.monthly_satisfaction_score >= 4 ? "text-green" : original?.monthly_satisfaction_score >= 3.9 ? "text-amber" : original?.monthly_satisfaction_score >= 1 ? "text-red" : ""
                  }`}
              >
                {original?.monthly_satisfaction_score?.toFixed(2)}
              </span>
              <span>&nbsp;/&nbsp;</span>
              <span
                className={`${original?.r3m_satisfaction_score >= 4 ? "text-green" : original?.r3m_satisfaction_score >= 3.9 ? "text-amber" : original?.r3m_satisfaction_score >= 1 ? "text-red" : ""
                  }`}
              >
                {original?.r3m_satisfaction_score?.toFixed(2)}
              </span>
            </div>
          );
        },
      },
    ].filter(col => columnsToExclude ? !columnsToExclude.includes(col.Header) : true),
    [tableData]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: cols,
    data: tableData,
  },
    useSortBy
  );

  const getValueCellContent = (value: number | null, isPercentage?: boolean) => {
    return <span>{value == null ? "" : `${isPercentage ? value.toFixed(2) : value.toLocaleString()}${isPercentage ? "%" : ""}`}</span>;
  };

  return (
    <div className="market-deep-dive-table-container">
      {isFetching ? (
        <LoadingEllipsis isLoading={isFetching} />
      ) : tableData && tableData.length > 0 ? (
        <table {...getTableProps()} className="market-deep-dive-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                {headerGroup.headers.map((column) => {
                  const sortClass =
                    column.canSort
                      ? column?.isSorted
                        ? column.isSortedDesc
                          ? "down"
                          : "up"
                        : ""
                      : "disabled";

                  return (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.getHeaderProps().key}>
                      <div className={`sortable ${sortClass}`} style={{ display: "flex" }}>
                        {column.render("Header")}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.getRowProps().key}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <NoDataMsg />
      )}
    </div>
  );
});

export default MarketDeepDiveTable;
