import { capitalize } from "lodash";
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { setSpecificParameter } from "../../../../actions";
import { ValueCombinedChart } from "../../../../components/Charts/CCSCockpit";
import { LegendMarker } from "../../../../components/Charts/components/LegendMarker";
import { UserEngagementMarketFilter } from "../../../../components/Filters/common/UserEngagementMarketFiler";
import { closeFilterOptions, closeMarketOptions } from "../../../../components/Filters/subs/helpers";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { CYAN, LIGHT_CYAN, RED } from "../../../../constants";
import { DashboardBannerContext, FilterContext, ThemeContext } from "../../../../context";
import { formatToThreeDigits } from "../../../../utils/numberFormatting";
import { convertToPercentage, isNull, plusSignNumbers } from "../../../../utils/utilityFunctions";
import { MultiSelectFilter } from "../../../../components/Filters/common";

interface Props {
  title: string;
  value: number | null;
  comparisonValue: number | null;
  soldEligible: number | null;
  comparisonSoldEligible: number | null;
  isLoading: boolean;
  comparisonPercentageValue?: number | null;
  chartData?: { data: Array<any> | undefined; chartId: string; barSeriesName: string; barDataKey: string; lineDataKey: string };
  isActivation?: boolean;
  showMetric?: boolean;
  tooltip?: string;
  onClick?: () => void;
  bpComparisonValue?: number | null;
  isSingleYear?: boolean;
  toggle: (toggleState: boolean, setToogleState: Dispatch<SetStateAction<boolean>>) => void;
  toggleActive: boolean;
  setToggleActive: Dispatch<SetStateAction<boolean>>;
}

const computeSoldEligible = (soldEligible: number, value: number) => (!soldEligible || isNull(value) ? null : value / soldEligible);

export const SoldEligibleTile = (props: Props) => {
  const {
    title,
    value,
    comparisonValue,
    soldEligible,
    comparisonSoldEligible,
    comparisonPercentageValue,
    isActivation,
    chartData,
    isLoading,
    showMetric,
    tooltip,
    onClick: handleClick,
    bpComparisonValue,
    isSingleYear,
    toggle,
    toggleActive,
    setToggleActive,
  } = props;

  const dispatch = useDispatch();

  const themeContext = useContext(ThemeContext);

  const [producedValue, setProducedValue] = useState<number | null>(null);
  const [comparisonProducedValue, setComparisonProducedValue] = useState<number | null>(null);

  const comparisonLabel = "YoY";
  const [highLight, setHighLight] = useState(false);

  const dateRange = useSelector((state: RootStateOrAny) => state.parameters.date_range);

  const {
    market: marketParamValue,
    region: regionParamValue,
    brand: brandParamValue,
    model: modelParamValue,
    enrolled_user_market: enrolledUserMarket,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const { user_engagement_geography } = useSelector((state: RootStateOrAny) => state.filters.cockpit_data);

  const { bannerHovered } = useContext(DashboardBannerContext);

  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const [marketList, setMarketList] = useState<Array<string>>([]);

  useEffect(() => {
    if (user_engagement_geography) {
      const markets: Array<string> = [];
      const regionGeos = regionParamValue == "Europe" ? user_engagement_geography.filter((geo: any) => geo.region == "europe") : user_engagement_geography.filter((geo: any) => geo.region == "asean");
      regionGeos.map((market: any) => {
        markets.push(capitalize(market.market));
      });
      setMarketList(markets);
    }
  }, [user_engagement_geography, regionParamValue]);

  useEffect(() => {
    if ((marketParamValue === "United States" || regionParamValue === "Japan") && bannerHovered) {
      setHighLight(true);
    } else {
      setHighLight(false);
    }
  }, [marketParamValue, bannerHovered, regionParamValue]);

  useEffect(() => {
    !isNull(soldEligible) &&
      !isNull(value) &&
      !isNull(comparisonValue) &&
      !isNull(comparisonSoldEligible) &&
      setProducedValue(computeSoldEligible(soldEligible as number, value as number));
    setComparisonProducedValue(computeSoldEligible(comparisonSoldEligible as number, comparisonValue as number));
  }, [soldEligible, value, comparisonSoldEligible, comparisonValue]);

  const isComparisonValueValid = !isNull(comparisonPercentageValue) && typeof comparisonPercentageValue !== "undefined";

  const [isAllUndefined, setIsAllUndefined] = useState<boolean>(false);

  useEffect(() => {
    const key = `${chartData?.barDataKey}`;
    const lineKey = "soldEligiblePercentage";
    setIsAllUndefined(
      chartData?.data?.every(
        (splitRow) =>
          typeof splitRow[key] === "undefined" ||
          (isNull(splitRow[key]) && typeof splitRow[lineKey] === "undefined") ||
          isNull(splitRow[lineKey])
      )
        ? true
        : false
    );
  }, [chartData]);

  useEffect(() => {
    const selectedMarket = enrolledUserMarket.split(",");
    const marketsAreValid = selectedMarket.every((market: string) => ["G8", ...marketList].includes(market));
    if (marketList.length > 0 && !marketsAreValid) {
      dispatch(setSpecificParameter("enrolled_user_market", "All"));
    }
  }, [enrolledUserMarket, marketList]);

  const marketFilterValue = useMemo(() => {
    let value = "All countries";
    if (marketList && enrolledUserMarket) {
      const selectedMarketsArr = enrolledUserMarket.split(",");
      value =
        selectedMarketsArr?.length === marketList.length
          ? "All"
          : selectedMarketsArr?.length > 1
            ? `${selectedMarketsArr?.length} markets selected`
            : enrolledUserMarket;
    }
    return value;
  }, [marketList, enrolledUserMarket]);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleMarketFilterSubmission = useCallback(() => {
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="enrolled_user_market"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = marketList?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    dispatch(setSpecificParameter("enrolled_user_market", filterValue));
  }, [marketList]);

  return (
    <div
      className={`tile engagement_tile ${highLight ? "isBlue" : ""}`}
      id={title.replaceAll(" ", "_")}
      data-test-id={title.replaceAll(" ", "_")}
      onClick={handleClick}
    >
      {!chartData?.data && (
        <div className="tile_header">
          {title}{" "}
          {tooltip && (
            <Tooltip
              position="left"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={false}
              delay={1}
              hideDelay={1}
              duration={1}
              title={tooltip}
              size={"small"}
            >
              <span className="info_tooltip" />
            </Tooltip>
          )}
        </div>
      )}
      {showMetric && toggle(toggleActive, setToggleActive)}
      {chartData &&
        chartData.data &&
        (regionParamValue === "Europe" ? (
          <div className="chartFilterContainer">
            <UserEngagementMarketFilter
              filterValue={marketFilterValue}
              marketsList={marketList}
              marketGroupsList={["G8"]}
              marketParamValue={enrolledUserMarket}
              parameterName="enrolled_user_market"
            />
          </div>
        ) : regionParamValue === "ASEAN" ? (
          <div className="chartFilterContainer">
            <MultiSelectFilter
              parentKey="enrolled_user_market"
              filterList={marketList as any}
              filterName="Select Markets"
              value={marketFilterValue}
              parameterName={"enrolled_user_market"}
              parameterValue={enrolledUserMarket}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleMarketFilterSubmission}
            />
          </div>
        ) : null)}
      <div className="CCS_main_content">
        <div className="tile_content">
          {isActivation ? (
            <>
              <div className="split_value" data-test-id={"sold_eligible_split"}>
                <div className="tile_header">
                  {chartData?.data && <LegendMarker shape={"line"} color={LIGHT_CYAN} />}
                  {`${!showMetric ? "" : "Activation ratio"}`}
                </div>

                <div className="value" data-test-value={producedValue ? producedValue : "n/a"} data-test-id={"sold_eligible_value"}>
                  <span className="main">{soldEligible}</span>
                </div>
                {!isNull(comparisonSoldEligible) && typeof comparisonSoldEligible !== "undefined" && (
                  <p className="period_comparison comparison">
                    <span className="main">
                      <span className="comparison_value">
                        {isNull(soldEligible) || isNull(comparisonSoldEligible) ? "n/a" : `${comparisonSoldEligible} PTS`}
                      </span>
                      {comparisonLabel}
                    </span>
                  </p>
                )}
              </div>
              <div className="split_value" data-test-id={"main_data_split"}>
                {chartData?.data && (
                  <div className="tile_header">
                    <LegendMarker color={CYAN} shape={"square"} />
                    <p>s</p>
                    {title}
                  </div>
                )}
                <div className="value" data-test-value={value ? value : "n/a"} data-test-id={"value"}>
                  <span className="main">
                    {isNull(value)
                      ? "n/a"
                      : regionParamValue == "China"
                        ? formatToThreeDigits(value as number, 1)
                        : formatToThreeDigits(value as number)}
                  </span>
                  <p className="value_note grey_info">{`${!showMetric ? "Total warranty start" : ""}`}</p>
                </div>

                {isComparisonValueValid && (
                  <p className="period_comparison comparison">
                    <Ratings
                      value={
                        comparisonPercentageValue ? (isNull(comparisonPercentageValue) ? "na" : String(comparisonPercentageValue)) : ""
                      }
                      isPercentageValue={false}
                    />
                    <span className="comparison_value">{comparisonPercentageValue}</span>
                    {comparisonLabel}
                  </p>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="split_value" data-test-id={"main_data_split"}>
                {chartData?.data && (
                  <div className="tile_header">
                    <LegendMarker color={CYAN} shape={"square"} />
                    {toggleActive ? title : "Avg cumulative enrolled customers"}
                    {tooltip && (
                      <Tooltip
                        position="top"
                        className={"normal_tippy"}
                        trigger="mouseenter"
                        animation="none"
                        interactive={true}
                        delay={1}
                        hideDelay={1}
                        duration={1}
                        title={tooltip}
                        size={"small"}
                      >
                        <span className="info_tooltip" />
                      </Tooltip>
                    )}
                  </div>
                )}
                <div className="value" data-test-value={value ? value : "n/a"} data-test-id={"value"}>
                  {isNull(value)
                    ? "n/a"
                    : regionParamValue == "China"
                      ? formatToThreeDigits(value as number, 1)
                      : formatToThreeDigits(value as number)}
                </div>

                {isComparisonValueValid && (
                  <p className="period_comparison comparison">
                    <span className="main">
                      <Ratings
                        value={
                          comparisonPercentageValue ? (isNull(comparisonPercentageValue) ? "na" : String(comparisonPercentageValue)) : ""
                        }
                        isPercentageValue={true}
                      />
                      <span className="comparison_value">{Math.abs(comparisonPercentageValue as number)}%</span>
                      {comparisonLabel}
                    </span>
                  </p>
                )}
              </div>

              {(enrolledUserMarket == "All" || regionParamValue != "Europe") && toggleActive && (
                <div className="split_value">
                  {chartData?.data && (
                    <div className="tile_header">
                      <LegendMarker color={CYAN} shape={"line"} />
                      of connected cars sold
                    </div>
                  )}
                  <div className="value" data-test-value={soldEligible ? soldEligible : "n/a"} data-test-id={"sold_eligible_value"}>
                    <span className="main">{isNull(soldEligible) ? "n/a" : `${soldEligible}%`}</span>
                    {!chartData?.data && <p className="value_note grey_info">{`${!showMetric ? "of connected cars sold" : ""}`}</p>}
                  </div>

                  {!isNull(comparisonSoldEligible) && typeof comparisonSoldEligible !== "undefined" && (
                    <p className="period_comparison comparison">
                      <span className="main">
                        <span className="comparison_value">
                          {isNull(soldEligible) || isNull(comparisonSoldEligible)
                            ? "n/a"
                            : `${plusSignNumbers(comparisonSoldEligible)} PTS`}
                        </span>
                        {comparisonLabel}
                      </span>
                    </p>
                  )}
                </div>
              )}

              {chartData?.data &&
                isSingleYear &&
                !showMetric &&
                (regionParamValue === "Japan" || regionParamValue === "North America") &&
                brandParamValue !== "Infiniti" && (
                  <div className="split_value" data-test-id={"sold_eligible_split"}>
                    <p className="period_comparison comparison">
                      <span className="main">
                        <span className="comparison_value">
                          {isNull(bpComparisonValue) || isNull(producedValue)
                            ? "n/a"
                            : `${plusSignNumbers(Math.round((producedValue as number) * 100 - (bpComparisonValue as number) * 100))} PTS`}
                        </span>
                        vs BP
                      </span>
                    </p>
                  </div>
                )}
            </>
          )}
          {chartData?.data &&
            (regionParamValue === "Japan" || regionParamValue === "North America") &&
            brandParamValue !== "Infiniti" &&
            toggleActive && (
              <>
                <div className="tile_header">
                  <LegendMarker color={RED} shape={"line"} />
                  BP target
                </div>

                {isSingleYear && (
                  <div
                    className="value"
                    data-test-value={bpComparisonValue ? "bpComparisonValue_enrollment" : "n/a"}
                    data-test-id={"sold_eligible_value"}
                  >
                    {modelParamValue.includes("All") ? (
                      <span className="main">
                        {isNull(bpComparisonValue) ? "n/a" : bpComparisonValue && convertToPercentage(bpComparisonValue, 0)}
                      </span>
                    ) : (
                      <span className="main">n/a </span>
                    )}
                  </div>
                )}
              </>
            )}
        </div>
        {chartData?.data && (
          <div className="metric_content">
            <ValueCombinedChart
              chartId={chartData.chartId}
              barSeriesName={toggleActive ? chartData.barSeriesName : "Cumulative enrolled customers"}
              barDataKey={chartData.barDataKey}
              lineSeriesName={`${isActivation ? "Activation rate" : "% of sold eligible"}`}
              lineDataKey={chartData.lineDataKey}
              data={isAllUndefined ? [] : chartData.data}
              theme={themeContext.theme}
              region={regionParamValue}
            />
          </div>
        )}
      </div>

      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};
