import React, { useEffect } from 'react'
import MetricTile from './MetricTile'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getOrderSummaryData } from '../../../api/ecommerce'
import { RootStateOrAny, useSelector } from 'react-redux'

const OrderSummary = withRouter((props: RouteComponentProps) => {

  const { history } = props

  const { currency: currencyParam } = useSelector((state: RootStateOrAny) => state.parameters)

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["ecommerceOrderSummary", history.location.search],
    queryFn: getOrderSummaryData,
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [history.location.search])


  return (
    <div className="order-summary-row">
      <MetricTile
        title="Total Online Orders"
        value={data?.total_online_orders ?? null}
        tooltipText="The total number of orders completed via 'Reserve online'  (Considering both BTO, STO and CPO)"
        isLoading={isFetching}
      />
      <MetricTile
        title="Total Order Value"
        value={data?.total_order_value ?? null}
        currency={currencyParam}
        tooltipText="Total value of online order (online offer if available OR MSRP) including selected options and accessories (NOT deposit value) Excl. Cancellation"
        isLoading={isFetching}
        formatValue={true}
      />
      <MetricTile
        title="Ecommerce Qualified Visits"
        value={data?.ecomerce_qualified_visits_to_order ?? null}
        tooltipText="Ecommerce Qualified Visit - BTO (User who visits configurator (any page) and PDP (AU) or check out (European) within same visit)"
        isLoading={isFetching}
        formatValue={true}
      />
      {/* <MetricTile
        title="Ecommerce Order to Sale Conversion"
        value={data?.ecommerce_order_to_sale_conversion ?? null}
        tooltipText="Online order to offline sales conversion rate – data may be below actual due to time lag to convert and track"
        isPercentage={true}
        isLoading={isFetching}
      /> */}
      <MetricTile
        title="Ecommerce Leads"
        value={data?.ecommerce_leads ?? null}
        tooltipText="Lead form sent within Ecommerce Journey. Inc, Config, PDP, Checkout pages."
        isLoading={isFetching}
      />
      <MetricTile
        title="Avg VOC"
        value={data?.avg_voc_sto_ecom_checkout ?? null}
        noOfResponses={data?.ratings_count ?? null}
        tooltipText="Voice of customers feedback submitted on Ecommerce pages (including STO and checkout pages). Watch out for low statistical significance (below 16 responses is considered not significant enough)."
        isLoading={isFetching}
        formatValue={true}
      />
    </div>
  )
})

export default OrderSummary