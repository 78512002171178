import React, { useContext, useEffect, useMemo } from 'react'
import DeepDiveMultipleBarLineChart from '../../../../components/Charts/ExecutiveSummary/DeepDiveMultipleBarLineChart'
import { ThemeContext } from '../../../../context'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getTrafficConversionBtoStoCta } from '../../../../api/executiveSummaryProductPerformanceDeepDive';
import { NoDataMsg } from '../../../../components/AppMessages';
import { GRAY } from '../../../../constants';

const TrafficConversion = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext)

  const { data, refetch, isFetching } = useQuery({
    queryKey: [`trafficConversionBtoStoCtaData`, history.location.search],
    queryFn: getTrafficConversionBtoStoCta,
    enabled: false
  });

  useEffect(() => {
    refetch()
  }, [history.location.search])

  const coloredSeries = useMemo(() => {
    if (data?.series) {
      return data.series.map((series: any) => {
        return {
          ...series,
          color: series.type === 'bar' ? GRAY : undefined
        }
      })
    }
    return []
  }
    , [data?.series])

  return (
    <div className='tile'>
      <div className="tile-header">
        <div className='title-container'>
          Traffic, Conversion, and  BTO & STO CTA
        </div>
      </div>
      <div className="tile-content">
        {data?.data ?
          <DeepDiveMultipleBarLineChart
            chartName={`trafficConversionBtoStochart`}
            data={data?.data}
            seriesList={coloredSeries}
            theme={theme}
            isLoading={isFetching}
            lineAxisIsPercentage={true}
          />
          :
          <NoDataMsg />
        }
      </div>
    </div>
  )
});

export default TrafficConversion