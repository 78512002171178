import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, Sprite } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLACK, CCS_ANALYSIS_COLORS, MEDIA_MIX_CHART_COLORS } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string | null>>;
  seriesList: Array<{ name: string; field: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
  isPercentage?: boolean;
  seriesColors: Array<string>;
  currency?: string;
}

class MultipleLineChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme, isPercentage, seriesColors, currency } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.0a";
    this.chart.colors.list = MEDIA_MIX_CHART_COLORS.map((colorStr: string) => color(colorStr));

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.marginBottom = 15;
    this.chart.colors.list = CCS_ANALYSIS_COLORS.map((item) => color(item));

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme, true);
    dateAxis.renderer.minGridDistance = 10;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter = new NumberFormatter()
    valueAxis.numberFormatter.numberFormat = "#a"
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${text}${isPercentage ? "%" : ""}`;
    });

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList.forEach(({ name, field }, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.dataFields.dateX = "date";
      if (seriesColors) series.fill = color(seriesColors.length > 1 ? seriesColors[idx] : seriesColors[0]);
      if (seriesColors) series.stroke = color(seriesColors.length > 1 ? seriesColors[idx] : seriesColors[0]);

      const circleBullet = lineSeriesConfiguration(series, {
        valueY: field,
        name: name,
        yAxis: valueAxis,
      });
      series.numberFormatter = new NumberFormatter();
      series.numberFormatter.numberFormat = "###,###";
      circleBullet.tooltipText = `[bold; font-size: var(--regular_font_size);]{dateX.formatDate('MMM YYYY')} [/ font-size: var(--regular_font_size);]: ${currency ?? ""} {valueY.formatNumber("###,###")}${isPercentage ? "%" : ""}`;
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default MultipleLineChart;
