import React, { useContext, useMemo } from 'react'
import DeepDiveMultipleBarLineChart from '../../../../components/Charts/ExecutiveSummary/DeepDiveMultipleBarLineChart';
import { ThemeContext } from '../../../../context';
import { BLUE, GRAY } from '../../../../constants';

interface Props {
  title: string;
  data: Array<any>;
  series: Array<any>;
  market: string;
  isLoading: boolean;
}
const WebsitePerformanceTile = (props: Props) => {
  const { title, data, series, market, isLoading } = props;
  const { theme } = useContext(ThemeContext);

  const coloredSeries = useMemo(() => {
    if (series) {
      return series.map((series: any) => {
        return {
          ...series,
          color: series.type === 'bar' ? series.name.includes("previous") ? GRAY : BLUE : undefined,
        }
      });
    }
    return [];
  }, [series])

  return (
    <div className="tile website-performance-tile">
      <div className="tile-header">
        <div className="title-container">
          {title}
        </div>
      </div>
      <div className="tile-content">
        <DeepDiveMultipleBarLineChart
          chartName={`${title.concat(market).toLowerCase().replace(/ /g, '_')}ConfiguratorVsOverallPages`}
          data={data}
          seriesList={coloredSeries}
          theme={theme}
          isLoading={isLoading}
          legendFontSize={15}
          lineAxisIsPercentage={true}
        />
      </div>
    </div>
  )
}

export default WebsitePerformanceTile