import React, { useContext, useEffect } from 'react'
import { Tooltip } from 'react-tippy'
import { ThemeContext } from '../../../context'
import EcommerceLineChart from '../../../components/Charts/Ecommerce/EcommerceLineChart'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getTotalOrderValueTrend } from '../../../api/ecommerce'
import { RootStateOrAny, useSelector } from 'react-redux'

const TotalOrderValueTrend = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext)
  const { currency } = useSelector((state: RootStateOrAny) => state.parameters);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["totalOrderValueTrend", history.location.search],
    queryFn: getTotalOrderValueTrend,
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [history.location.search])

  return (
    <div className="ecommerce-tile">
      <div className="tile-header">
        <div className="header-title">
          <p>Total Order Value Trend Over Time</p>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            title={
              "The total number of orders value completed via 'Reserve online'  (Considering both BTO, STO and CPO) over time"
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <EcommerceLineChart data={data?.data ?? []} seriesList={data?.series ?? []} chartId="totalOrderValueTrendOverTimeChart" theme={theme} isLoading={isFetching} currency={currency} />
    </div>
  )
})

export default TotalOrderValueTrend