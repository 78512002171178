import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DefaultTable } from '../../../../components/Tables/subs';
import { ErrorBoundary } from '@sentry/react';
import { ErrorMsg, NoDataMsg } from '../../../../components/AppMessages';
import Ratings from '../../../../components/Ratings/Ratings';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getCustomerAndServiceTakeRateBreakdown } from '../../../../api/ccsMonetizationApi';
import { useQuery } from '@tanstack/react-query';

const BreakdownTable = withRouter((props: RouteComponentProps) => {
  const { history } = props

  const [breakdownSortId, setBreakdownSortId] = useState("customer_take_rate");
  const [breakdownSortDesc, setBreakdownSortDesc] = useState(true);

  const { data, refetch } = useQuery({
    queryKey: ["customerAndServiceTakeRateBreakdownData", history.location.search],
    queryFn: getCustomerAndServiceTakeRateBreakdown,
    enabled: false,
  });

  useEffect(() => {
    refetch()
  }, [history.location.search])

  const columns = useMemo(
    () => [
      {
        id: "breakdowns",
        Header: "G5 markets",
        accessor: `market`,
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
        Cell: (props: { row: { values: { breakdown: any }; getToggleRowExpandedProps: () => any }; cell: { row: { original: any } } }) => {
          const {
            row,
            cell: {
              row: { original },
            },
          } = props;

          const type =
            "model" in original
              ? "model"
              : "market"

          const value =
            original?.["model"] ||
            original?.["market"]

          const disable_click = type === "model" ? "disable_click" : "clickable";

          const spanProps = disable_click === "clickable" ? { ...row.getToggleRowExpandedProps() } : {};

          const parentValue = original?.parent ? original?.parent : "";
          return (
            <span
              className={`${disable_click}`}
              data-type={"market"}
              {...spanProps}
              data-value={value}
              data-parent={parentValue}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Customer Take rate",
        accessor: `customer_take_rate`,
        id: "customerTakeRate",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: ({ value }: { value: number }) => {
          return (
            <div className="table-cell">
              <Ratings value={value} isPercentageValue={true} /> {value ? `${Math.abs(value)}%` : "n/a"}
            </div>
          );
        },
      },
      {
        Header: "Service Take rate",
        accessor: `service_take_rate`,
        id: "serviceTakeRate",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: ({ value }: { value: number }) => {
          return (
            <div className="table-cell">
              <Ratings value={value} isPercentageValue={true} /> {value ? `${Math.abs(value)}%` : "n/a"}
            </div>
          );
        },
      },
    ],
    []
  );

  const setChildSortOrder = useCallback(
    (sortId: string, sortDesc: boolean) => {
      setBreakdownSortId(sortId);
      setBreakdownSortDesc(sortDesc);
    },
    [setBreakdownSortId, setBreakdownSortDesc]
  );

  const renderRowSubComponent = useCallback(
    // eslint-disable-next-line react/display-name
    () => (props: { row: { original: { breakdown?: Array<any>; } } }) => {
      const {
        row: { original: { breakdown: data } },
      } = props;

      return (
        <ErrorBoundary fallback={<ErrorMsg />}>
          {data && data?.length > 0 ? (
            <DefaultTable
              columns={columns}
              data={data}
              id={"visits_trend_breakdown_child"}
              //@ts-ignore
              isChildComponent={true}
              initialSortColId={{ id: breakdownSortId, desc: !breakdownSortDesc }}
              hiddenCols={[]}
              hideFooter={true}
            />
          ) : (
            <NoDataMsg />
          )}
        </ErrorBoundary>
      );
    },
    [columns, breakdownSortDesc, breakdownSortId]
  );

  return (
    <div className='cust-service-table tile'>
      <div className='tile-header'>
        Customer & Service take rates per market and model
      </div>
      {data && data?.length > 0 ? (
        <DefaultTable
          columns={columns}
          data={data ?? []}
          id={"visits_trend_breakdown"}
          data-test-id={"visits_trend_breakdown"}
          // @ts-ignore
          renderRowSubComponent={renderRowSubComponent()}
          isChildComponent={false}
          hiddenCols={[]}
          initialSortColId={{ id: "customer_take_rate", desc: true }}
          setSortInformation={setChildSortOrder}
        />
      ) : (
        <NoDataMsg />
      )}
    </div>
  )
})

export default BreakdownTable