import React, { useContext, useEffect } from 'react'
import { ThemeContext } from '../../../context'
import { Tooltip } from 'react-tippy'
import EcommerceBarLineTrendChart from '../../../components/Charts/Ecommerce/EcommerceStackedBarLineChart';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getOrderValueByExtrasTrend } from '../../../api/ecommerce';
import { CYAN, GRAY, ORANGE, TURQUOISE } from '../../../constants';
import { RootStateOrAny, useSelector } from 'react-redux';

const OrderValueByExtras = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { theme } = useContext(ThemeContext)

  const { currency } = useSelector((state: RootStateOrAny) => state.parameters)

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["orderValueByExtrasTrend", history.location.search],
    queryFn: getOrderValueByExtrasTrend,
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [history.location.search])

  return (
    <div className="ecommerce-tile">
      <div className="tile-header">
        <div className="header-title">
          <p>Order Value by extras</p>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            title={
              "Shows the volume of orders with options and accessories added and the per cent take rate"
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <EcommerceBarLineTrendChart data={data?.data ?? []} seriesList={data?.series ?? []} chartId="orderValueByExtrasChart" theme={theme} isLoading={isFetching} barColorMapping={[TURQUOISE, CYAN, ORANGE]} lineColorMapping={[GRAY]} lineIsPercentage={true} currency={currency} />
    </div>
  )
})

export default OrderValueByExtras