import React, { useEffect } from 'react'
import { DefaultPageContent } from '../../../components/PageContent'
import { PageTitle } from '../../../components/PageContent/subs'
import ExecutiveSummaryProductPerformanceDeepDiveFilters from '../../../components/Filters/ExecutiveSummaryDashboard/ExecutiveSummaryProductPerformanceDeepDiveFilters'
import { EXECUTIVE_SUMMARY_BTN_FILTERS } from '../../../constants/executiveSummaryConstants'
import { DashboardLayout } from '../../../components/Layouts'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setPageName } from '../../../actions'
import { eventTracking, MixpanelEvents } from '../../../utils/userTracking'
import "../../../assets/styles/pages/executiveSummaryProductPerformanceDeepDive.scss";
import OverallConfiguratorVoc from './components/OverallConfiguratorVoc'
import ConfiguratorContributionToVoc from './components/ConfiguratorContributionToVoc'
import VerbatimSplit from './components/VerbatimSplit'
import MarketSplitVoc from './components/MarketSplitVoc'
import ModelSplitVoc from './components/ModelSplitVoc'
import ConfiguratorVsOverallPagesTile from './components/ConfiguratorVsOverallPagesTile'
import TrafficConversion from './components/TrafficConversion'
import YoyComparisonTile from './components/YoyComparisonTile'
import Collapsible from '../../../components/Collapsible/Collapsible'
import WebsitePerformance from './components/WebsitePerformance'
import OverallConfiguratorPerformanceSummary from './components/OverallConfiguratorPerformanceSummary'
import ContributionToMarketNumbers from './components/ContributionToMarketNumbers'
import OverallConfiguratorErrors from './components/OverallConfiguratorErrors'
import { getConfiguratorCtrOnBtoSto, getConfiguratorLeadsGeneration, getDxsComparison, getFrustrationScoreComparison, getTechnicalScoreComparison } from '../../../api/executiveSummaryProductPerformanceDeepDive'


const ExecutiveSummaryProdcutPerformanceDeepDive = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageName("Executive Summary - Product Performance - deep dive"));
    eventTracking(MixpanelEvents.page_view, { dashboard: "Executive Summary", page: "Product Performance - deep dive" });
  }, []);


  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<ExecutiveSummaryProductPerformanceDeepDiveFilters filterBtns={EXECUTIVE_SUMMARY_BTN_FILTERS} />}
        dataDocumentation="executive_summary"
        lastDataRefresh={""}
        pageTitle={<PageTitle dataDocumentation="executive_summary" />}
      >
        <div className='executive-summary-product-performance-deep-dive'>
          {/* <Collapsible title='Overall Product Performance' show={true}>
            <OverallConfiguratorPerformanceSummary />
            <ContributionToMarketNumbers />
            <OverallConfiguratorErrors />
          </Collapsible> */}
          <Collapsible title='Customer Experience' show={true}>
            {/* <div className='row-5 h-25'>
              <CustomerExperienceMetricTile />
              <PageOverallPerformance />
            </div> */}
            <div className='row-3 h-25'>
              <ConfiguratorVsOverallPagesTile metric='DSX' getDataFn={getDxsComparison} />
              <ConfiguratorVsOverallPagesTile metric='Technical score' getDataFn={getTechnicalScoreComparison} legendFontSize={14} />
              <ConfiguratorVsOverallPagesTile metric='Frustration score' getDataFn={getFrustrationScoreComparison} legendFontSize={14} />
            </div>
          </Collapsible>
          <Collapsible title='Voice of the Customer' show={true} >
            <div className="row-2">
              <OverallConfiguratorVoc />
              <div className='col-2'>
                <ConfiguratorContributionToVoc />
                <VerbatimSplit />
              </div>
            </div>
            <div className="row-2">
              <MarketSplitVoc />
              <ModelSplitVoc />
            </div>
          </Collapsible>
          <Collapsible title='Website Performance' show={true}>
            <div className="row-1">
              <TrafficConversion />
            </div>
            <div className='row-2'>
              <YoyComparisonTile title='Configurator Leads Generation' getDataFn={getConfiguratorLeadsGeneration} />
              <YoyComparisonTile title='Configurator CTR on BTO/STO' getDataFn={getConfiguratorCtrOnBtoSto} />
            </div>
          </Collapsible>
          <Collapsible title='Website Performance - Market Deep-dive' show={true}>
            <WebsitePerformance />
          </Collapsible>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  )
})

export default ExecutiveSummaryProdcutPerformanceDeepDive