import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../../context';
import ExecutiveSummaryDonutChart from '../../../../components/Charts/ExecutiveSummary/ExecutiveSummaryDonutChart';
import { Tooltip } from 'react-tippy';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DefaultFilter } from '../../../../components/Filters/common';
import { eventTracking, MixpanelEvents } from '../../../../utils/userTracking';
import { closeFilterOptions } from '../../../../components/Filters/subs/helpers';
import { getPerformanceByProductCategory } from '../../../../api/executiveSummaryProductPerformanceApi';
import { useQuery } from '@tanstack/react-query';

const PerformanceByProductCategory = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);
  const [filterDataBy, setFilteDataBy] = useState("Visits");

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["performanceByProductCategoryData", history.location.search, filterDataBy],
    queryFn: () => getPerformanceByProductCategory(filterDataBy),
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search, filterDataBy]);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      setFilteDataBy(optionValue);
    }
    closeFilterOptions();
  }, []);

  return (
    <div className="tile">
      <div className="tile-header">
        <div className="title-container">
          <div className="title">Performance by product category</div>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={"Products that have received the biggest number of website visits within the selected date range and markets."}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <div className="filters-container">
          <DefaultFilter
            list={["Visits", "KBAs", "Leads"]}
            filterName={"filter_by"}
            filterLabel={"METRIC FILTER BY"}
            filterValue={filterDataBy}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </div>
      </div>
      <ExecutiveSummaryDonutChart
        chartId="performanceByProductCategoryChart"
        data={data}
        theme={theme}
        categoryField="product_category"
        valueField={`${filterDataBy.toLowerCase()}_share`}
        isLoading={isLoading}
        radius={100}
        tooltipValueField={`${filterDataBy.toLowerCase()}_share`}
        metric={filterDataBy}
      />
    </div>
  );
})

export default PerformanceByProductCategory