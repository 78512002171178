import React, { useContext, useEffect, useMemo } from 'react'
import DeepDiveMultipleBarLineChart from '../../../../components/Charts/ExecutiveSummary/DeepDiveMultipleBarLineChart';
import { ThemeContext } from '../../../../context';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getModelSplit } from '../../../../api/executiveSummaryProductPerformanceDeepDive';
import { RED, PURE_ORANGE, GOLDEN_YELLOW, RACING_GREEN, FOREST_GREEN } from '../../../../constants';
import { NoDataMsg } from '../../../../components/AppMessages';
import LoadingEllipsis from '../../../../components/Loading/LoadingEllipsis';

const ratingColorMapping = {
  "Rating 1": RED,
  "Rating 2": PURE_ORANGE,
  "Rating 3": GOLDEN_YELLOW,
  "Rating 4": RACING_GREEN,
  "Rating 5": FOREST_GREEN,
};

const ModelSplitVoc = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["modelSplitData", history.location.search],
    queryFn: getModelSplit,
    enabled: false
  });

  useEffect(() => {
    refetch()
  }, [history.location.search])

  const colouredSeries = useMemo(() => {
    if (data?.series) {
      return data.series.map((series: { name: keyof typeof ratingColorMapping }) => ({ ...series, color: ratingColorMapping[series.name] }))
    }
    return []
  }, [data?.series])

  return (
    <div className='tile'>
      <div className="tile-header">
        <div className='title-container'>
          <div className='title'>Model Split Voc</div>
        </div>
      </div>
      <div className='tile-content'>
        {isFetching ? <LoadingEllipsis isLoading={isFetching} />
          : data ? (
            <DeepDiveMultipleBarLineChart
              chartName='modelSplitVocChart'
              data={data?.data ?? []}
              seriesList={colouredSeries}
              theme={theme}
              isLoading={isFetching}
              isCategoryAxis={true}
              categoryAxisField='model'
              stackedBars={true}
            />) : <NoDataMsg />}
      </div>
    </div>
  )
});

export default ModelSplitVoc