import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, Sprite } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLACK, CCS_ANALYSIS_COLORS, MEDIA_MIX_CHART_COLORS } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string | null>>;
  seriesList: Array<{ name: string; field: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
  isPercentage?: boolean;
  seriesColors: Array<string>;
  min?: number;
  max?: number;
  minGridDistance?: number;
}

class QuickVocLineChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme, isPercentage, seriesColors, minGridDistance } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    // this.chart.numberFormatter.numberFormat = "#.0a";
    this.chart.maskBullets = false;

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.position = "right";
    this.chart.legend.itemContainers.template.paddingBottom = 20;
    this.chart.legend.useDefaultMarker = true;
    this.chart.legend.scrollable = true;

    this.chart.events.on("ready", function (ev) {
      if (valueAxis.min) {
        valueAxis.min = Math.floor(valueAxis.min * 2) / 2
      }
    });

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme, true);
    dateAxis.renderer.minGridDistance = minGridDistance ?? 30;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.max = 5;
    valueAxis.strictMinMax = true;
    // valueAxis.renderer.minGridDistance = 10;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${text}${isPercentage ? "%" : ""}`;
    });
    valueAxis.numberFormatter = new NumberFormatter();

    this.createGrid(0, valueAxis);
    this.createGrid(0.5, valueAxis);
    this.createGrid(1, valueAxis);
    this.createGrid(1.5, valueAxis);
    this.createGrid(2, valueAxis);
    this.createGrid(2.5, valueAxis);
    this.createGrid(3, valueAxis);
    this.createGrid(3.5, valueAxis);
    this.createGrid(4, valueAxis);
    this.createGrid(4.5, valueAxis);
    this.createGrid(5, valueAxis);

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList.forEach(({ name, field }, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.dataFields.dateX = "date";
      if (seriesColors) series.fill = color(seriesColors.length > 1 ? seriesColors[idx] : seriesColors[0]);
      if (seriesColors) series.stroke = color(seriesColors.length > 1 ? seriesColors[idx] : seriesColors[0]);

      const circleBullet = lineSeriesConfiguration(series, {
        valueY: field,
        name: name,
        yAxis: valueAxis,
      });
      series.numberFormatter = new NumberFormatter();
      series.numberFormatter.numberFormat = "#.00";
      circleBullet.tooltipText = `[bold font-size: var(--regular_font_size)]{dateX.formatDate('MMM')} {name}:[bold font-size: var(--regular_font_size)] {valueY.numberFormat('#.00')}${isPercentage ? "%" : ""
        }`;
      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = color(BLACK);
      }
    });
  }

  createGrid(value: number, valueAxis: ValueAxis) {
    const range = valueAxis.axisRanges.create();
    range.value = value;
    range.label.text = "{value}";
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default QuickVocLineChart;
