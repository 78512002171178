import moment from "moment";
import { DIS_PRESET_DATE_PARAMS } from "../constants";
import { newApiFetchV2 } from "./utils";

export const getParams = () => {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters.get("date_range");
  const brandParam = parameters.get("brand") == "All" ? [] : parameters.get("brand")?.split(",");
  const regionParam = /all/gi?.test(parameters.get("region") as string) ? [] : parameters.get("region")?.split(",");
  const marketParam = parameters.get("market") == "All countries" ? [] : parameters.get("market")?.split(",");
  const productParam = parameters.get("product") == "All" ? [] : parameters.get("product")?.split(",");
  const productCategoryParam = parameters.get("product_category") == "All" ? [] : parameters.get("product_category")?.split(",");
  const modelParam = parameters.get("model") == "All" ? [] : parameters.get("model")?.split(",");

  const paramObj: any = {
    brand: brandParam,
    region: regionParam,
    market: marketParam,
    product_category: productCategoryParam,
    product: productParam,
    model: modelParam,
  };

  if (dateRangeParam) {
    if (DIS_PRESET_DATE_PARAMS.includes(dateRangeParam)) {
      paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
    } else {
      paramObj["date_range"] = "custom";
      paramObj["start_date"] = moment(dateRangeParam.split("-")[0], "MMMM YYYY").format("YYYY-MM-DD");
      paramObj["end_date"] = moment(dateRangeParam.split("-")[1], "MMMM YYYY").endOf("month").format("YYYY-MM-DD");
    }
  }

  return paramObj;
};

export const getOverallConfiguratorVoc = () =>
  newApiFetchV2("/executive-summary/product-performance-deep-dive/overall-configurator-voc", getParams());
export const getConfiguratorContributionToVoc = () =>
  newApiFetchV2("/executive-summary/product-performance-deep-dive/configurator-contribution-to-voc", getParams());
export const getVerbatimSplit = (experienceRating: string) =>
  newApiFetchV2(`/executive-summary/product-performance-deep-dive/verbatims-split?experience_rating=${experienceRating}`, getParams());
export const getMarketSplit = () => newApiFetchV2("/executive-summary/product-performance-deep-dive/market-split-voc", getParams());
export const getModelSplit = () => newApiFetchV2("/executive-summary/product-performance-deep-dive/model-split-voc", getParams());
export const getCustomerExperienceDxs = () =>
  newApiFetchV2("/executive-summary/product-performance-customer-experience/customer-experience-dxs", getParams());
export const getPageOverallPerformance = () =>
  newApiFetchV2("/executive-summary/product-performance-customer-experience/page-overall-performance", getParams());
export const getPageOverallPerformanceBreakdown = () =>
  newApiFetchV2("/executive-summary/product-performance-customer-experience/page-overall-performance-breakdown", getParams());
export const getDxsComparison = () =>
  newApiFetchV2("/executive-summary/product-performance-customer-experience/dxs-comparison-configurator-vs-overall-pages", getParams());
export const getTechnicalScoreComparison = () =>
  newApiFetchV2(
    "/executive-summary/product-performance-customer-experience/technical-score-comparison-configurator-vs-overall-pages",
    getParams()
  );
export const getFrustrationScoreComparison = () =>
  newApiFetchV2(
    "/executive-summary/product-performance-customer-experience/frustration-score-comparison-configurator-vs-overall-pages",
    getParams()
  );
export const getTrafficConversionBtoStoCta = () =>
  newApiFetchV2("/executive-summary/product-performance-lead-performance/traffic-conversion-bto-sto-cta", getParams());
export const getConfiguratorLeadsGeneration = () =>
  newApiFetchV2("/executive-summary/product-performance-lead-performance/configurator-leads-generation", getParams());
export const getConfiguratorCtrOnBtoSto = () =>
  newApiFetchV2("/executive-summary/product-performance-lead-performance/configurator-ctr-on-bto-sto", getParams());
export const getExperienceRatings = () =>
  newApiFetchV2("/executive-summary/product-performance-deep-dive/verbatims-experience-rating-filter", getParams());
export const getLeadPerformanceMarketDeepDive = () =>
  newApiFetchV2("/executive-summary/product-performance-lead-performance/leads-performance-market-deep-dive", getParams());
