import React from "react";
import Ratings from "../../../../components/Ratings/Ratings";
import { isNull, isNullOrUndefined } from "../../../../utils/utilityFunctions";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";

interface Props {
  title: string;
  tiles: Array<{ title: string; value: number; rating: number; volume: number; previousPeriod: number; }>;
  isLoading: boolean;
}

const CustomerJourneyVocCategoryTile = (props: Props) => {
  const { title, tiles, isLoading } = props;
  return (
    <div className="category-tile">
      <div className="title">{title}</div>
      <div className="tile-container">
        {tiles.map((tile) => (
          <div className="tile" key={tile.title}>
            <div className="title">{tile.title}</div>
            <div className="value-container">
              <div className={`value ${tile.value ? tile.value >= 4 ? "text-green" : tile.value >= 3.9 ? "text-amber" : "text-red" : ""}`}>
                {isNullOrUndefined(tile.value) ? "n/a" : tile.value?.toFixed(2)}
              </div>
              {isNullOrUndefined(tile.rating) ? null : <Ratings value={tile.rating} isPercentageValue={false} />}
            </div>
            <div className="comparison-container">
              <span>{!isNullOrUndefined(tile.previousPeriod) && "Previous Period: "}</span> &nbsp; <span className={`${tile.previousPeriod ? tile.previousPeriod >= 4 ? "text-green" : tile.previousPeriod >= 3.9 ? "text-amber" : "text-red" : ""}`}>{tile.previousPeriod?.toFixed(2)}</span>
            </div>
            <div className="response-volume">
              {isNullOrUndefined(tile.volume) ? null : `(Selected period volume of responses: ${tile.volume?.toLocaleString()})`}
            </div>
            <LoadingEllipsis isLoading={isLoading} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerJourneyVocCategoryTile;
