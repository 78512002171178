import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageName } from "../../../actions";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { VOC_BTN_FILTERS } from "../../../constants/vocConstants";
import { PageTitle } from "../../../components/PageContent/subs";
import VocQuickVocCecFilters from "../../../components/Filters/Voc/VocQuickVocCecFilters";
import {
  getQuickVocCecFilters,
  getQuickVocCecLastRefreshDate,
  getQuickVocCecMarketDeepDiveTable,
  getQuickVocCecMarketDeepDiveTrendline,
  getQuickVocCecPerformanceByMarket,
} from "../../../api/quickVocCecApi";
import { useQuery } from "@tanstack/react-query";
import Summary from "./components/Summary";
import "../../../assets/styles/pages/quickVocCec.scss";
import PerformanceByMarket from "../QuickVocSales/components/PerformanceByMarket";
import MarketDeepDive from "../QuickVocSales/components/MarketDeepDive";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { RouteComponentProps, withRouter } from "react-router-dom";

const metricMapping: Record<string, string> = {
  "Scores Complaint": "complaint",
  "Scores Info": "info",
  "Scores CCS": "ccs",
};

const QuickVocCec = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const dispatch = useDispatch();

  const { data: lastDataRefreshData, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["quickVocCecLastDataRefresh", history.location.search],
    queryFn: getQuickVocCecLastRefreshDate,
    enabled: false,
  });

  useEffect(() => {
    dispatch(setPageName("CEC VOC"));
    eventTracking(MixpanelEvents.page_view, { dashboard: "VOC", page: "CEC VOC" });
  }, [dispatch]);

  useEffect(() => {
    refetchLastDataRefresh();
  }, [history.location.search])

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<VocQuickVocCecFilters filterBtns={VOC_BTN_FILTERS} queryFn={getQuickVocCecFilters} pageName="CEC VOC" />}
        dataDocumentation="voc"
        lastDataRefresh={lastDataRefreshData?.date ?? ""}
        pageTitle={<PageTitle dataDocumentation="voc" />}
      >
        <div className="quick-voc-cec">
          <Summary />
          <PerformanceByMarket
            queryFn={getQuickVocCecPerformanceByMarket}
            metrics={["Scores Complaint", "Scores Info", "Scores CCS"]}
            metricMapping={metricMapping}
          />
          <MarketDeepDive tableQueryFn={getQuickVocCecMarketDeepDiveTable} trendlineQueryFn={getQuickVocCecMarketDeepDiveTrendline} columnsToExclude={["Resp%"]} />
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default QuickVocCec;
