import moment from "moment";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  fetchAverageDaysInStockTrendData,
  fetchAverageListPriceTrendData,
  fetchAverageSalesPriceTrendData,
  fetchBaseTrendsData,
  fetchCpoOperationalFilters,
  fetchCpoOperationalLastDataRefresh,
  fetchDealerTrendData,
  fetchInventoryByAgeData,
  fetchInventoryRatioData,
  fetchInventoryVolumeTrendData,
  fetchLastWeekDates,
  fetchLeadSalesConversionData,
  fetchListPriceData,
  fetchPerformancePerUnitData,
  fetchSalesRevenueTrendData,
  fetchSalesVolumeTrendData,
  fetchTotalValuesData,
  fetchTurnoverRatioData,
  setPageName,
  setSpecificParameter,
} from "../../actions";
import "../../assets/styles/pages/cpoOperational.scss";
import UsedCarsGranularityOptions from "../../components/Charts/components/UsedCarsGranularityOptions";
import { getGranularitiesToEnable } from "../../components/Charts/helpers/helpers";
import { UsedCarsFilters } from "../../components/Filters";
import { DashboardLayout } from "../../components/Layouts";
import { DefaultPageContent } from "../../components/PageContent";
import { PageTitle } from "../../components/PageContent/subs";
import { getStartEnd } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import BaseTrends from "./components/BaseTrends";
import DealerSalesDataTable from "./components/DealerSalesDataTable";
import InventoryGraphs from "./components/InventoryGraphs";
import RatioCharts from "./components/RatioCharts";
import { DashboardBannerContext } from "../../context";

const CpoOperational = () => {
  const dispatch = useDispatch();

  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);

  const { date_range, granularity, brand } = useSelector((state: RootStateOrAny) => state.parameters);
  const params = useSelector((state: RootStateOrAny) => state.parameters);

  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.cpo_operational_last_refresh_date);
  const lastWeekDates = useSelector((state: RootStateOrAny) => state.cpo_operational.last_week_dates);

  useEffect(() => {
    setShowBanner(true);
    setBannerText(
      "Please note that some of the figures present on the dashboard may be different to your local reporting. This is due to automation and the calculation methodology used. For clarification on how each metric is calculated, please refer to the definitions in the 'i' symbols."
    );
    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, []);

  useEffect(() => {
    const dateString = lastWeekDates?.start_date
      ? `${moment(lastWeekDates.start_date).format("DD-MMM")} - ${moment(lastWeekDates.end_date).format("DD-MMM")}`
      : null;
    Promise.all([
      // Sets page name
      dispatch(setPageName(`CPO Operational Dashboard ${dateString && date_range == "Last week" ? dateString : ""}`)),
      dispatch(fetchCpoOperationalLastDataRefresh()),
    ]);
  }, [dispatch, date_range, lastWeekDates]);

  useEffect(() => {
    Promise.all([
      dispatch(fetchBaseTrendsData()),
      dispatch(fetchDealerTrendData()),
      dispatch(fetchInventoryByAgeData()),
      dispatch(fetchPerformancePerUnitData()),
      dispatch(fetchTotalValuesData()),
      dispatch(fetchListPriceData()),
      dispatch(fetchInventoryRatioData()),
      dispatch(fetchTurnoverRatioData()),
      dispatch(fetchLeadSalesConversionData()),
      dispatch(fetchInventoryVolumeTrendData()),
      dispatch(fetchAverageDaysInStockTrendData()),
      dispatch(fetchSalesVolumeTrendData()),
      dispatch(fetchSalesRevenueTrendData()),
      dispatch(fetchAverageListPriceTrendData()),
      dispatch(fetchAverageSalesPriceTrendData()),
    ]);
  }, [params]);

  const granularitiesList = useMemo(() => {
    let list = [];
    if (date_range?.includes("-")) {
      const startEnd = getStartEnd(date_range);
      const diff = moment(startEnd.end, "DD/MM/YYYY").diff(moment(startEnd.start, "DD/MM/YYYY"), "days");
      const toEnable = getGranularitiesToEnable(diff);
      list = toEnable;
    } else {
      date_range?.includes("week")
        ? (list = ["daily"])
        : date_range?.includes("month")
          ? (list = ["daily", "weekly"])
          : date_range?.includes("quarter")
            ? (list = ["weekly", "monthly"])
            : (list = ["monthly", "quarterly"]);
    }
    return list;
  }, [date_range]);

  const [chartGranularity, setChartGranularity] = useState("daily");

  const handleGranularityRadioChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = evt;

      setChartGranularity(value);
    },
    [setChartGranularity]
  );

  useEffect(() => {
    dispatch(setSpecificParameter("granularity", chartGranularity));
  }, [chartGranularity]);

  useEffect(() => {
    if (!granularitiesList?.includes(granularity)) dispatch(setSpecificParameter("granularity", granularitiesList[0]));
  }, [granularitiesList, granularity, date_range, chartGranularity]);

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Used Cars", page: "Used Cars" });
    dispatch(fetchLastWeekDates());
  }, [location.pathname]);

  useEffect(() => {
    dispatch(fetchCpoOperationalFilters());
  }, [dispatch, params]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={
          <UsedCarsFilters
            granularityOptions={
              <UsedCarsGranularityOptions
                onHandleRadioClick={handleGranularityRadioChange}
                granularity={granularity}
                name={"cpo_operational_granularities"}
                dateValue={date_range}
                granularitiesList={granularitiesList}
              />
            }
          />
        }
        dataDocumentation="cpo_operational"
        lastDataRefresh={lastDataRefresh}
        pageTitle={
          <PageTitle
            dataDocumentation="cpo_operational"
            tooltipText="Market values are displayed by default. Top and bottom dealers based on sales volume, as well as up to 5 dealers can be selected in DEALER filter."
          />
        }
      >
        <div className="cpo_operational_sotu">
          <BaseTrends />
          <div className="dealer_tables primary_background">
            <DealerSalesDataTable />
          </div>
          <InventoryGraphs />
          <RatioCharts />
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
};

export default CpoOperational;
