import React, { useEffect } from 'react'
import { DashboardLayout } from '../../../components/Layouts'
import CcsMonetizationFilters from '../../../components/Filters/CcsMonetizationFilters'
import { PageTitle } from '../../../components/PageContent/subs'
import { DefaultPageContent } from '../../../components/PageContent'
import BackButton from '../../../components/PageContent/subs/BackButtonLink'
import MetricTile from './components/MetricTile'
import { useDispatch } from 'react-redux'
import { setPageName } from '../../../actions'
import MarketBreakdownTile from './components/MarketBreakdownTile'
import ModelBreakdownTile from './components/ModelBreakdownTile'
import { getCcsMonetizationLastDataRefresh, getSvtSubscriptionsSummary } from '../../../api/ccsMonetizationApi'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

const StvSubscriptions = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  const { data: lastDataRefreshData, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["stvSubscriptionsLastDataRefresh"],
    queryFn: getCcsMonetizationLastDataRefresh,
    enabled: false,
  });

  useEffect(() => {
    refetchLastDataRefresh()
    dispatch(setPageName("SVT Subscription"))
  }, []);

  const { data: summaryData, refetch: refetchSummaryData, isFetching } = useQuery({
    queryKey: ["svtSubscriptionsSummaryData", history.location.search],
    queryFn: getSvtSubscriptionsSummary,
    enabled: false,
  });

  useEffect(() => {
    refetchSummaryData()
  }, [history.location.search])

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<CcsMonetizationFilters dashboard='SVT Subscription' />}
        dataDocumentation="ccs_monetization"
        lastDataRefresh={lastDataRefreshData?.date ?? ""}
        pageTitle={<PageTitle dataDocumentation="ccs_monetization" backButton={<BackButton id={"stv_subscriptions"} link="/ccs_vital_signs/ccs_monetization" />} />}
      >
        <div className='ccs-monetization-layout'>
          <div className='row-2'>
            <MetricTile title='SVT Subscriptions Number' value={summaryData?.svt_subscription_number} tooltipText='Total number of Stolen Vehicle Tracking Subscriptions' isLoading={isFetching} formatValue={true} />
            <MetricTile title='SVT Revenue' value={summaryData?.svt_revenue} tooltipText='Total revenue specifically for Stolen Vehicle Tracking CCS service' isLoading={isFetching} formatValue={true} currency='€' />
          </div>
          <MarketBreakdownTile />
          <ModelBreakdownTile />
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  )
});

export default StvSubscriptions