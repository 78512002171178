import React, { useEffect, useMemo, useState } from 'react'
import { DefaultTable } from '../../../components/Tables/subs'
import { isNull } from '../../../utils/utilityFunctions';
import NewRatings from '../../../components/Ratings/NewRatings';
import { Tooltip } from 'react-tippy';
import { ToggleSwitch } from '../../../components/Toogle';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getTotalOrderValueTable } from '../../../api/ecommerce';
import LoadingEllipsis from '../../../components/Loading/LoadingEllipsis';
import { NoDataMsg } from '../../../components/AppMessages';

const TotalOrderValueTable = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { market, currency } = useSelector((state: RootStateOrAny) => state.parameters)
  const [toggleValue, setToggleValue] = useState<boolean>(true)

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["totalOrderValueTable", history.location.search, toggleValue],
    queryFn: () => getTotalOrderValueTable(toggleValue),
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [history.location.search, toggleValue])

  const columns = useMemo(
    () => [
      {
        id: "market",
        Header: market == "All" ? "Top Markets" : "Top Models",
        accessor: "market",
      },
      {
        id: "totalOrderValue",
        Header: () => {
          return (
            <div style={{ display: "flex" }}>
              <Tooltip
                position="top-end"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                distance={50}
                delay={1}
                hideDelay={1}
                duration={1}
                title={"Visitors who interact with digital buying tools and the conversion rate to order after interaction"}
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
              <div>{toggleValue ? "Total Order Value" : "Total Online Orders"}</div>
            </div>
          )
        },
        accessor: toggleValue ? "total_order_value" : "total_order_volume",
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {!isNull(value) && <NewRatings value={value} />}
            {!isNull(value) && toggleValue ? currency : ""}
            {" "}{isNull(value) ? "n/a" : value?.toLocaleString()}
          </div>
        ),
      },
      {
        id: "totalExtraValue",
        Header: () => {
          return (
            <div style={{ display: "flex" }}>
              <Tooltip
                position="top-end"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                distance={50}
                delay={1}
                hideDelay={1}
                duration={1}
                title={"Visitors who interact with digital buying tools and the conversion rate to order after interaction"}
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
              <div>{toggleValue ? "Total Extra Value" : "Online Orders w Extras"}</div>
            </div>
          )
        },
        accessor: toggleValue ? "total_extra_value" : "total_extra_volume",
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {!isNull(value) && <NewRatings value={value} />}
            {!isNull(value) && toggleValue ? currency : ""}
            {" "}{isNull(value) ? "n/a" : value?.toLocaleString()}
          </div>
        ),
      },
      {
        id: "percExtraVsOrderValue",
        Header: toggleValue ? "% Extra vs Order Value" : "Extra Take Rate",
        accessor: toggleValue ? "perc_extra_vs_order_value" : "perc_extra_vs_order_volume",
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {!isNull(value) && <NewRatings value={value} />}
            {isNull(value) ? "n/a" : value?.toLocaleString()}
            {!isNull(value) && "%"}
          </div>
        ),
      },
    ],
    [data, toggleValue, market]
  );
  return (
    <div className="ecommerce-tile">
      <div className="tile-header">
        <div className="header-title">
          <p>Total Orders broken down by {market == "All" ? "Market" : "Model"}</p>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            title={
              "The total number of order value or volume based on the toggle selection completed via 'Reserve online'  (Considering both BTO, STO and CPO)"
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <ToggleSwitch
          activeToggleLabel='Value'
          inactiveToggleLabel='Volume'
          active={toggleValue}
          handleToggleClick={() => setToggleValue(prevState => !prevState)}
        />
      </div>
      {data ?
        <DefaultTable
          columns={columns}
          data={data}
          id={"totalOrderValueTable"}
          isChildComponent={false}
          initialSortColId={{ id: "totalOrderValue", desc: true }}
          hiddenCols={[]}
          extraClass="verbatimTable"
          showPagination={true}
          pageSize={10}
        />
        :
        isFetching ?
          <LoadingEllipsis isLoading={isFetching} />
          :
          <NoDataMsg />
      }
    </div>

  )
})

export default TotalOrderValueTable