import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { fetchExecutiveSummaryLastDataRefresh, setPageName } from "../../../actions";
import "../../../assets/styles/pages/executiveSummaryDis.scss";
import ExecutiveSummaryDisFilters from "../../../components/Filters/ExecutiveSummaryDashboard/ExecutiveSummaryDisFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { EXECUTIVE_SUMMARY_BTN_FILTERS } from "../../../constants/executiveSummaryConstants";
import AggregatedDataByMarket from "./components/AggregatedDataByMarket";
import AggregatedPurchaseFunnel from "./components/AggregatedPurchaseFunnel";
import AggregatedPurchaseFunnelTable from "./components/AggregatedPurchaseFunnelTable";
import ChannelMixPerformanceTable from "./components/ChannelMixPerformanceTable";
import DetailedComparisons from "./components/DetailedComparisons";
import DigitallyInfluencedSalesSummaryTable from "./components/DigitallyInfluencedSalesSummaryTable";
import KeyIndicators from "./components/KeyIndicators";
import TimeSeriesAggregatedData from "./components/TimeSeriesAggregatedData";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";

const ExecutiveSummaryDis = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.executive_summary_last_data_refresh);

  useEffect(() => {
    dispatch(setPageName("Executive Summary - Digitally Influenced Sales"));
    eventTracking(MixpanelEvents.page_view, { dashboard: "Executive Summary", page: "Digitally Influenced Sales" });
  }, []);

  useEffect(() => {
    dispatch(fetchExecutiveSummaryLastDataRefresh());
  }, [history.location.search]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<ExecutiveSummaryDisFilters filterBtns={EXECUTIVE_SUMMARY_BTN_FILTERS} />}
        dataDocumentation="executive_summary"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="executive_summary" />}
      >
        <div className="executive-summary">
          <div className="grid-row-2">
            <KeyIndicators />
            <div className="tile">
              <div className="tile-header">
                <p>Digitally Influenced Sales Summary</p>
                <Tooltip
                  position="right"
                  className={"normal_tippy"}
                  trigger="mouseenter"
                  interactive={true}
                  distance={220}
                  delay={1}
                  hideDelay={1}
                  duration={1}
                  html={
                    <div>
                      <p>DIS%: Digitally influenced sales / Total private sales</p>
                      <p>Units: Number of confirmed new car sales that can be attributed to OEM managed digital activity</p>
                      <p>vs LY: DIS% and units compared with same period in the previous year</p>
                      <p>vs BP: DIS% compared with forecast for the period</p>
                    </div>
                  }
                  size={"small"}
                >
                  <span className="info_tooltip" />
                </Tooltip>
              </div>
              <DigitallyInfluencedSalesSummaryTable />
            </div>
          </div>
          <div className="grid-row-2">
            <div>
              <div className="title">Aggregated data by market</div>
              <AggregatedDataByMarket />
            </div>

            <div>
              <div className="title">Time-series aggregated data</div>
              <TimeSeriesAggregatedData />
            </div>
          </div>
          <div className="grid-row-1">
            <div className="tile">
              <div className="tile-header">Channel Mix Performance</div>
              <ChannelMixPerformanceTable />
            </div>
          </div>
          <div className="grid-row-1">
            <div className="tile">
              <div className="tile-header">Aggregated purchase funnel view</div>
              <div className="grid-row-2">
                <div className="tile">
                  <AggregatedPurchaseFunnel />
                </div>
                <div className="tile">
                  <div className="aggregated-purchase-funnel-table-container">
                    <AggregatedPurchaseFunnelTable />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-row-1">
            <div className="tile">
              <div className="tile-header">
                Detailed purchase funnel view
                <div>
                  <Tooltip
                    position="right"
                    className={"normal_tippy"}
                    trigger="mouseenter"
                    animation="none"
                    interactive={true}
                    distance={50}
                    delay={1}
                    hideDelay={1}
                    duration={1}
                    size={"small"}
                    title={
                      "The following tables are not impacted by LEAD SOURCE filter as it will always show Online OEM channels data. Please note that Private Sales data is not distinguishable by channel therefore it’s total data is shown."
                    }
                  >
                    <span className="info_tooltip" />
                  </Tooltip>
                </div>
              </div>
              <div className="sub-title">
                Nissan OEM channels are Nissan OEM managed channels (e.g. Tier 1 Brand website, Dealer website, E-commerce etc) apart from Japan where the data is not available at a split level but at a total level.
              </div>
              <DetailedComparisons />
            </div>
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default ExecutiveSummaryDis;
