import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import MultipleLineChart from '../../../../components/Charts/CCSMonetizaation/MiltipleLineChart'
import { ThemeContext } from '../../../../context'
import { ALL_OPTION_NO_SPACE, MARKETING_ACTIVITY_COLORS } from '../../../../constants'
import { DefaultTable } from '../../../../components/Tables/subs'
import Ratings from '../../../../components/Ratings/Ratings'
import { RootStateOrAny, useSelector } from 'react-redux'
import { DefaultFilter } from '../../../../components/Filters/common'
import { eventTracking, MixpanelEvents } from '../../../../utils/userTracking'
import { closeFilterOptions } from '../../../../components/Filters/subs/helpers'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getTakeRateBreakdownTable, getTakeRateBreakdownTrend } from '../../../../api/ccsMonetizationApi'
import { NoDataMsg } from '../../../../components/AppMessages'
import { Tooltip } from 'react-tippy'
import { ToggleSwitch } from '../../../../components/Toogle'
import LoadingEllipsis from '../../../../components/Loading/LoadingEllipsis'

const TakeRates = withRouter((props: RouteComponentProps) => {
  const { history } = props
  const { theme } = useContext(ThemeContext)

  const { market: marketParamValue } = useSelector((state: RootStateOrAny) => state.parameters)

  const [toggleState, setToggleState] = useState(true)

  const { data: trendData, isFetching: trendIsFetching, refetch: trendDataRefetch } = useQuery({
    queryKey: ["takeRateBreakedownTrendData", history.location.search, toggleState],
    queryFn: () => getTakeRateBreakdownTrend(!toggleState),
    enabled: false,
  });

  const { data: tableData, isFetching: tableIsFetching, refetch: tableDataRefetch } = useQuery({
    queryKey: ["takeRateBreakdownTableData", history.location.search],
    queryFn: getTakeRateBreakdownTable,
    enabled: false,
  });

  useEffect(() => {
    trendDataRefetch()
  }, [history.location.search, toggleState])

  useEffect(() => {
    tableDataRefetch()
  }, [history.location.search])

  const isMarkets = useMemo(() => {
    return marketParamValue == ALL_OPTION_NO_SPACE || marketParamValue.split(",")?.length > 1
  }, [marketParamValue])

  const columns = useMemo(
    () => [
      {
        id: isMarkets ? "markets" : "services",
        Header: isMarkets ? "G5 markets" : "CCS services",
        accessor: isMarkets ? `market` : `service`,
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
      },
      {
        Header: "Customer take rate",
        accessor: `customer_take_rate`,
        id: "customerTakeRate",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: ({ value }: { value: number }) => {
          return (
            <div className="table-cell">
              <Ratings value={value} isPercentageValue={false} testValue={false} /> {value ? `${Math.abs(value)}%` : "n/a"}
            </div>
          );
        },
      },
      {
        Header: "Service take rate",
        accessor: `service_take_rate`,
        id: "serviceTakeRate",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: ({ value }: { value: number }) => {
          return (
            <div className="table-cell">
              <Ratings value={value} isPercentageValue={false} testValue={false} /> {value ? `${Math.abs(value)}%` : "n/a"}
            </div>
          );
        },
      },
    ],
    [marketParamValue, isMarkets]
  );

  return (
    <div className='column-2'>
      <div className='tile'>
        <div className='tile-header'>
          <div className='title'>
            <div>
              Customer take rate per Market
            </div>
            <Tooltip
              position="top"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              title={"The date used in that graph is the Broadcast date, meaning the month the customer received the CRM email."}
              size={"small"}
            >
              <span className="info_tooltip" />
            </Tooltip>
          </div>
          <ToggleSwitch
            activeToggleLabel="Non-cumulative"
            inactiveToggleLabel="Cumulative"
            active={toggleState}
            handleToggleClick={() => setToggleState(!toggleState)}
          />
        </div>
        <div className='tile-content'>
          {trendData?.data && trendData?.data?.length ?
            (
              <MultipleLineChart
                chartId='takeRatesLineChart'
                data={trendData.data}
                seriesList={trendData.series}
                theme={theme}
                isLoading={trendIsFetching}
                seriesColors={MARKETING_ACTIVITY_COLORS}
                isPercentage={true}
              />
            ) : (
              <NoDataMsg />
            )}
        </div>
      </div>
      <div className='tile'>
        <div className='tile-header'>
          Take rates per {isMarkets ? "Market" : "CCS service"}
        </div>
        <div className='tile-content'>
          {tableData && tableData.length > 0 ? (
            <DefaultTable
              columns={columns}
              data={tableData ?? []}
              id={"visits_trend_breakdown"}
              data-test-id={"visits_trend_breakdown"}
              hiddenCols={[]}
              initialSortColId={{ id: "reached_volumes", desc: true }}
              isChildComponent={false}
            />
          ) : tableIsFetching ? (
            <LoadingEllipsis isLoading={tableIsFetching} />
          ) : (
            <NoDataMsg />
          )}
        </div>
      </div>
    </div>

  )
})

export default TakeRates