import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageName } from "../../../actions";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { DEALER_DEEP_DIVE_METRICS, MODEL_DEEP_DIVE_METRICS, VOC_BTN_FILTERS } from "../../../constants/vocConstants";
import { PageTitle } from "../../../components/PageContent/subs";
import VocQuickAfterSalesFilters from "../../../components/Filters/Voc/VocQuickAfterSalesFilters";
import "../../../assets/styles/pages/quickVocSales.scss";
import { useQuery } from "@tanstack/react-query";
import PerformanceByMarket from "../QuickVocSales/components/PerformanceByMarket";
import MarketDeepDive from "../QuickVocSales/components/MarketDeepDive";
import {
  getDealerDeepDiveTable,
  getDealerOverallPerformanceTrend,
  getDealerScoreDistribution,
  getMarketDeepDiveTable,
  getMarketDeepDiveTrendline,
  getModelDeepDiveTable,
  getModelOverallPerformanceTrend,
  getModelScoreDistribution,
  getModelTypeDeepDiveTable,
  getModelTypeOverallPerformanceTrend,
  getModelTypeScoreDistribution,
  getPerformanceByMarketMap,
  getQuickVocAfterSalesFilters,
  getQuickVocAfterSalesLastRefreshDate,
} from "../../../api/quickVocAfterSales";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import DeepDiveBreakdown from "../QuickVocSales/components/DeepDiveBreakdown";
import { RouteComponentProps, withRouter } from "react-router-dom";

const QuickVocAfterSales = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();

  const { history } = props;

  const { data: lastDataRefreshData, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["quickVocAfterSalesLastDataRefresh"],
    queryFn: getQuickVocAfterSalesLastRefreshDate,
    enabled: false,
  });

  useEffect(() => {
    dispatch(setPageName("Quick VOC After Sales"));
    eventTracking(MixpanelEvents.page_view, { dashboard: "VOC", page: "Quick VOC After Sales" });
  }, [dispatch]);

  useEffect(() => {
    refetchLastDataRefresh();
  }, [history.location.search])

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={
          <VocQuickAfterSalesFilters filterBtns={VOC_BTN_FILTERS} queryFn={getQuickVocAfterSalesFilters} pageName="Quick VOC After Sales" />
        }
        dataDocumentation="voc"
        lastDataRefresh={lastDataRefreshData?.date ?? ""}
        pageTitle={<PageTitle dataDocumentation="voc" />}
      >
        <div className="quick-voc-sales">
          <PerformanceByMarket queryFn={getPerformanceByMarketMap} />
          <MarketDeepDive tableQueryFn={getMarketDeepDiveTable} trendlineQueryFn={getMarketDeepDiveTrendline} />
          <DeepDiveBreakdown
            mainTitle="Dealer Deep Dive"
            overallTableTitle="Dealers overall performance"
            overtimeTrendTitle="Dealers overtime performance"
            volumeOfResponsesTitle="Dealers score distribution"
            metrics={DEALER_DEEP_DIVE_METRICS}
            metric="dealer"
            tableQueryFn={getDealerDeepDiveTable}
            trendQueryFn={getDealerOverallPerformanceTrend}
            distributionQueryFn={getDealerScoreDistribution}
          />
          <DeepDiveBreakdown
            mainTitle="Model Deep Dive"
            overallTableTitle="Models overall performance"
            overtimeTrendTitle="Models overtime performance"
            volumeOfResponsesTitle="Models score distribution"
            metrics={MODEL_DEEP_DIVE_METRICS} metric="model"
            tableQueryFn={getModelDeepDiveTable}
            trendQueryFn={getModelOverallPerformanceTrend}
            distributionQueryFn={getModelScoreDistribution}
          />
          <DeepDiveBreakdown
            mainTitle="Model Type Deep Dive"
            overallTableTitle="Model type overall performance"
            overtimeTrendTitle="Model type overtime performance"
            volumeOfResponsesTitle="Model type score distribution"
            metric="model_type"
            tableQueryFn={getModelTypeDeepDiveTable}
            trendQueryFn={getModelTypeOverallPerformanceTrend}
            distributionQueryFn={getModelTypeScoreDistribution}
          />
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default QuickVocAfterSales;
