import React, { useContext, useEffect, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DeepDiveMultipleBarLineChart from '../../../../components/Charts/ExecutiveSummary/DeepDiveMultipleBarLineChart';
import { ThemeContext } from '../../../../context';
import { useQuery } from '@tanstack/react-query';
import { NoDataMsg } from '../../../../components/AppMessages';
import { GRAY } from '../../../../constants';

interface Props extends RouteComponentProps {
  metric: string;
  getDataFn: () => any
  legendFontSize?: number;
}

const ConfiguratorVsOverallPagesTile = withRouter((props: Props) => {
  const { metric, history, getDataFn, legendFontSize } = props;
  const { theme } = useContext(ThemeContext);

  const { data, refetch, isFetching } = useQuery({
    queryKey: [`${metric.replace(/ /g, "_")}ConfigVsOverall`, history.location.search],
    queryFn: getDataFn,
    enabled: false
  });

  useEffect(() => {
    refetch()
  }, [history.location.search])

  const coloredSeries = useMemo(() => {
    if (data?.series) {
      return data.series.map((series: any) => {
        return {
          ...series,
          color: series.type === 'bar' ? GRAY : undefined
        }
      })
    }
    return []
  }, [data?.series])

  return (
    <div className='tile'>
      <div className="tile-header">
        <div className="title-container">
          {metric} comparison Configurator vs Overall pages
        </div>
      </div>
      <div className="tile-content">
        {data?.data || isFetching ?
          <DeepDiveMultipleBarLineChart
            chartName={`${metric.toLowerCase().replace(/ /g, '_')}ConfiguratorVsOverallPages`}
            data={data?.data ?? []}
            seriesList={coloredSeries}
            theme={theme}
            isLoading={isFetching}
            legendFontSize={legendFontSize}
          />
          :
          <NoDataMsg />
        }
      </div>
    </div>
  )
});

export default ConfiguratorVsOverallPagesTile