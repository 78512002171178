import React, { useContext, useEffect } from 'react'
import { ThemeContext } from '../../../context';
import { Tooltip } from 'react-tippy';
import EcommerceBarLineTrendChart from '../../../components/Charts/Ecommerce/EcommerceStackedBarLineChart';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getOrderNumberWithUpsellTrend } from '../../../api/ecommerce';
import { CYAN, GRAY, ORANGE } from '../../../constants';
import { RootStateOrAny, useSelector } from 'react-redux';

const OrderNumberWithUpsell = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { theme } = useContext(ThemeContext);
  const { currency } = useSelector((state: RootStateOrAny) => state.parameters);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["orderNumberWithUpsellTrend", history.location.search],
    queryFn: getOrderNumberWithUpsellTrend,
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [history.location.search])

  return (
    <div className="ecommerce-tile">
      <div className="tile-header">
        <div className="header-title">
          <p>Order Number with Upsell</p>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            title={
              "Shows the volume of orders with options and accessories added and the per cent take rate with online order completion"
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <EcommerceBarLineTrendChart data={data?.data ?? []} seriesList={data?.series ?? []} chartId="orderNumberWithUpsellChart" theme={theme} isLoading={isFetching} barColorMapping={[CYAN, ORANGE]} lineColorMapping={[GRAY]} lineIsPercentage={true} currency={currency} />
    </div>
  );
})

export default OrderNumberWithUpsell