import React, { useContext, useEffect } from 'react'
import ExecutiveSummaryDonutChart from '../../../../components/Charts/ExecutiveSummary/ExecutiveSummaryDonutChart'
import { ThemeContext } from '../../../../context'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getConfiguratorContributionToVoc } from '../../../../api/executiveSummaryProductPerformanceDeepDive'

const ConfiguratorContributionToVoc = withRouter((props: RouteComponentProps) => {
  const { theme } = useContext(ThemeContext)
  const { history } = props;

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["configuratorContributionToVocData", history.location.search],
    queryFn: getConfiguratorContributionToVoc,
    enabled: false
  });

  useEffect(() => {
    refetch()
  }, [history.location.search])

  return (
    <div className='tile'>
      <div className="tile-header">
        <div className='title-container'>
          Configurator contribution to VOC
        </div>
      </div>
      <div className="tile-content">
        <ExecutiveSummaryDonutChart
          chartId='configuratorContributionToVocChart'
          data={data}
          theme={theme}
          isLoading={isFetching}
          valueField='value'
          categoryField='category'
          radius={80}
          tooltipValuesMapping={{ "Contribution % ": "value", "# of feedback": "volume" }}
        />
      </div>
    </div>
  )
})

export default ConfiguratorContributionToVoc