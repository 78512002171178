import React, { useMemo } from 'react'
import Ratings from '../../../../components/Ratings/Ratings';
import { useSortBy, useTable } from 'react-table';
import LoadingEllipsis from '../../../../components/Loading/LoadingEllipsis';
import { NoDataMsg } from '../../../../components/AppMessages';

interface Props {
  data: any
  isLoading: boolean;
  metric: string;
}

const DeepDiveOveralTable = (props: Props) => {
  const { data, isLoading, metric } = props;

  const cols = useMemo(
    () => [
      {
        Header: "",
        accessor: metric,
      },
      {
        Header: "Model Type",
        accessor: "model_type",
      },
      {
        Header: "Volume",
        accessor: "volume",
        Cell: ({ value }: { value: number | null; row: any }) => {
          return getValueCellContent(value);
        },
      },
      {
        Header: "Resp%",
        accessor: "response_rate",
        Cell: ({ value }: { value: number | null; row: any }) => {
          return getValueCellContent(value, true);
        },
      },
      {
        Header: "Monthly Satisfaction Score / R3M Satisfaction Score",
        accessor: "monthly_satisfaction_score",
        Cell: ({ value, row: { original } }: { value: number | null; row: any }) => {
          return (
            <div className="satisfaction-score-cell">
              <Ratings
                value={original?.monthly_satisfaction_score ? original?.monthly_satisfaction_score : "n/a"}
                isPercentageValue={false}
              />
              <span
                className={`${original?.monthly_satisfaction_score >= 4 ? "text-green" : original?.monthly_satisfaction_score >= 3.9 ? "text-green" : original?.monthly_satisfaction_score >= 1 ? "text-red" : ""
                  }`}
              >
                {original?.monthly_satisfaction_score?.toFixed(2)}
              </span>
              <span>&nbsp;/&nbsp;</span>
              <span
                className={`${original?.r3m_satisfaction_score >= 4 ? "text-green" : original?.r3m_satisfaction_score >= 3.9 ? "text-green" : original?.r3m_satisfaction_score >= 1 ? "text-red" : ""
                  }`}
              >
                {original?.r3m_satisfaction_score?.toFixed(2)}
              </span>
            </div>
          );
        },
      },
    ].filter((col) => metric == "model" ? true : col.Header != "Model Type"),
    [data, metric]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: cols,
    data: data,
  },
    useSortBy
  );

  const getValueCellContent = (value: number | null, isPercentage?: boolean) => {
    return <span>{value == null ? "" : `${isPercentage ? value.toFixed(2) : value.toLocaleString()}${isPercentage ? "%" : ""}`}</span>;
  };

  return isLoading ? (
    <LoadingEllipsis isLoading={isLoading} />
  ) : data && data.length > 0 ? (
    <div className="market-deep-dive-table-container">
      <table {...getTableProps()} className={`market-deep-dive-table ${metric}-table`}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map((column) => {
                const sortClass =
                  column.canSort
                    ? column?.isSorted
                      ? column.isSortedDesc
                        ? "down"
                        : "up"
                      : ""
                    : "disabled";

                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.getHeaderProps().key}>
                    <div className={`sortable ${sortClass}`} style={{ display: "flex" }}>
                      {column.render("Header")}
                    </div>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <NoDataMsg />
  );
};

export default DeepDiveOveralTable