import React, { useContext, useEffect, useMemo } from 'react'
import ExecutiveSummaryStackedBarLineChart from '../../../../components/Charts/ExecutiveSummary/ExecutiveSummaryStackedBarLineChart'
import { ThemeContext } from '../../../../context'
import DeepDiveMultipleBarLineChart from '../../../../components/Charts/ExecutiveSummary/DeepDiveMultipleBarLineChart'
import { useQuery } from '@tanstack/react-query'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { getOverallConfiguratorVoc } from '../../../../api/executiveSummaryProductPerformanceDeepDive'
import { FOREST_GREEN, GOLDEN_YELLOW, PURE_ORANGE, RACING_GREEN, RED } from '../../../../constants'
import { NoDataMsg } from '../../../../components/AppMessages'
import LoadingEllipsis from '../../../../components/Loading/LoadingEllipsis'

const ratingColorMapping = {
  "Rating 1": RED,
  "Rating 2": PURE_ORANGE,
  "Rating 3": GOLDEN_YELLOW,
  "Rating 4": RACING_GREEN,
  "Rating 5": FOREST_GREEN,
};

const OverallConfiguratorVoc = withRouter((props: RouteComponentProps) => {
  const { theme } = useContext(ThemeContext);
  const { history } = props;

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["overallConfiguratorData", history.location.search],
    queryFn: getOverallConfiguratorVoc,
    enabled: false
  });

  useEffect(() => {
    refetch()
  }, [history.location.search])

  const colouredSeries = useMemo(() => {
    if (data?.series) {
      return data.series.map((series: { name: keyof typeof ratingColorMapping }) => ({ ...series, color: ratingColorMapping[series.name] }))
    }
    return []
  }, [data?.series])

  return (
    <div className='tile'>
      <div className='tile-header'>
        <div className="title-container">
          Overall configurator VOC
        </div>
      </div>
      <div className="tile-content">
        {isFetching ? <LoadingEllipsis isLoading={isFetching} />
          : data ? (
            <DeepDiveMultipleBarLineChart
              chartName='overallConfiguratorVoc'
              data={data?.data ?? []}
              seriesList={colouredSeries}
              theme={theme}
              isLoading={isFetching}
              stackedBars={true}
              isRating={true}
            />
          ) : <NoDataMsg />}
      </div>
    </div>
  )
});

export default OverallConfiguratorVoc