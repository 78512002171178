import React, { useCallback, useContext, useEffect, useState } from 'react'
import { DefaultFilter } from '../../../components/Filters/common'
import { eventTracking, MixpanelEvents } from '../../../utils/userTracking';
import { closeFilterOptions } from '../../../components/Filters/subs/helpers';
import ExecutiveSummaryMapChart from '../../../components/Charts/ExecutiveSummary/ExecutiveSummaryMapChart';
import { Tooltip } from 'react-tippy';
import { RootStateOrAny, useSelector } from 'react-redux';
import { DATE_FILTER_VALUE_MAPPING } from '../../../constants';
import LoadingEllipsis from '../../../components/Loading/LoadingEllipsis';
import { NoDataMsg } from '../../../components/AppMessages';
import EcommerceMapChart from '../../../components/Charts/Ecommerce/MapChart';
import { ThemeContext } from '../../../context';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getGlobalKeyIndicatorsData } from '../../../api/ecommerce';

const GlobalKeyIndicators = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);
  const { date_range, currency } = useSelector((state: RootStateOrAny) => state.parameters);

  const [metricFilterValue, setMetricFilterValue] = useState("Total Online Orders");
  const [dateString, setDateString] = useState("");

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["ecommerceGlobalKeyIndicators", history.location.search, metricFilterValue],
    queryFn: () => getGlobalKeyIndicatorsData(metricFilterValue),
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [history.location.search, metricFilterValue])

  useEffect(() => {
    if (Object.keys(DATE_FILTER_VALUE_MAPPING).includes(date_range)) {
      setDateString(DATE_FILTER_VALUE_MAPPING[date_range]);
    } else {
      const [start, end] = date_range.split("-");
      const startDate = moment(start, "MMMM YYYY").format("MMM YYYY");
      const endDate = moment(end, "MMMM YYYY").format("MMM YYYY");
      setDateString(`${startDate} - ${endDate}`);
    }
  }, [date_range]);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      setMetricFilterValue(optionValue);
      eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "Ecommerce" });
    }
    closeFilterOptions();
  }, []);

  return (
    <div className="tile map-tile">
      <div className="tile-header">
        <div className="title">
          Global Key Indicators
        </div>
        <Tooltip
          position="top"
          className={"normal_tippy"}
          trigger="mouseenter"
          animation="none"
          interactive={true}
          delay={1}
          hideDelay={1}
          duration={1}
          title="The map shows the performance by market based on the selected KPI in the unique filter"
          size={"small"}
        >
          <span className="info_tooltip" />
        </Tooltip>
        <div className="filters-container">
          <DefaultFilter
            list={["Total Online Orders", "Total Order Value", "Ecommerce Qualified Visits", "Ecommerce Leads"]}
            filterName={"metric"}
            filterLabel={"METRIC FILTER BY"}
            filterValue={metricFilterValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </div>
      </div>
      {data?.length > 0 ? (
        <EcommerceMapChart
          chartId="ecommerceKeyIndicatorsMapChart"
          data={data}
          metric={metricFilterValue}
          currentPeriodLabel={dateString}
          isLoading={isFetching}
          theme={theme}
          currency={metricFilterValue == "Total Order Value" ? currency : null}
        />
      ) : (
        <NoDataMsg />
      )}

      <LoadingEllipsis isLoading={isFetching} />
    </div>
  )
});

export default GlobalKeyIndicators