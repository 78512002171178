import React, { useEffect } from 'react'
import WebsitePerformanceTile from './WebsitePerformanceTile'
import { getLeadPerformanceMarketDeepDive } from '../../../../api/executiveSummaryProductPerformanceDeepDive'
import { useQuery } from '@tanstack/react-query'
import { RouteComponentProps, withRouter } from 'react-router-dom'


const WebsitePerformance = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["leadPerformanceMarketDeepDive"],
    queryFn: getLeadPerformanceMarketDeepDive,
    enabled: false
  });

  useEffect(() => {
    refetch();
  }, [history.location.search])

  return (
    <div className='row-2'>
      {data?.data?.map((marketData: any) => (
        <div key={marketData.market.replace(/ /g, "_")} className='tile'>
          <div className='tile-header'>
            <div className="title-container">
              {marketData.market}
            </div>
          </div>
          <div className="tile-content">
            <div className='row-2'>
              <WebsitePerformanceTile
                title='Leads'
                data={marketData?.data ?? []}
                series={data?.leads_series ?? []}
                market={marketData.market}
                isLoading={isFetching}
              />
              <WebsitePerformanceTile
                title='Build to Order | Stock to Order CTR'
                data={marketData?.data ?? []}
                series={data?.bto_sto_series ?? []}
                market={marketData.market}
                isLoading={isFetching}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
});

export default WebsitePerformance