import { retry, takeLatest } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { put } from "redux-saga/effects";
import {
  FETCH_ALL_REVIEWS,
  FETCH_APP_ANALYSIS_FEATURE_USAGE_DATA,
  FETCH_APP_ANALYSIS_WEB_USAGE_DATA,
  FETCH_APP_OPEN_RATE_DATA,
  FETCH_APP_RATINGS,
  FETCH_APP_RATINGS_AVG_DATA,
  FETCH_APP_RATINGS_BY_MARKET,
  FETCH_APP_RATINGS_BY_MARKET_CHART_DATA,
  FETCH_APP_RATINGS_CHART_DATA,
  FETCH_APP_RATINGS_DOWNLOADS_DATA,
  FETCH_APP_REVIEW_BREAKDOWN,
  FETCH_APP_REVIEW_BY_TOPIC,
  FETCH_APP_SATISFACTION_DATA,
  FETCH_BUSINESS_REVENUE_CHART_DATA,
  FETCH_BUSINESS_REVENUE_DATA,
  FETCH_CCS_JOURNEY_BANNER_DATA,
  FETCH_CCS_JOURNEY_DATA,
  FETCH_CONNECTED_CARS_CHART_DATA,
  FETCH_CONNECTED_CARS_DATA,
  FETCH_ENGAGEMENT_APP_DOWNLOADS_CHART_DATA,
  FETCH_ENGAGEMENT_APP_DOWNLOADS_DATA,
  FETCH_ENROLLED_CUSTOMERS_CHART_DATA,
  FETCH_ENROLLED_CUSTOMERS_DATA,
  FETCH_EUROPE_APP_RATINGS_AVG_DATA,
  FETCH_EUROPE_APP_RATINGS_AVG_TOTAL_DATA,
  FETCH_EUROPE_APP_RATINGS_TREND_DATA,
  FETCH_G8_PRIVATE_ACTIVATION_RATIO_CHART_DATA,
  FETCH_G8_PRIVATE_ACTIVATION_RATIO_DATA,
  FETCH_RENEWAL_CHURN_RATE,
  FETCH_RENEWAL_CHURN_RATE_CHART_DATA,
  FETCH_SALES_REVENUE_CHART_DATA,
  FETCH_SALES_REVENUE_DATA,
  FETCH_SALES_REVENUE_SOURCE,
  FETCH_TOP_USED_SERVICES,
  FETCH_USER_ENGAGEMENT,
  FETCH_USER_ENGAGEMENT_CHART_DATA,
  FETCH_VOC_CALL_CENTRE,
  FETCH_VOC_CALL_CENTRE_CHART_DATA,
  FETCH_WARRANTY_START_DATE,
} from "../actions/actionTypes";
import {
  setAllReviewsData,
  setAppAnalysisFeatureUsageData,
  setAppAnalysisWebUsageData,
  setAppOpenRateData,
  setAppRatings,
  setAppRatingsAvgData,
  setAppRatingsByMarket,
  setAppRatingsByMarketChartData,
  setAppRatingsChartData,
  setAppRatingsDownloadsData,
  setAppReviewBreakdown,
  setAppSatisfactionData,
  setBusinessRevenueChartData,
  setBusinessRevenueData,
  setCcsJourneyBannerData,
  setCcsJourneyData,
  setConnectedCarsChartData,
  setConnectedCarsData,
  setEngagementAppDownloadsChartData,
  setEngagementAppDownloadsData,
  setEnrolledCustomersChartData,
  setEnrolledCustomersData,
  setEuropeAppRatingsAvgData,
  setEuropeAppRatingsAvgTotalData,
  setEuropeAppRatingsTrendData,
  setG8PrivateActivationRatioChartData,
  setG8PrivateActivationRatioData,
  setRenewalChurnRateChartData,
  setRenewalChurnRateData,
  setReviewsByTopic,
  setSalesRevenueChartData,
  setSalesRevenueData,
  setSalesRevenueSource,
  setTopUsedServices,
  setUserEngagementChartData,
  setUserEngagementData,
  setVOCCallCentreChartData,
  setVOCCallCentreData,
  setWarrantyStartDateData,
} from "../actions/ccsCockpitActions";
import { setLoading } from "../actions/loadingActions";
import {
  fetchBusinessRevenuePostData,
  fetchConnectedCarsPostData,
  getAllReviewsData,
  getAppAnalysisFeatureUsageData,
  getAppAnalysisWebUsageData,
  getAppOpenRateData,
  getAppRatingsAvgData,
  getAppRatingsBreakdownAvgData,
  getAppRatingsBreakdownAvgTotalData,
  getAppRatingsBreakdownTrendData,
  getAppRatingsByMarketData,
  getAppRatingsData,
  getAppRatingsDownloadsData,
  getAppReviewBreakdownData,
  getAppReviewByTopicData,
  getAppSatisfactionData,
  getCCSJourneyBannerData,
  getCCSJourneyData,
  getEngagementAppDownloadsData,
  getEnrolledCustomers,
  getG8ActivationRatioData,
  getRenewalChurnRateData,
  getSalesRevenueData,
  getSalesRevenueSourceData,
  getTopUsedServicesData,
  getVOC_callCentre,
  getWarrantyStartDateData,
  postFetchUserEngagement,
} from "../api/ccsCockpitApi";
import { secondsDelay } from "../api/utils";
import {
  AppReviewInt,
  CCSBusinessRevenueChartData,
  CCSBusinessRevenueData,
  IAppAnalysisFeatureUsage,
  IReviews,
  ITopUsedServices,
  VOCCallCentreInt,
} from "../constants/interface/ccsCockpit";
import { IActivationOverallData } from "../constants/interface/europeActivation/activationData";

// Watcher sagas
export function* watchFetchCCSBusinessRevenueData() {
  yield takeLatest(FETCH_BUSINESS_REVENUE_DATA, fetchCCSBusinessRevenueData);
}

export function* watchFetchCCSBusinessRevenueChartData() {
  yield takeLatest(FETCH_BUSINESS_REVENUE_CHART_DATA, fetchCCSBusinessRevenueChartData);
}

export function* watchFetchCCSConnectedCarsData() {
  yield takeLatest(FETCH_CONNECTED_CARS_DATA, fetchCCSConnectedCarsData);
}

export function* watchFetchCCSConnectedCarsChartData() {
  yield takeLatest(FETCH_CONNECTED_CARS_CHART_DATA, fetchCCSConnectedCarsChartData);
}

export function* watchFetch_UserEngagementData() {
  yield takeLatest(FETCH_USER_ENGAGEMENT, fetchUserEngagementData);
}

export function* watchFetch_UserEngagementChartData() {
  yield takeLatest(FETCH_USER_ENGAGEMENT_CHART_DATA, fetchUserEngagementChartData);
}

export function* watchFetchEnrolledCustomersData() {
  yield takeLatest(FETCH_ENROLLED_CUSTOMERS_DATA, fetchEnrolledCustomersData);
}

export function* watchFetchEnrolledCustomersChartData() {
  yield takeLatest(FETCH_ENROLLED_CUSTOMERS_CHART_DATA, fetchEnrolledCustomersChartData);
}

export function* watchFetchTopUsedServices() {
  yield takeLatest(FETCH_TOP_USED_SERVICES, fetchTopUsedServicesData);
}

export function* watchFetchAppRatings() {
  yield takeLatest(FETCH_APP_RATINGS, fetchAppRatingsData);
}

export function* watchFetchAppRatingsChartData() {
  yield takeLatest(FETCH_APP_RATINGS_CHART_DATA, fetchAppRatingsChartData);
}

export function* watchFetchAppRatingsByMarket() {
  yield takeLatest(FETCH_APP_RATINGS_BY_MARKET, fetchAppRatingsByMarketData);
}

export function* watchFetchAppRatingsByMarketChartData() {
  yield takeLatest(FETCH_APP_RATINGS_BY_MARKET_CHART_DATA, fetchAppRatingsByMarketChartData);
}

export function* watchFetchRenewalChurnRateData() {
  yield takeLatest(FETCH_RENEWAL_CHURN_RATE, fetchRenewalChurnRateData);
}

export function* watchFetchRenewalChurnRateChartData() {
  yield takeLatest(FETCH_RENEWAL_CHURN_RATE_CHART_DATA, fetchRenewalChurnRateChartData);
}

export function* watchFetchAppReviewBreakdown() {
  yield takeLatest(FETCH_APP_REVIEW_BREAKDOWN, fetchAppReviewBreakdownData);
}

export function* watchVOC_CallCentre() {
  yield takeLatest(FETCH_VOC_CALL_CENTRE, fetchVOC_CallCentre);
}

export function* watchVOC_CallCentreBreakdown() {
  yield takeLatest(FETCH_VOC_CALL_CENTRE_CHART_DATA, fetchVOC_CallCentreBreakdownData);
}

export function* watchFetch_AllReviews() {
  yield takeLatest(FETCH_ALL_REVIEWS, fetch_AllReviews);
}

export function* watchFetchAppReviewByTopic() {
  yield takeLatest(FETCH_APP_REVIEW_BY_TOPIC, fetchAppReviewByTopicData);
}

export function* watchFetchAppReviewSatisfactionData() {
  yield takeLatest(FETCH_APP_SATISFACTION_DATA, fetchAppReviewSatisfactionData);
}

export function* watchFetchWarrantyStartDateSaga() {
  yield takeLatest(FETCH_WARRANTY_START_DATE, fetchWarrantyStartDateSaga);
}

export function* watchFetchAppAnalysisFeatureUsage() {
  yield takeLatest(FETCH_APP_ANALYSIS_FEATURE_USAGE_DATA, fetchAppAnalysisFeatureUsage);
}

export function* watchFetchAppAnalysisWebUsage() {
  yield takeLatest(FETCH_APP_ANALYSIS_WEB_USAGE_DATA, fetchAppAnalysisWebUsage);
}

export function* watchFetchAppRatingsDownloads() {
  yield takeLatest(FETCH_APP_RATINGS_DOWNLOADS_DATA, fetchAppRatingsDownloads);
}

export function* watchFetchEngagementAppDownloadsData() {
  yield takeLatest(FETCH_ENGAGEMENT_APP_DOWNLOADS_DATA, fetchEngagementAppDownloadsData);
}

export function* watchFetchEngagementAppDownloadsChartData() {
  yield takeLatest(FETCH_ENGAGEMENT_APP_DOWNLOADS_CHART_DATA, fetchEngagementAppDownloadsChartData);
}

export function* watchFetchAppOpenRate() {
  yield takeLatest(FETCH_APP_OPEN_RATE_DATA, fetchAppOpenRate);
}

export function* watchFetchCcsJourneyData() {
  yield takeLatest(FETCH_CCS_JOURNEY_DATA, fetchCcsJourneyData);
}

export function* watchFetchEuropeAppRatingsAvg() {
  yield takeLatest(FETCH_EUROPE_APP_RATINGS_AVG_DATA, fetchEuropeAppRatingsAvg);
}

export function* watchFetchEuropeAppRatingsAvgTotal() {
  yield takeLatest(FETCH_EUROPE_APP_RATINGS_AVG_TOTAL_DATA, fetchEuropeAppRatingsAvgTotal);
}

export function* watchFetchEuropeAppRatingsTrend() {
  yield takeLatest(FETCH_EUROPE_APP_RATINGS_TREND_DATA, fetchEuropeAppRatingsTrend);
}

export function* watchFetchAppRatingAvg() {
  yield takeLatest(FETCH_APP_RATINGS_AVG_DATA, fetchAppRatingAvg);
}

export function* watchFetchG8PrivateActivationRatioData() {
  yield takeLatest(FETCH_G8_PRIVATE_ACTIVATION_RATIO_DATA, fetchG8PrivateActivationRatioData);
}

export function* watchFetchCCSJourneyDataSaga() {
  yield takeLatest(FETCH_CCS_JOURNEY_DATA, fetchCCSJourneyDataSaga);
}

export function* watchFetchCCSJourneyBannerDataSaga() {
  yield takeLatest(FETCH_CCS_JOURNEY_BANNER_DATA, fetchCCSJourneyBannerDataSaga);
}

export function* watchFetchSalesRevenueSource() {
  yield takeLatest(FETCH_SALES_REVENUE_SOURCE, fetchSalesRevenueSource);
}

export function* watchFetchCCSSalesRevenueData() {
  yield takeLatest(FETCH_SALES_REVENUE_DATA, fetchCCSSalesRevenueData);
}

export function* watchFetchCCSSalesRevenueChartData() {
  yield takeLatest(FETCH_SALES_REVENUE_CHART_DATA, fetchCCSSalesRevenueChartData);
}

export function* watchFetchG8PrivateActivationRatioChartData() {
  yield takeLatest(FETCH_G8_PRIVATE_ACTIVATION_RATIO_CHART_DATA, fetchG8PrivateActivationRatioChartData);
}

// Worker sagas
export function* fetch_AllReviews() {
  try {
    yield put(setLoading("all_reviews", true));

    const response: IReviews = yield retry(4, secondsDelay, getAllReviewsData);

    if (!("error" in response)) {
      yield put(setAllReviewsData(response));
    }
  } catch (err) {
    console.log("Failed to fetch all reviews data");
  } finally {
    yield put(setLoading("all_reviews", false));
  }
}

export function* fetchUserEngagementData({ payload: markets }: AnyAction) {
  try {
    yield put(setLoading("user_engagement", true));

    const response: Array<any> = yield retry(4, secondsDelay, postFetchUserEngagement, markets);

    if (!("error" in response)) {
      yield put(setUserEngagementData(response));
    }
  } catch (err) {
    console.log("failed to fetch user engagement data");
  } finally {
    yield put(setLoading("user_engagement", false));
  }
}

export function* fetchUserEngagementChartData({ payload: markets }: AnyAction) {
  try {
    yield put(setLoading("user_engagement", true));

    const response: Array<any> = yield retry(4, secondsDelay, postFetchUserEngagement, markets, true);

    if (!("error" in response)) {
      yield put(setUserEngagementChartData(response));
    }
  } catch (err) {
    console.log("failed to fetch user engagement data");
  } finally {
    yield put(setLoading("user_engagement", false));
  }
}

export function* fetchEnrolledCustomersData({ payload: markets }: AnyAction) {
  try {
    yield put(setLoading("enrolled_customers", true));

    const response: Array<any> = yield retry(4, secondsDelay, getEnrolledCustomers, markets);

    if (!("error" in response)) {
      yield put(setEnrolledCustomersData(response));
    }
  } catch (err) {
    console.log("failed to fetch enrolled customers data");
  } finally {
    yield put(setLoading("enrolled_customers", false));
  }
}

export function* fetchEnrolledCustomersChartData({ payload: markets }: AnyAction) {
  try {
    yield put(setLoading("enrolled_customers", true));

    const response: Array<any> = yield retry(4, secondsDelay, getEnrolledCustomers, markets, true);

    if (!("error" in response)) {
      yield put(setEnrolledCustomersChartData(response));
    }
  } catch (err) {
    console.log("failed to fetch enrolled customers data");
  } finally {
    yield put(setLoading("enrolled_customers", false));
  }
}

export function* fetchCCSBusinessRevenueData({ payload: markets }: AnyAction) {
  try {
    yield put(setLoading("business_revenue_data", true));

    const response: CCSBusinessRevenueData = yield retry(4, secondsDelay, fetchBusinessRevenuePostData, markets);

    if (!("error" in response)) {
      yield put(setBusinessRevenueData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("business_revenue_data", false));
  }
}

export function* fetchCCSBusinessRevenueChartData({ payload: markets }: AnyAction) {
  try {
    yield put(setLoading("business_revenue_chart_data", true));

    const response: CCSBusinessRevenueChartData = yield retry(4, secondsDelay, fetchBusinessRevenuePostData, markets, true);

    if (!("error" in response)) {
      yield put(setBusinessRevenueChartData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("business_revenue_chart_data", false));
  }
}

export function* fetchCCSConnectedCarsData() {
  try {
    yield put(setLoading("connected_cars_data", true));

    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, fetchConnectedCarsPostData);

    if (!("error" in response)) {
      yield put(setConnectedCarsData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("connected_cars_data", false));
  }
}

export function* fetchCCSConnectedCarsChartData({ payload: breakdown }: AnyAction) {
  try {
    yield put(setLoading("connected_cars_chart_data", true));

    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, fetchConnectedCarsPostData, breakdown);

    if (!("error" in response)) {
      yield put(setConnectedCarsChartData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("connected_cars_chart_data", false));
  }
}

export function* fetchTopUsedServicesData() {
  try {
    yield put(setLoading("top_used_services", true));

    const response: ITopUsedServices = yield retry(4, secondsDelay, getTopUsedServicesData);
    if (!("error" in response)) {
      yield put(setTopUsedServices(response.all_data));
    }
  } catch (err) {
    console.log("failed to fetch top used services");
  } finally {
    yield put(setLoading("top_used_services", false));
  }
}

export function* fetchAppRatingsData({ payload: appStoreValue }: AnyAction) {
  try {
    yield put(setLoading("app_review", true));

    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getAppRatingsData, appStoreValue);
    if (!("error" in response)) {
      yield put(setAppRatings(response));
    }
  } catch (err) {
    console.log("failed to fetch app review data", err);
  } finally {
    yield put(setLoading("app_review", false));
  }
}

export function* fetchAppRatingsChartData({ payload: appStoreValue }: AnyAction) {
  try {
    yield put(setLoading("app_review", true));

    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getAppRatingsData, appStoreValue, true);
    if (!("error" in response)) {
      yield put(setAppRatingsChartData(response));
    }
  } catch (err) {
    console.log("failed to fetch app review data", err);
  } finally {
    yield put(setLoading("app_review", false));
  }
}

export function* fetchAppRatingsByMarketData({ payload: appStoreValue }: AnyAction) {
  try {
    yield put(setLoading("app_rating_by_market", true));

    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getAppRatingsByMarketData, appStoreValue);
    if (!("error" in response)) {
      yield put(setAppRatingsByMarket(response));
    }
  } catch (err) {
    console.log("failed to fetch app review data", err);
  } finally {
    yield put(setLoading("app_rating_by_market", false));
  }
}

export function* fetchAppRatingsByMarketChartData({ payload: appStoreValue }: AnyAction) {
  try {
    yield put(setLoading("app_rating_by_market", true));

    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getAppRatingsByMarketData, appStoreValue, true);
    if (!("error" in response)) {
      yield put(setAppRatingsByMarketChartData(response));
    }
  } catch (err) {
    console.log("failed to fetch app review data", err);
  } finally {
    yield put(setLoading("app_rating_by_market", false));
  }
}

export function* fetchRenewalChurnRateData() {
  try {
    yield put(setLoading("renewal_and_churn_rate", true));

    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getRenewalChurnRateData);
    if (!("error" in response)) {
      yield put(setRenewalChurnRateData(response));
    }
  } catch (err) {
    console.log("failed to fetch renewal and churn rate data", err);
  } finally {
    yield put(setLoading("renewal_and_churn_rate", false));
  }
}

export function* fetchRenewalChurnRateChartData() {
  try {
    yield put(setLoading("renewal_and_churn_rate", true));

    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getRenewalChurnRateData, true);
    if (!("error" in response)) {
      yield put(setRenewalChurnRateChartData(response));
    }
  } catch (err) {
    console.log("failed to fetch renewal and churn rate data", err);
  } finally {
    yield put(setLoading("renewal_and_churn_rate", false));
  }
}

export function* fetchAppReviewBreakdownData({ payload: appStoreValue }: AnyAction) {
  try {
    yield put(setLoading("app_review", true));

    const response: AppReviewInt = yield retry(4, secondsDelay, getAppReviewBreakdownData, appStoreValue);
    if (!("error" in response)) {
      yield put(setAppReviewBreakdown(response));
    }
  } catch (err) {
    console.log("failed to fetch app review breakdown data", err);
  } finally {
    yield put(setLoading("app_review", false));
  }
}

export function* fetchAppReviewByTopicData() {
  try {
    yield put(setLoading("app_review_by_topic", true));

    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getAppReviewByTopicData);
    if (!("error" in response)) {
      yield put(setReviewsByTopic(response));
    }
  } catch (err) {
    console.log("failed to fetch app review by topic data", err);
  } finally {
    yield put(setLoading("app_review_by_topic", false));
  }
}

export function* fetchAppReviewSatisfactionData() {
  try {
    yield put(setLoading("app_review_satisfaction", true));

    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getAppSatisfactionData);
    if (!("error" in response)) {
      yield put(setAppSatisfactionData(response));
    }
  } catch (err) {
    console.log("failed to fetch app satisfaction data", err);
  } finally {
    yield put(setLoading("app_review_satisfaction", false));
  }
}

export function* fetchVOC_CallCentre() {
  try {
    yield put(setLoading("call_centre", true));
    const response: VOCCallCentreInt = yield retry(4, secondsDelay, getVOC_callCentre);

    if (!("error" in response)) {
      yield put(setVOCCallCentreData(response));
    }
  } catch (err) {
    console.log("failed to get VOC call centre data", err);
  } finally {
    yield put(setLoading("call_centre", false));
  }
}

export function* fetchVOC_CallCentreBreakdownData({ payload: breakdown }: AnyAction) {
  try {
    yield put(setLoading("call_centre", true));
    const response: VOCCallCentreInt = yield retry(4, secondsDelay, getVOC_callCentre, breakdown);

    if (!("error" in response)) {
      yield put(setVOCCallCentreChartData(response));
    }
  } catch (err) {
    console.log("failed to get VOC call centre data", err);
  } finally {
    yield put(setLoading("call_centre", false));
  }
}

export function* fetchWarrantyStartDateSaga() {
  try {
    yield put(setLoading("warranty_start_date", true));
    const response: IActivationOverallData = yield retry(4, secondsDelay, getWarrantyStartDateData);
    if (!("error" in response)) {
      yield put(setWarrantyStartDateData(response));
    }
  } catch (err) {
    console.log("failed to get warranty start date data", err);
  } finally {
    yield put(setLoading("warranty_start_date", false));
  }
}

export function* fetchAppAnalysisFeatureUsage() {
  try {
    yield put(setLoading("feature_usage", true));
    const response: IAppAnalysisFeatureUsage = yield retry(4, secondsDelay, getAppAnalysisFeatureUsageData);

    if (!("error" in response)) {
      yield put(setAppAnalysisFeatureUsageData(response));
    }
  } catch (err) {
    console.log("failed to get app analysis feature usage data", err);
  } finally {
    yield put(setLoading("feature_usage", false));
  }
}

export function* fetchAppAnalysisWebUsage() {
  try {
    yield put(setLoading("web_usage", true));
    const response: any[] = yield retry(4, secondsDelay, getAppAnalysisWebUsageData);

    if (!("error" in response)) {
      yield put(setAppAnalysisWebUsageData(response));
    }
  } catch (err) {
    console.log("failed to get app analysis web usage data", err);
  } finally {
    yield put(setLoading("web_usage", false));
  }
}

export function* fetchAppRatingsDownloads() {
  try {
    yield put(setLoading("app_ratings_downloads", true));
    const response: any[] = yield retry(4, secondsDelay, getAppRatingsDownloadsData);

    if (!("error" in response)) {
      yield put(setAppRatingsDownloadsData(response));
    }
  } catch (err) {
    console.log("failed to get app downloads data", err);
  } finally {
    yield put(setLoading("app_ratings_downloads", false));
  }
}

export function* fetchEngagementAppDownloadsData() {
  try {
    yield put(setLoading("engagement_downloads", true));
    const response: any[] = yield retry(4, secondsDelay, getEngagementAppDownloadsData);

    if (!("error" in response)) {
      yield put(setEngagementAppDownloadsData(response));
    }
  } catch (err) {
    console.log("failed to get app downloads data", err);
  } finally {
    yield put(setLoading("engagement_downloads", false));
  }
}

export function* fetchEngagementAppDownloadsChartData() {
  try {
    yield put(setLoading("engagement_downloads", true));
    const response: any[] = yield retry(4, secondsDelay, getEngagementAppDownloadsData, true);

    if (!("error" in response)) {
      yield put(setEngagementAppDownloadsChartData(response));
    }
  } catch (err) {
    console.log("failed to get app downloads data", err);
  } finally {
    yield put(setLoading("engagement_downloads", false));
  }
}

export function* fetchAppOpenRate() {
  try {
    yield put(setLoading("open_rate", true));
    const response: any[] = yield retry(4, secondsDelay, getAppOpenRateData);

    if (!("error" in response)) {
      yield put(setAppOpenRateData(response));
    }
  } catch (err) {
    console.log("failed to get app open rate data", err);
  } finally {
    yield put(setLoading("open_rate", false));
  }
}

export function* fetchCcsJourneyData() {
  try {
    yield put(setLoading("ccs_journey", true));
    const response: any[] = yield retry(4, secondsDelay, getCCSJourneyData);

    if (!("error" in response)) {
      yield put(setCcsJourneyData(response));
    }
  } catch (err) {
    console.log("failed to get ccs journey data", err);
  } finally {
    yield put(setLoading("ccs_journey", false));
  }
}

export function* fetchG8PrivateActivationRatioData() {
  try {
    yield put(setLoading("g8_private_activation_ratio", true));
    const response: any[] = yield retry(4, secondsDelay, getG8ActivationRatioData);

    if (!("error" in response)) {
      yield put(setG8PrivateActivationRatioData(response));
    }
  } catch (err) {
    console.log("failed to get g8 private activation ratio data", err);
  } finally {
    yield put(setLoading("g8_private_activation_ratio", false));
  }
}

export function* fetchG8PrivateActivationRatioChartData() {
  try {
    yield put(setLoading("g8_private_activation_ratio", true));
    const response: any[] = yield retry(4, secondsDelay, getG8ActivationRatioData, true);

    if (!("error" in response)) {
      yield put(setG8PrivateActivationRatioChartData(response));
    }
  } catch (err) {
    console.log("failed to get g8 private activation ratio data", err);
  } finally {
    yield put(setLoading("g8_private_activation_ratio", false));
  }
}

export function* fetchEuropeAppRatingsAvg() {
  try {
    yield put(setLoading("europe_app_ratings", true));
    const searchParams = new URLSearchParams(window.location.search);
    const regionParam = searchParams.get("region");

    const response: any[] = yield retry(4, secondsDelay, getAppRatingsBreakdownAvgData, regionParam as string);

    if (!("error" in response)) {
      yield put(setEuropeAppRatingsAvgData(response));
    }
  } catch (err) {
    console.log("failed to get europe app ratings avg data", err);
  } finally {
    yield put(setLoading("europe_app_ratings", false));
  }
}

export function* fetchEuropeAppRatingsAvgTotal({ payload: markets }: AnyAction) {
  try {
    yield put(setLoading("europe_app_ratings", true));
    const searchParams = new URLSearchParams(window.location.search);
    const regionParam = searchParams.get("region");
    const response: any[] = yield retry(4, secondsDelay, getAppRatingsBreakdownAvgTotalData, regionParam as string, markets);

    if (!("error" in response)) {
      yield put(setEuropeAppRatingsAvgTotalData(response));
    }
  } catch (err) {
    console.log("failed to get europe app ratings avg total data", err);
  } finally {
    yield put(setLoading("europe_app_ratings", false));
  }
}

export function* fetchEuropeAppRatingsTrend({ payload: markets }: AnyAction) {
  try {
    yield put(setLoading("europe_app_ratings", true));
    const searchParams = new URLSearchParams(window.location.search);
    const regionParam = searchParams.get("region");
    const response: any[] = yield retry(4, secondsDelay, getAppRatingsBreakdownTrendData, regionParam as string, markets);

    if (!("error" in response)) {
      yield put(setEuropeAppRatingsTrendData(response));
    }
  } catch (err) {
    console.log("failed to get europe app ratings data", err);
  } finally {
    yield put(setLoading("europe_app_ratings", false));
  }
}

export function* fetchAppRatingAvg() {
  try {
    yield put(setLoading("app_review", true));
    const response: any[] = yield retry(4, secondsDelay, getAppRatingsAvgData);

    if (!("error" in response)) {
      yield put(setAppRatingsAvgData(response));
    }
  } catch (err) {
    console.log("failed to get app ratings avg data", err);
  } finally {
    yield put(setLoading("app_review", false));
  }
}

export function* fetchCCSJourneyDataSaga() {
  try {
    yield put(setLoading("ccs_journey_data", true));
    const response: any[] = yield retry(4, secondsDelay, getCCSJourneyData);

    if (!("error" in response)) {
      yield put(setCcsJourneyData(response));
    }
  } catch (err) {
    console.log("failed to get /ccs-journey/get-ccs-journey", err);
  } finally {
    yield put(setLoading("ccs_journey_data", false));
  }
}

export function* fetchCCSJourneyBannerDataSaga() {
  try {
    yield put(setLoading("ccs_journey_banner_data", true));
    const response: any[] = yield retry(4, secondsDelay, getCCSJourneyBannerData);
    if (response) {
      yield put(setCcsJourneyBannerData(response));
    } else {
      yield put(setCcsJourneyBannerData(null));
    }
  } catch (err) {
    console.log("failed to get /ccs-journey/banner", err);
  } finally {
    yield put(setLoading("ccs_journey_banner_data", false));
  }
}

export function* fetchSalesRevenueSource() {
  try {
    yield put(setLoading("sales_revenue_source", true));
    const response: any[] = yield retry(4, secondsDelay, getSalesRevenueSourceData);

    if (!("error" in response)) {
      yield put(setSalesRevenueSource(response));
    }
  } catch (err) {
    console.log("failed to get sales revenue sources data", err);
  } finally {
    yield put(setLoading("sales_revenue_source", false));
  }
}

export function* fetchCCSSalesRevenueData() {
  try {
    yield put(setLoading("sales_revenue_data", true));

    const response: CCSBusinessRevenueData = yield retry(4, secondsDelay, getSalesRevenueData);

    if (!("error" in response)) {
      yield put(setSalesRevenueData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("sales_revenue_data", false));
  }
}

export function* fetchCCSSalesRevenueChartData() {
  try {
    yield put(setLoading("sales_revenue_chart_data", true));

    const response: CCSBusinessRevenueChartData = yield retry(4, secondsDelay, getSalesRevenueData, true);

    if (!("error" in response)) {
      yield put(setSalesRevenueChartData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("sales_revenue_chart_data", false));
  }
}
