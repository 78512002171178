import React, { useEffect, useMemo, useState } from 'react'
import { isNull } from '../../../utils/utilityFunctions';
import NewRatings from '../../../components/Ratings/NewRatings';
import { Tooltip } from 'react-tippy';
import { ToggleSwitch } from '../../../components/Toogle';
import { DefaultTable } from '../../../components/Tables/subs';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getOrderValueByExtrasTable, getTotalOrderValueTable } from '../../../api/ecommerce';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LoadingEllipsis from '../../../components/Loading/LoadingEllipsis';
import { NoDataMsg } from '../../../components/AppMessages';

const TotalExtrasOrdersTable = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { market } = useSelector((state: RootStateOrAny) => state.parameters)

  const [toggleValue, setToggleValue] = useState<boolean>(true)

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["totalExtrasOrderTable", history.location.search, toggleValue],
    queryFn: () => getOrderValueByExtrasTable(toggleValue),
    enabled: false
  })

  useEffect(() => {
    refetch()
  }, [history.location.search, toggleValue])

  const columns = useMemo(
    () => [
      {
        id: "market",
        Header: market == "All" ? "Top Markets" : "Top Models",
        accessor: "market",
      },
      {
        id: "accessories",
        Header: "Accessories",
        accessor: "accessories",
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {!isNull(value) && <NewRatings value={value} />}
            {isNull(value) ? "n/a" : value?.toLocaleString()}
          </div>
        ),
      },
      {
        id: "extendedWarranty",
        Header: "Extended warranty",
        accessor: "extended_warranty",
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {!isNull(value) && <NewRatings value={value} />}
            {isNull(value) ? "n/a" : value?.toLocaleString()}
          </div>
        ),
      },
      {
        id: "pmp",
        Header: "PMP",
        accessor: "pmp",
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {!isNull(value) && <NewRatings value={value} />}
            {isNull(value) ? "n/a" : value?.toLocaleString()}
          </div>
        ),
      },
    ],
    [data, toggleValue, market]
  );
  return (
    <div className="ecommerce-tile">
      <div className="tile-header">
        <div className="header-title">
          <p>Total Extras Orders broken down by {market == "All" ? "Market" : "Model"}</p>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            title={
              "The total number of orders value completed via 'Reserve online'  (Considering both BTO, STO and CPO) by extras"
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <ToggleSwitch
          activeToggleLabel='Value'
          inactiveToggleLabel='Volume'
          active={toggleValue}
          handleToggleClick={() => setToggleValue(prevState => !prevState)}
        />
      </div>
      {data ?
        <DefaultTable
          columns={columns}
          data={data ?? []}
          id={"totalExtrasOrderTable"}
          isChildComponent={false}
          initialSortColId={{ id: "accessories", desc: true }}
          hiddenCols={[]}
          showPagination={true}
          pageSize={10}
        />
        :
        isFetching ?
          <LoadingEllipsis isLoading={isFetching} />
          :
          <NoDataMsg />
      }
    </div>
  )
})

export default TotalExtrasOrdersTable