import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { fetchAseanActivationFilters, fetchUaeActivationFilters, setSpecificParameter } from "../../actions";
import { ALL_OPTION_NO_SPACE, DEFAULT_EUROPE_ACTIVATION_PARAMETERS } from "../../constants";
import { FilterContext } from "../../context";
import { checkConsecutiveMonths } from "../../pages/CCS Vital Signs/subs/utils";
import { groupByKeys } from "../../utils/computeFunctions";
import { enumerateDaysBetweenDates, FY_TWENTY } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DefaultFilter, EuropeActivationMarketFilter, MultiSelectFilter } from "./common";
import { EuropeActivationDateFilter } from "./common/EuropeActivationDateFilter";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";

export const CcsAseanActivationFilters = withRouter((props: RouteComponentProps): JSX.Element => {
  const { history } = props;
  const dispatch = useDispatch();
  const {
    model,
    market,
    sales_channel: channels,
    date_range: availableDates,
  } = useSelector((state: RootStateOrAny) => state.filters.asean_activation_filters);
  //Filter options
  const [modelsList, setModelsList] = useState<string[]>([]);
  const [marketsList, setMarketsList] = useState<string[]>([]);
  const [marketGroupsList, setMarketGroupsList] = useState<Array<string>>([]);
  const [salesChannelList, setSalesChannelList] = useState<string[]>([]);
  const [dealersList, setDealersList] = useState<string[]>([]);
  const [topFiveDealers, setTopFiveDealers] = useState<string[]>([]);
  const [bottomFiveDealers, setBottomFiveDealers] = useState<string[]>([]);
  const [datesList, setDatesList] = useState<any>([]);
  const [dealersFilterOptions, setDealersFilterOptions] = useState<string[]>([]);
  const [allOptionList] = useState<Array<string>>(["All"]);

  //Parameter values
  const params = useSelector((state: RootStateOrAny) => state.parameters);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const marketParamValue = useSelector((state: RootStateOrAny) => state.parameters.market);
  const modelParamValue = useSelector((state: RootStateOrAny) => state.parameters.model);
  const salesChannelParamValue = useSelector((state: RootStateOrAny) => state.parameters.sales_channel);
  const activationFormatParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.activation_format);

  //Filter input values
  const [dateRangeFilterValue, setDateRangeFilterValue] = useState<string>("");
  const [modelFilterValue, setModelFilterValue] = useState<string>("");
  const [marketFilterValue, setMarketFilterValue] = useState<string>("");
  const [dealerFilterValue, setDealerFilterValue] = useState<string>("");
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  useEffect(() => {
    dispatch(fetchAseanActivationFilters());
    dispatch(setSpecificParameter("region", "ASEAN"));
  }, [dispatch]);

  useEffect(() => {
    if (availableDates) {
      const startDate = "2019-04-01";
      const { max_date } = availableDates[0];
      const formattedMaxDate = moment(max_date, "YYYY-MM-DD");
      const dateDifference = moment().diff(formattedMaxDate, "months");
      const endDate = dateDifference > 0 ? formattedMaxDate : moment().format("YYYY-MM-DD");
      const datesArr = enumerateDaysBetweenDates({
        startDate: startDate,
        endDate: endDate,
        dateFormat: "MMMM YYYY",
        valueToIncrement: "months",
      });
      setDatesList(datesArr);
    }
  }, [availableDates]);

  const groupedDateRange = useMemo(() => {
    if (datesList) {
      const nestedDates = datesList.map((dateValue: string) => {
        const monthValue = dateValue?.split(" ")[0];
        const yearValue = dateValue?.split(" ")[1];
        return { year: Number(yearValue), month: monthValue };
      });
      const groupedDates = groupByKeys(nestedDates, "year");
      return Object.keys(groupedDates).map((year) => ({ year, child: groupedDates[year] }));
    }
    return [];
  }, [datesList]);

  //Set date range filter value
  // Updates date range filter value
  useEffect(() => {
    if (availableDates) {
      // Todo: Updates date range values
      const selectedDateArray: Array<string> = dateRangeParamValue?.split(",");
      const availableDateOptions: Array<string> = availableDates?.map(
        (dates: { year: number; month: string }) => `${dates.month} ${dates.year}`
      );

      const isFY20 = selectedDateArray.length === FY_TWENTY.length && selectedDateArray.every((dateValue) => FY_TWENTY.includes(dateValue));

      const checkConsecutive = checkConsecutiveMonths(selectedDateArray, availableDateOptions);

      const dateRangeInputValue = isFY20
        ? "FY20"
        : selectedDateArray.length > 1
        ? checkConsecutive.isConsecutive
          ? checkConsecutive.consecutiveDateRange
          : `${selectedDateArray?.length} months selected`
        : dateRangeParamValue;

      const isValidCCSDate: boolean = selectedDateArray.reduce(
        (result: boolean, date) => result && (date == ALL_OPTION_NO_SPACE ? true : availableDateOptions.includes(date)),
        true
      );

      const availableCalendarYears = Array.from(new Set(availableDates.map((option: { month: string; year: number }) => option.year)));

      // Sets the default date selection to the latest available calendar year
      if (!isValidCCSDate) {
        const defaultSelection = availableDateOptions.filter((option) => {
          const calendarYearRe = new RegExp(`${availableCalendarYears[availableCalendarYears.length - 1]}`, "gi");

          return calendarYearRe.test(option);
        });

        dispatch(setSpecificParameter("date_range", defaultSelection.join(",")));
        dispatch(setSpecificParameter("dates", defaultSelection.join(",")));
      }
      setDateRangeFilterValue(dateRangeInputValue);
    }
  }, [dateRangeParamValue, availableDates]);

  //Set geo filter value
  useEffect(() => {
    if (market && marketParamValue) {
      const selectedMarketsArr = marketParamValue?.split(",");
      const marketValue =
        selectedMarketsArr?.length === market?.length
          ? "All"
          : selectedMarketsArr?.length > 1
          ? `${selectedMarketsArr?.length} markets selected`
          : marketParamValue;
      setMarketFilterValue(marketValue);
    } else {
      setMarketFilterValue(ALL_OPTION_NO_SPACE);
    }
  }, [marketParamValue, market]);

  //Reset the market filter param if the param value is not in the markets array
  useEffect(() => {
    if (market?.length > 0) {
      const marketParamValuesArr = marketParamValue?.split(",");
      const marketsAreValid = marketParamValuesArr?.every((market: string) => market?.includes(market));
      if (!marketsAreValid || marketParamValue?.includes(ALL_OPTION_NO_SPACE)) {
        dispatch(setSpecificParameter("market", market?.join(",")));
      }
    }
  }, [market, marketParamValue]);

  //Reset the model parameter value if the value is invalid
  useEffect(() => {
    if (model?.length > 0) {
      const modelParamValuesArr = modelParamValue?.split(",");
      const modelsAreValid = modelParamValuesArr?.every((market: string) => model?.includes(market));
      if ((model?.length > 1 && !modelsAreValid) || modelParamValue?.includes(ALL_OPTION_NO_SPACE)) {
        dispatch(setSpecificParameter("model", model?.join(",")));
      }
    }
  }, [model, modelParamValue]);

  //Reset the sales_channel parameter value if the value is invalid
  useEffect(() => {
    if (channels?.length > 0) {
      const salesChannelParamValuesArr = salesChannelParamValue?.split(",");
      const salesChannelsAreValid =
        salesChannelParamValuesArr?.every((market: string) => channels?.includes(market)) || salesChannelParamValue == "All";
      if ((channels?.length > 0 && !salesChannelsAreValid) || salesChannelParamValue?.includes(ALL_OPTION_NO_SPACE)) {
        dispatch(setSpecificParameter("sales_channel", "All"));
      }
    }
  }, [channels, salesChannelParamValue]);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const handleModelFilterSubmission = useCallback(() => {
    const parameterName = "model";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = modelsList.length === selectedValues.length ? "All" : selectedValues.join(",");
    dispatch(setSpecificParameter("all_models_selected", filterValue.includes("All") ? true : false));

    closeFilterOptions();
    dispatch(setSpecificParameter("model", filterValue));
    eventTracking(MixpanelEvents.filter_change, { filter: "model", value: filterValue, dashboard: "CCS", page: "Europe activation" });
  }, []);

  const handleDefaultFilterSubmission = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;
    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
      eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "CCS", page: "Europe activation" });
    }

    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  }, []);

  const resetFilters = useCallback(() => {
    eventTracking(MixpanelEvents.filter_reset, { dashboard: "CCS", page: location.pathname });

    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(DEFAULT_EUROPE_ACTIVATION_PARAMETERS)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });

    window.location.reload();
  }, []);

  // Set params
  useEffect(() => {
    const queryParams = new URLSearchParams();

    queryParams.set("date_range", params.date_range);
    queryParams.set("region", params.region);
    queryParams.set("market", params.market);
    queryParams.set("model", params.model);
    queryParams.set("sales_channel", params.sales_channel);
    queryParams.set("activation_format", activationFormatParamValue);
    history.push({ search: `?${queryParams.toString()}` });
  }, [params, activationFormatParamValue]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <FiltersLayout resetFilters={resetFilters}>
        <>
          {/**DATE FILTER */}
          <EuropeActivationDateFilter
            filterValue={dateRangeFilterValue}
            groupedDatesList={groupedDateRange}
            dateRangeParamValue={dateRangeParamValue}
            showFilterOptions={showFilterOptionsFn}
          />

          {/* GEOGRAPHY FILTER */}
          {market?.length > 0 ? (
            <EuropeActivationMarketFilter
              filterValue={marketFilterValue}
              marketsList={market}
              marketGroupsList={[]}
              showFilterOptions={showFilterOptionsFn}
              marketParamValue={marketParamValue}
              marketGroupParamValue={""}
            />
          ) : (
            <DefaultFilter
              filterName={"market"}
              list={allOptionList}
              filterValue="All"
              filterLabel={"GEOGRAPHY"}
              handleFilterOptionClick={handleDefaultFilterSubmission}
            />
          )}

          {/* MODEL FILTER */}
          {model?.length > 1 ? (
            <MultiSelectFilter
              parentKey="model"
              // @ts-ignore
              filterList={model}
              filterName="MODEL"
              value={modelFilterValue}
              parameterName={"model"}
              parameterValue={modelParamValue}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleModelFilterSubmission}
            />
          ) : (
            <DefaultFilter
              filterName={"model"}
              list={model}
              filterValue={modelParamValue}
              filterLabel={"MODEL"}
              handleFilterOptionClick={handleDefaultFilterSubmission}
            />
          )}
          {/* SALES CHANNEL FILTER */}
          <DefaultFilter
            filterName={"sales_channel"}
            list={channels ? ["All", ...channels] : ["All"]}
            filterValue={salesChannelParamValue}
            filterLabel={"SALES CHANNEL"}
            handleFilterOptionClick={handleDefaultFilterSubmission}
          />
        </>
      </FiltersLayout>
    </ErrorBoundary>
  );
});
