import React, { useContext, useEffect } from 'react'
import MonetizationPieChart from '../../../../components/Charts/CCSMonetizaation/PieChart'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCustomerServiceTakeRatePieChartData } from '../../../../api/ccsMonetizationApi';
import { ThemeContext } from '../../../../context';
import { NoDataMsg } from '../../../../components/AppMessages';

const CustomerServiceTakeRatePieChart = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { theme } = useContext(ThemeContext)

  const { data, isFetching, refetch: refetch } = useQuery({
    queryKey: ["customerServiceTakeRatePieChartData", history.location.search],
    queryFn: getCustomerServiceTakeRatePieChartData,
    enabled: false,
  });

  useEffect(() => {
    refetch()
  }, [history.location.search])

  return (
    <div className='tile'>
      <div className="tile-header">
        Service take rate per CCS services
      </div>
      <div className="tile-content">
        {data && data?.length ?
          <MonetizationPieChart
            chartId='customerServiceTakeRatePieChart'
            data={data}
            valueField='service_take_rate'
            categoryField='value'
            theme={theme}
            legendPosition='bottom'
            isLoading={isFetching}
          />
          : <NoDataMsg />
        }
      </div>
    </div>
  )
})

export default CustomerServiceTakeRatePieChart