import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react'
import { getVisitToLeadToolToken } from '../../api/visitToLeadTool';
import { IframeLayout } from '../../components/Layouts';
import { ErrorBoundary } from '@sentry/react';
import { ErrorMsg } from '../../components/AppMessages';
import LoadingEllipsis from '../../components/Loading/LoadingEllipsis';
import { IframePageContent } from '../../components/PageContent';
import { Buffer } from 'buffer';

const Clv = () => {
  const baseUrl = "https://clv-dashboard.streamlit.nissan-cedar.com/?embed=true";

  const {
    data: tokenData,
    isFetching: isFetchingTokenData,
    refetch: refetchTokenData,
  } = useQuery({
    queryKey: ["clvToken"],
    queryFn: getVisitToLeadToolToken,
    enabled: false,
  });

  const encodedToken = tokenData?.token ? Buffer.from(tokenData.token).toString('base64') : "";
  const iframeUrl = encodedToken ? `${baseUrl}&id=${encodedToken}` : baseUrl;

  useEffect(() => {
    refetchTokenData();
    const interval = setInterval(() => {
      refetchTokenData();
    }, 60 * 60 * 1000); // 66 minutes in milliseconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [refetchTokenData]);

  return (
    <IframeLayout hasFilter={false}>
      <ErrorBoundary fallback={<ErrorMsg />}>
        {isFetchingTokenData ?
          <LoadingEllipsis isLoading={isFetchingTokenData} />
          :
          <IframePageContent
            frameId="clvDashboard"
            iframeUrl={iframeUrl} // Use the iframe URL with encoded token as query parameter
          />
        }

      </ErrorBoundary>
    </IframeLayout>
  );
}

export default Clv