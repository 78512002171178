import { CategoryAxis, ColumnSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, Tooltip } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";
import { LIME_GREEN, ORANGE, RED } from "../../../constants";

interface Props {
  data: Array<Record<string, number | any>>;
  theme: ThemeInt;
  chartId: string;
  colorMapping: Record<string, string>;
  isLoading: boolean;
  xAxisFontSize?: number;
  categoryField?: string;
  valueField?: string;
}

class UseOwnershipTopicChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme, colorMapping, categoryField, valueField, xAxisFontSize } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#a";

    // Create Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    categoryAxis.dataFields.category = categoryField ?? "topic";
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.fontSize = 20;
    if (xAxisFontSize) {
      categoryAxis.renderer.labels.template.fontSize = xAxisFontSize;
    }
    categoryAxis.events.on("sizechanged", function (ev) {
      const axis = ev.target;
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      axis.renderer.labels.template.maxWidth = cellWidth + 10;
    });


    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return b.topic_score - a.topic_score;
    });

    const series = this.chart.series.push(new ColumnSeries());
    series.columns.template.adapter.add("fill", (fill: any, target: any) => {
      if (target.dataItem.dataContext?.topic_category) {
        const category = target.dataItem.dataContext.topic_category;
        return color(colorMapping[category]);
      } else {
        const experienceRating = target.dataItem.dataContext?.experience_rating
        if (experienceRating && experienceRating <= 3.4) {
          fill = color(RED);
        } else if (experienceRating && experienceRating >= 3.5 && experienceRating <= 3.9) {
          fill = color(ORANGE);
        } else {
          fill = color(LIME_GREEN);
        }
        return fill;
      }
    });
    series.columns.template.adapter.add("stroke", (fill: any, target: any) => {
      if (target.dataItem.dataContext?.topic_category) {
        const category = target.dataItem.dataContext.topic_category;
        return color(colorMapping[category]);
      } else {
        const experienceRating = target.dataItem.dataContext?.experience_rating
        if (experienceRating && experienceRating <= 3.4) {
          fill = color(RED);
        } else if (experienceRating && experienceRating >= 3.5 && experienceRating <= 3.9) {
          fill = color(ORANGE);
        } else {
          fill = color(LIME_GREEN);
        }
        return fill;
      }
    });
    series.dataFields.categoryX = categoryField ?? "topic";
    series.dataFields.valueY = valueField ?? "topic_score";
    series.columns.template.tooltip = new Tooltip();
    series.columns.template.tooltipText = `[bold font-size: var(--regular_font_size)]{categoryX}: {valueY.formatNumber('###,###')}`;
    series.columns.template.adapter.add("tooltipHTML", (value: any, target: any) => {
      const comments = target?.tooltipDataItem?.dataContext?.comments;

      if (comments) {
        let commentsHTML = ``;
        comments.forEach((comment: any) => {
          commentsHTML += `<li key=${comment}>● ${comment}</li>`;
        });
        return `
          <ol style="font-size: var(--regular_font_size);">
          ${commentsHTML}            
          </ol>
        `;
      } else {
        return value
      }
    });
    if (series.columns.template.tooltip) {
      series.columns.template.tooltip.label.maxWidth = 350;
      series.columns.template.tooltip.label.wrap = true;
    }
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default UseOwnershipTopicChart;
