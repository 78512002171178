import React, { useContext } from "react";
import { isNull } from "../../../../utils/utilityFunctions";
import Ratings from "../../../../components/Ratings/Ratings";
import SummaryBarChart from "../../../../components/Charts/QuickVocCec/SummaryBarChart";
import { ThemeContext } from "../../../../context";

interface Props {
  title: string;
  chartId: string;
  summaryValue: number;
  isRating: boolean;
  previousPeriodValue: number;
  chartData: { data: Array<Record<string, any>>; series: Array<{ name: string; field: string }> };
  isLoading: boolean;
}

const SummaryTile = (props: Props) => {
  const { title, summaryValue, isRating, previousPeriodValue, chartId, chartData, isLoading } = props;

  const { theme } = useContext(ThemeContext);

  return (
    <div className="summary-tile">
      <div className="title">{title}</div>
      <div className="value-container">
        <div className={`value ${isRating && summaryValue >= 4 ? "text-green" : isRating && summaryValue >= 3.9 ? "text-amber" : isRating && summaryValue > 0 ? "text-red" : ""}`}>
          {isRating ? summaryValue?.toFixed(2) : summaryValue?.toLocaleString()}
        </div>
        <Ratings value={!isNull(previousPeriodValue) ? previousPeriodValue : "n/a"} isPercentageValue={false} />
      </div>
      <SummaryBarChart
        isRating={isRating}
        chartId={chartId}
        data={chartData.data}
        seriesList={chartData.series}
        isLoading={isLoading}
        theme={theme}
      />
    </div>
  );
};

export default SummaryTile;
