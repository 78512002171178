import { format, lastDayOfMonth, parse } from "date-fns";
import { VOC_DATE_FILTER_OPTIONS } from "../constants";
import { fetchDataV2, newApiFetchV2 } from "./utils";

export const getParams = () => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);

  const dateRangeParam = parameters.get("date_range");
  const brand = parameters.get("brand") == "All" ? [] : parameters.get("brand")?.split(",") ?? [];
  const market = parameters.get("market") == "All" ? [] : parameters.get("market")?.split(",") ?? [];

  const date = dateRangeParam
    ? VOC_DATE_FILTER_OPTIONS.includes(dateRangeParam)
      ? dateRangeParam.split(" ").join("_").toLocaleLowerCase()
      : "custom"
    : "";

  const parameterEntries: Record<string, string | null | Array<string> | undefined> = {
    date_range: date,
    brand: brand,
    market: market,
  };

  if (date == "custom") {
    parameterEntries["start_date"] = dateRangeParam
      ? format(parse(dateRangeParam.split("-")[0].trim(), "MMMM yyyy", new Date()), "y-MM-dd")
      : "";
    parameterEntries["end_date"] = dateRangeParam ? format(lastDayOfMonth(new Date(dateRangeParam.split("-")[1].trim())), "y-MM-dd") : "";
  }

  return parameterEntries;
};

export const getQuickVocCecFilters = () => newApiFetchV2("/quick-voc-cec/filters", getParams());

export const getQuickVocCecLastRefreshDate = () => newApiFetchV2("/quick-voc-cec/last-refresh-date", getParams());

export const getQuickVocCecComplaint = (isRating: boolean) => newApiFetchV2(`/quick-voc-cec/complaint?is_rating=${isRating}`, getParams());

export const getQuickVocCecInformationRequest = (isRating: boolean) =>
  newApiFetchV2(`/quick-voc-cec/information-request?is_rating=${isRating}`, getParams());

export const getQuickVocCecCcs = (isRating: boolean) => newApiFetchV2(`/quick-voc-cec/ccs?is_rating=${isRating}`, getParams());

export const getQuickVocCecPerformanceByMarket = (metric: string | undefined) =>
  newApiFetchV2(`/quick-voc-cec/performance-by-market?metric=${metric}`, getParams());

export const getQuickVocCecMarketDeepDiveTable = () => newApiFetchV2(`/quick-voc-cec/market-deep-dive-table`, getParams());

export const getQuickVocCecMarketDeepDiveTrendline = () => newApiFetchV2(`/quick-voc-cec/satisfaction-score-by-market`, getParams());
