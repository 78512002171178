import React, { useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ThemeContext } from "../../../context";
import EcommerceLineChart from "../../../components/Charts/Ecommerce/EcommerceLineChart";

const EcommerceOrders = () => {
  const themeContext = useContext(ThemeContext);

  const { data, series } = useSelector((state: RootStateOrAny) => state.ecommerce.orders);
  const { market } = useSelector((state: RootStateOrAny) => state.parameters);
  const loading = useSelector((state: RootStateOrAny) => state.loading.orders);

  return (
    <div className="ecommerce-tile">
      <div className="tile-header">
        <div className="header-title">
          <p>{market == "All" ? "Ecommerce Orders by Region" : "Ecommerce Orders by Nameplate"}</p>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            title={
              market == "All" ? "Number of ecommerce orders broken down per region" : "Number of ecommerce orders broken down per nameplate (top 5 models)."
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <EcommerceLineChart data={data} seriesList={series} chartId="EcommerceOrdersChart" theme={themeContext.theme} isLoading={loading} />
    </div>
  );
};

export default EcommerceOrders;
