import React, { useCallback, useContext, useEffect, useState } from 'react'
import InAppSingleSelectNestedFilter from '../../../../components/Filters/common/InAppSingleSelectNestedFilter'
import { Tooltip } from 'react-tippy'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { DefaultFilter } from '../../../../components/Filters/common'
import { closeFilterOptions } from '../../../../components/Filters/subs/helpers'
import ExecutiveSummaryStackedBarChart from '../../../../components/Charts/ExecutiveSummary/ExecutiveSummaryStackedBarChart'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ThemeContext } from '../../../../context'
import { getPerformanceByProductCategoryByMarket } from '../../../../api/executiveSummaryProductPerformanceApi'

const ProductPerformanceByMarket = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const [filterDataBy, setFilteDataBy] = useState("Global");
  const [metricFilterBy, setMetricFilterBy] = useState("Visits");

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["productsActivatedByMarketData", history.location.search, metricFilterBy, filterDataBy],
    queryFn: () => getPerformanceByProductCategoryByMarket(metricFilterBy, filterDataBy),
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search, metricFilterBy, filterDataBy]);

  const queryCache = useQueryClient()
  const filtersData: any = queryCache.getQueriesData(["executiveSummaryProductPerformanceFiltersData"])?.at(-1)
  const regions = filtersData ? filtersData[1]?.geographies?.map((geo: any) => ({ value: geo.region })) : []

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      filterName == "performance_by_market_filter_data_by" ? setFilteDataBy(optionValue) : setMetricFilterBy(optionValue);
    }
    closeFilterOptions();
  }, []);

  return (
    <div className="tile products-activated-market">
      <div className="tile-header">
        <div className="title-container">
          <div className="title">Market Performance by Product Category</div>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={
              " Shows the total number of products activated globally, regionally and in each market and a Conversion Rate comparison. Please note that the numbers are affected by the filters selection"
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <div className="filters-container">
          <InAppSingleSelectNestedFilter
            options={[
              { value: "Global" },
              {
                value: "Regions",
                children: regions,
              },
              { value: "Market" },
            ]}
            filterValue={filterDataBy}
            filterName={"performance_by_market_filter_data_by"}
            filterLabel={"FILTER DATA BY"}
            setValue={setFilteDataBy}
          />
          <DefaultFilter
            list={["Visits", "KBAs", "Leads"]}
            filterName={"performance_by_market_metric_filter"}
            filterLabel={"METRIC FILTER BY"}
            filterValue={metricFilterBy}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </div>
      </div>
      <ExecutiveSummaryStackedBarChart
        chartName="performanceByMarketStackedChart"
        data={data?.data ?? []}
        seriesList={data?.series ?? []}
        isLoading={isLoading}
        theme={theme}
        categoryField="product_category"
      />
    </div>
  )
});

export default ProductPerformanceByMarket