import React, { useContext, useEffect } from 'react'
import MultipleLineChart from '../../../../components/Charts/CCSMonetizaation/MiltipleLineChart'
import { ThemeContext } from '../../../../context'
import { MARKETING_ACTIVITY_COLORS } from '../../../../constants'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getTotalRevenueByMarketTrend } from '../../../../api/ccsMonetizationApi'
import { NoDataMsg } from '../../../../components/AppMessages'

const TotalRevenuePerMarketTrendline = withRouter((props: RouteComponentProps) => {
  const { history } = props
  const { theme } = useContext(ThemeContext);

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["totalRevenuePerMarketTrendlineData", history.location.search],
    queryFn: getTotalRevenueByMarketTrend,
    enabled: false,
  });

  useEffect(() => {
    refetch()
  }, [history.location.search])

  return (
    <div className='tile'>
      <div className='tile-header'>
        Total Revenue Trendline per Market
      </div>
      <div className='tile-content'>
        {data?.data && data?.data?.length ?
          <MultipleLineChart
            chartId='totalRevenuePerMarketTrendline'
            data={data.data}
            seriesList={data.series}
            theme={theme}
            isLoading={isFetching}
            seriesColors={MARKETING_ACTIVITY_COLORS}
            currency='€'
          /> : <NoDataMsg />}
      </div>
    </div>
  )
})

export default TotalRevenuePerMarketTrendline