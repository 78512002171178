import React, { useContext, useEffect, useMemo } from 'react'
import MultipleLineChart from '../../../../components/Charts/CCSMonetizaation/MiltipleLineChart'
import { ThemeContext } from '../../../../context'
import { ALL_OPTION_NO_SPACE, MARKETING_ACTIVITY_COLORS } from '../../../../constants'
import { DefaultTable } from '../../../../components/Tables/subs'
import Ratings from '../../../../components/Ratings/Ratings'
import { RootStateOrAny, useSelector } from 'react-redux'
import MonetizationPieChart from '../../../../components/Charts/CCSMonetizaation/PieChart'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getG5TakeRateBreakdownTrend, getG5TakeRateBreakdownTable } from '../../../../api/ccsMonetizationApi'
import { NoDataMsg } from '../../../../components/AppMessages'

const CustomerTakeRate = withRouter((props: RouteComponentProps) => {
  const { history } = props
  const { theme } = useContext(ThemeContext)

  const { market: marketParamValue } = useSelector((state: RootStateOrAny) => state.parameters)

  const { data: trendData, isFetching: trendIsFetching, refetch: trendDataRefetch } = useQuery({
    queryKey: ["g5TakeRateBreakedownTrendData", history.location.search],
    queryFn: getG5TakeRateBreakdownTrend,
    enabled: false,
  });

  const { data: tableData, isFetching: tableIsFetching, refetch: tableDataRefetch } = useQuery({
    queryKey: ["g5TakeRateBreakdownTableData", history.location.search],
    queryFn: getG5TakeRateBreakdownTable,
    enabled: false,
  });

  useEffect(() => {
    trendDataRefetch()
    tableDataRefetch()
  }, [history.location.search])

  const isMarkets = useMemo(() => {
    return marketParamValue == ALL_OPTION_NO_SPACE || marketParamValue.split(",")?.length > 1
  }, [marketParamValue])

  const columns = useMemo(
    () => [
      {
        id: isMarkets ? "markets" : "services",
        Header: isMarkets ? "G5 markets" : "CCS services",
        accessor: isMarkets ? `market` : `service`,
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
      },
      {
        Header: "Customer take rate",
        accessor: `customer_take_rate`,
        id: "customerTakeRate",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: ({ value }: { value: number }) => {
          return (
            <div className="table-cell">
              <Ratings value={value} isPercentageValue={false} testValue={false} /> {value ? `${Math.abs(value)}%` : "n/a"}
            </div>
          );
        },
      },
      {
        Header: "Service take rate",
        accessor: `service_take_rate`,
        id: "serviceTakeRate",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: ({ value }: { value: number }) => {
          return (
            <div className="table-cell">
              <Ratings value={value} isPercentageValue={false} testValue={false} /> {value ? `${Math.abs(value)}%` : "n/a"}
            </div>
          );
        },
      },
    ],
    [marketParamValue, isMarkets]
  );

  return (
    <div className='column-2'>
      <div className='tile'>
        <div className='tile-header'>
          Customer take rate proportions
        </div>
        <div className='tile-content'>
          {trendData && trendData?.length ?
            <MonetizationPieChart
              chartId='g5TakeRatePieChart'
              data={trendData}
              valueField='value'
              categoryField='category'
              theme={theme}
              legendPosition='bottom'
              isLoading={trendIsFetching}
            /> : <NoDataMsg />
          }
        </div>
      </div>
      <div className='tile'>
        <div className='tile-header'>
          G5 take rates per {isMarkets ? "Market" : "CCS service"} proportions
        </div>
        <div className='tile-content'>
          {tableData && tableData.length > 0 ? (
            <DefaultTable
              columns={columns}
              data={tableData ?? []}
              id={"visits_trend_breakdown"}
              data-test-id={"visits_trend_breakdown"}
              hiddenCols={[]}
              initialSortColId={{ id: "reached_volumes", desc: true }}
              isChildComponent={false}
            />
          ) : (
            <NoDataMsg />
          )}
        </div>
      </div>
    </div>

  )
})

export default CustomerTakeRate