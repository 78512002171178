import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DefaultTable } from '../../../components/Tables/subs';
import LoadingEllipsis from '../../../components/Loading/LoadingEllipsis';
import { ErrorBoundary } from '@sentry/react';
import { ErrorMsg, NoDataMsg } from '../../../components/AppMessages';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { InAppMultiSelectFilter } from '../../../components/Filters/common/InAppMultiselectFilter';
import { ToggleSwitch } from '../../../components/Toogle';
import { closeFilterOptions } from '../../../components/Filters/subs/helpers';
import { setSpecificParameter } from '../../../actions';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getEcommerceFeedbackComments, getEcommerceFeedbackCommentsFilters } from '../../../api/ecommerce';
import { getFeedbackCommentsFilters } from '../../../api/vocApi';
import { Tooltip } from 'react-tippy';

const VerbatimTable = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const dispatch = useDispatch()

  const [touchpointFilterValue, setTouchpointFilterValue] = useState("All");
  const [topicFilterValue, setTopicFilterValue] = useState("All");
  const [experienceRatingFilterValue, setExperienceRatingFilterValue] = useState("All");
  const [toggleValue, setToggleValue] = useState(true);
  const [experienceRatingApiValue, setExperienceRatingApiValue] = useState<Array<string>>([])
  const [topicsApiValue, setTopicsApiValue] = useState<Array<string>>([])
  const [touchpointsApiValue, setTouchpointsApiValue] = useState<Array<string>>([])

  const touchpointParam = "touchpoint";
  const topicParam = "topic";
  const experienceRatingParam = "experience_rating";

  const {
    data,
    refetch,
    isFetching: isLoading,
  } = useQuery({
    queryKey: ["ecommerceVerbatimData", experienceRatingApiValue, topicsApiValue, touchpointsApiValue, history.location.search],
    queryFn: () => getEcommerceFeedbackComments(experienceRatingApiValue, topicsApiValue, touchpointsApiValue),
    enabled: false,
  });

  const {
    data: filtersData,
    refetch: refetchFilters,
  } = useQuery({
    queryKey: ["ecommerceVerbatimFiltersData", history.location.search],
    queryFn: getEcommerceFeedbackCommentsFilters,
    enabled: false,
  });

  useEffect(() => {
    experienceRatingFilterValue == "All" ? setExperienceRatingApiValue([]) : setExperienceRatingApiValue(experienceRatingFilterValue.split(","));
    topicFilterValue == "All" ? setTopicsApiValue([]) : setTopicsApiValue(topicFilterValue.split(","));
    touchpointFilterValue == "All" ? setTouchpointsApiValue([]) : setTouchpointsApiValue(touchpointFilterValue.split(","));
  }, [touchpointFilterValue, topicFilterValue, experienceRatingFilterValue]);

  useEffect(() => {
    refetch()
  }, [experienceRatingApiValue, topicsApiValue, touchpointsApiValue, history.location.search])

  useEffect(() => {
    refetchFilters()
  }, [history.location.search])

  const columns = useMemo(
    () => [
      {
        id: "market",
        Header: "Market",
        accessor: "market",
      },
      {
        id: "date",
        Header: "Date",
        accessor: "date",
      },
      {
        id: "experienceRating",
        Header: "Experience rating",
        accessor: "experience_rating",
      },
      {
        id: "topic",
        Header: "Topic",
        accessor: "topic",
      },
      {
        id: "touchpoint",
        Header: "Touchpoints",
        accessor: "touchpoint",
      },
      {
        id: "subcategory",
        Header: "Subcategory",
        accessor: "sub_category",
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return value ? value : "n/a";
        },
      },
      {
        id: "url",
        Header: "URL",
        accessor: "url",
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return (
            <a href={value} target="_blank" rel="noreferrer" className="link">
              {value}
            </a>
          );
        },
      },
      {
        id: "devicetype",
        Header: "Device type",
        accessor: "device_type",
      },
      {
        id: "comment",
        Header: "Comment",
        accessor: `${toggleValue ? "verbatim_concat_en" : "verbatim_concat"}`,
      },
    ],
    [toggleValue]
  );

  const handleTouchpointMultiselectSubmit = useCallback(() => {
    const parameterName = touchpointParam;
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = filtersData?.touchpoints?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    setTouchpointFilterValue(filterValue);
    dispatch(setSpecificParameter(parameterName, filterValue));
  }, [filtersData?.touchpoints]);

  const handleTopicMultiselectSubmit = useCallback(() => {
    const parameterName = topicParam;
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = filtersData?.topics?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    setTopicFilterValue(filterValue);
    dispatch(setSpecificParameter(parameterName, filterValue));
  }, [filtersData?.topics]);

  const handleExperienceRatingMultiselectSubmit = useCallback(() => {
    const parameterName = experienceRatingParam;
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = filtersData?.experience_ratings?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    setExperienceRatingFilterValue(filterValue);
    dispatch(setSpecificParameter(parameterName, filterValue));
  }, [filtersData?.experience_ratings]);

  const totalVerbatimCount = useMemo(() => {
    if (data && data.length > 0) {
      return data[0].verbatim_concat_count;
    }
    return null;
  }, [data]);

  return (
    <div>
      <div>
        <div className="tile h-30 grid-col-span-6 verbatimTile">
          <div className="space-between verbatim-tile-header">
            <div className="tileHeader">
              <p>Ecommerce verbatims</p>
              <Tooltip
                position="top-end"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                distance={50}
                delay={1}
                hideDelay={1}
                duration={1}
                title={
                  "e-commerce checkout” to “Ecommerce Checkout”, “Financial service” to “Financing”, “STO / inventory” to PLP / STO”"
                }
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>
            <div className="flex-row">
              <div className="verbatim-count">Total number of verbatims: {totalVerbatimCount ? totalVerbatimCount : "n/a"}</div>
              <ErrorBoundary fallback={<ErrorMsg />}>
                <InAppMultiSelectFilter
                  filterLabel="Select Experience Rating"
                  optionsList={filtersData?.experience_ratings?.map((rating: number) => String(rating)) ?? []}
                  filterValue={experienceRatingFilterValue}
                  parameterName={experienceRatingParam}
                  parameterValue={experienceRatingFilterValue}
                  handleFilterSubmission={handleExperienceRatingMultiselectSubmit}
                />
              </ErrorBoundary>
              <ErrorBoundary fallback={<ErrorMsg />}>
                <InAppMultiSelectFilter
                  filterLabel="Select Topics"
                  optionsList={filtersData?.topics ?? []}
                  filterValue={topicFilterValue}
                  parameterName={topicParam}
                  parameterValue={topicFilterValue}
                  handleFilterSubmission={handleTopicMultiselectSubmit}
                />
              </ErrorBoundary>
              <ErrorBoundary fallback={<ErrorMsg />}>
                <InAppMultiSelectFilter
                  filterLabel="Select Touchpoints"
                  optionsList={filtersData?.touchpoints ?? []}
                  filterValue={touchpointFilterValue}
                  parameterName={touchpointParam}
                  parameterValue={touchpointFilterValue}
                  handleFilterSubmission={handleTouchpointMultiselectSubmit}
                />
              </ErrorBoundary>
              <ToggleSwitch
                activeToggleLabel={"English"}
                inactiveToggleLabel={"Native"}
                active={toggleValue}
                handleToggleClick={() => setToggleValue((prevState) => !prevState)}
              />
            </div>
          </div>
          {isLoading ? (
            <LoadingEllipsis isLoading={isLoading} />
          ) : (
            data?.length > 0 ? (
              <DefaultTable
                columns={columns}
                data={data}
                id={"topicVerbatimTable"}
                isChildComponent={false}
                initialSortColId={{ id: "market", desc: false }}
                hiddenCols={[]}
                extraClass="verbatimTable"
                showPagination={true}
                pageSize={50}
              />
            ) : (
              <NoDataMsg />
            )

          )}
        </div>
      </div>
    </div>
  );
})

export default VerbatimTable
