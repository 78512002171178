import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fetchGradeMixData,
  fetchLastDataRefresh,
  fetchLeadsFunnelData,
  fetchLeadToTestDriveRequestsData,
  fetchMediaSpendData,
  fetchMediaSpendTotalsData,
  fetchPriceVolumeSalesData,
  fetchProductMarketingOptimizationFilters,
  fetchProductMarketingOptimizationLastDataRefresh,
  fetchRetailSalesData,
  fetchTestDriveRequestsData,
  fetchTotalLeadsData,
  fetchTotalsVisitsData,
  fetchWebVisitsByChannelData,
  setPageName,
  setSpecificParameter,
} from "../../actions";
import "../../assets/styles/pages/dfpDashboard.scss";
import "../../assets/styles/pages/midasUnderConstruction.scss";
import { ErrorMsg, NoDataMsg } from "../../components/AppMessages";
import { GranularityOptions } from "../../components/Charts/components";
import ProductMarketingOptimizationFilters from "../../components/Filters/ProductMarketingOptimizationFilters";
import { DashboardLayout } from "../../components/Layouts";
import { DefaultPageContent } from "../../components/PageContent";
import { PageTitle } from "../../components/PageContent/subs";
import { currencies } from "../../constants/currenctConstants";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import GradeMixGraph from "./components/GradeMixGraph";
import LeadsFunnelGraph from "./components/LeadsFunnelGraph";
import MediaSpendGraph from "./components/MediaSpendGraph";
import MetricComparisons from "./components/MetricComparisons";
import ShowRoomGraph from "./components/ShowRoomGraph";
import { displayWeeklyOption } from "./helpers/helpers";
import { useQuery } from "@tanstack/react-query";
import { getDfpLastRefreshDate } from "../../api";

const DfpDashboard = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { history } = props;

  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const { date_range: dateRangeParam } = useSelector((state: RootStateOrAny) => state.parameters);
  const responseError = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.response_error);

  const [showWeeklyOption, setShowWeeklyOption] = useState<boolean>(true);
  const [chartGranularity, setChartGranularity] = useState("monthly");

  const [inAccessibleDashboard, setInAccessibleDashboard] = useState<boolean>(false);

  const { data: lastDataRefreshDate, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["dfpLastDataRefresh"],
    queryFn: getDfpLastRefreshDate,
    enabled: false,
  });

  useEffect(() => {
    setInAccessibleDashboard(responseError?.error?.includes("invalid_scope"));
  }, [responseError]);

  useEffect(() => {
    setShowWeeklyOption(displayWeeklyOption(dateRangeParam, setChartGranularity));
  }, [dateRangeParam]);

  const currency = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.price_volume_sales?.currency ?? "");

  const market = useSelector((state: RootStateOrAny) => state.parameters.market);

  const currencySymbol = useMemo(() => {
    //@ts-ignore
    if (currencies[currency] != undefined) {
      //@ts-ignore
      return currencies[currency]["symbol"];
    }
  }, [currency]);

  const handleGranularityRadioChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = evt;

      setChartGranularity(value);
    },
    [setChartGranularity]
  );

  useEffect(() => {
    dispatch(setSpecificParameter("granularity", chartGranularity));
  }, [chartGranularity, dispatch]);

  // Sets page name
  useEffect(() => {
    Promise.all([
      dispatch(setPageName("Drivers for flash performance")),
      dispatch(fetchProductMarketingOptimizationFilters("Nissan")),
      dispatch(fetchLastDataRefresh()),
      dispatch(fetchProductMarketingOptimizationLastDataRefresh()),
      refetchLastDataRefresh()
    ]);
  }, [dispatch]);

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Product Marketing Optimization", page: "Drivers for flash performance" });
  }, [location.pathname]);

  useEffect(() => {
    if (parameters.market != "All countries" && parameters.model != "All") {
      setTimeout(
        () =>
          Promise.all([
            dispatch(fetchMediaSpendData()),
            dispatch(fetchMediaSpendTotalsData()),
            dispatch(fetchTotalsVisitsData()),
            dispatch(fetchTotalLeadsData()),
            dispatch(fetchLeadsFunnelData()),
            dispatch(fetchRetailSalesData()),
            dispatch(fetchLeadToTestDriveRequestsData()),
            dispatch(fetchWebVisitsByChannelData()),
            dispatch(fetchTestDriveRequestsData()),
            dispatch(fetchGradeMixData()),
            dispatch(fetchPriceVolumeSalesData("exact")),
          ]),
        50
      );
    }
  }, [parameters]);

  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", parameters.date_range);
    params.set("brand", parameters.brand);
    params.set("market", parameters.market);
    params.set("model", parameters.model);
    params.set("granularity", parameters.granularity);
    history.push({ search: `?${params.toString()}` });
  }, [parameters, history]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={
          <ProductMarketingOptimizationFilters
            granularityOptions={
              <GranularityOptions
                onHandleRadioClick={handleGranularityRadioChange}
                granularity={chartGranularity}
                name={"media_spend"}
                dateValue={parameters.date_range}
                isSearchInterest={false}
                granularitiesList={showWeeklyOption ? ["weekly", "monthly", "quarterly"] : ["monthly", "quarterly"]}
              />
            }
            inAccessible={inAccessibleDashboard}
            filterBtns={[{ id: "pmo_btn", name: "PMO", navigateTo: "/product_marketing_optimization" }]}
          />
        }
        dataDocumentation="product_marketing_optimization"
        lastDataRefresh={lastDataRefreshDate?.date ?? ""}
        pageTitle={<PageTitle dataDocumentation="product_marketing_optimization" />}
      >
        <ErrorBoundary fallback={<ErrorMsg />}>
          {inAccessibleDashboard ? (
            <div className="under_construction_content primary_background">
              <NoDataMsg message="This dashboard page is not available for the selected geographies." />
            </div>
          ) : (
            <div className="dfp_sotu">
              <div className="media_spend_and_showroom">
                <div className="media_spend_leads">
                  <ErrorBoundary fallback={<ErrorMsg />}>
                    <MediaSpendGraph currency={currencySymbol} market={market} />
                  </ErrorBoundary>
                  <ErrorBoundary fallback={<ErrorMsg />}>
                    <LeadsFunnelGraph />
                  </ErrorBoundary>
                </div>
                <ShowRoomGraph />
              </div>
              <div className="metric_comparison_and_grade_mix">
                <div className="metric_comparisons_module">
                  <ErrorBoundary fallback={<ErrorMsg />}>
                    <MetricComparisons />
                  </ErrorBoundary>
                </div>
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <GradeMixGraph />
                </ErrorBoundary>
              </div>
            </div>
          )}
        </ErrorBoundary>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default DfpDashboard;
