import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FiltersLayout } from '../Layouts'
import { ErrorBoundary } from '@sentry/react'
import PurchaseFunnelDateFilter from './common/PurchaseFunnelDateFilter'
import { ErrorMsg } from '../AppMessages'
import { setParameters, setSpecificParameter } from '../../actions'
import { ALL_OPTION_NO_SPACE, DEFAULT_CCS_MONETIZATION_PARAMETERES } from '../../constants'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { closeFilterOptions, closeMarketOptions } from './subs/helpers'
import { eventTracking, MixpanelEvents } from '../../utils/userTracking'
import { DashboardBannerContext, FilterContext } from '../../context'
import { DefaultFilter, MultiSelectFilter } from './common'
import { currentDefaultFiltersSet, defaultFiltersSet } from '../../utils/utilityFunctions'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { getCcsMonetizationFilters } from '../../api'
import { useQuery } from '@tanstack/react-query'

interface Props extends RouteComponentProps {
  dashboard: string
}
const CcsMonetizationFilters = withRouter((props: Props) => {
  const { history, dashboard } = props;
  const dispatch = useDispatch();

  const { date_range: dateRangeParamValue, brand: brandParamValue, market: marketParamValue, model: modelParamValue, service: serviceParamValue } = useSelector((state: RootStateOrAny) => state.parameters)
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const [marketFilterValue, setMarketFilterValue] = useState(marketParamValue);
  const [modelFilterValue, setModelFilterValue] = useState(modelParamValue);
  const [serviceFilterValue, setServiceFilterValue] = useState(serviceParamValue);

  const { data, refetch } = useQuery({
    queryKey: ["ccsMonetizationFiltersData", history.location.search],
    queryFn: getCcsMonetizationFilters,
    initialData: {},
    enabled: false,
  });

  const {
    market: markets,
    model: models,
    brand: brands,
    service: services,
  } = data;

  const { setShowBanner, setBannerText } = useContext(DashboardBannerContext)

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet(dashboard);
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_CCS_MONETIZATION_PARAMETERES));
      currentDefaultFiltersSet(dashboard);
    }
    setShowBanner(true)
  }, []);

  useEffect(() => {
    refetch()
  }, [history.location.search])

  useEffect(() => {
    if (!dateRangeParamValue) {
      dispatch(setSpecificParameter("date_range", DEFAULT_CCS_MONETIZATION_PARAMETERES.date_range))
    }
  }, [dateRangeParamValue])

  useEffect(() => {
    if (markets && marketParamValue != ALL_OPTION_NO_SPACE) {
      const selectedMarkets = marketParamValue.split(",")
      const allMarketsValid = selectedMarkets.map((market: string) => markets.includes(market)).every((item: boolean) => item)
      if ((!allMarketsValid)) {
        dispatch(setSpecificParameter("market", ALL_OPTION_NO_SPACE))
      }
    }
  }, [markets, marketParamValue])

  useEffect(() => {
    if (services && serviceParamValue != ALL_OPTION_NO_SPACE) {
      const selectedServices = serviceParamValue ? serviceParamValue.split(",") : []
      const allServicesValid = selectedServices.length ? selectedServices.map((service: string) => services.includes(service)).every((item: boolean) => item) : false
      const validServices = selectedServices.filter((service: string) => services.includes(service))
      if ((allServicesValid && validServices)) {
        dispatch(setSpecificParameter("service", validServices.join(",")))
      } else {
        dispatch(setSpecificParameter("service", ALL_OPTION_NO_SPACE))
      }
    }
  }, [services, serviceParamValue])

  // Set market filter value
  useEffect(() => {
    if (markets && marketParamValue) {
      const selectedMarketsArr = marketParamValue.split(",");
      const marketValue =
        selectedMarketsArr?.length === markets.length
          ? "All"
          : selectedMarketsArr?.length > 1
            ? `${selectedMarketsArr?.length} markets selected`
            : marketParamValue;

      setMarketFilterValue(marketValue);
    } else {
      setMarketFilterValue(ALL_OPTION_NO_SPACE);
    }
  }, [markets, marketParamValue]);

  // Set model filter value
  useEffect(() => {
    if (models?.length && modelParamValue) {
      const selectedModels =
        models?.length === modelParamValue.split(",")?.length
          ? "All"
          : modelParamValue.includes(",")
            ? `${modelParamValue.split(",")?.length} models selected`
            : modelParamValue;
      setModelFilterValue(selectedModels);
    }
  }, [modelParamValue, models]);

  // Set model filter value
  useEffect(() => {
    if (services?.length && serviceParamValue) {
      const selectedServices =
        services?.length === serviceParamValue.split(",")?.length
          ? "All"
          : serviceParamValue.includes(",")
            ? `${serviceParamValue.split(",")?.length} services selected`
            : serviceParamValue;
      setServiceFilterValue(selectedServices);
    }
  }, [serviceParamValue, services]);

  //set banner text
  useEffect(() => {
    let text = "Please Note: X-Trail is not monetized yet as the subscriptions are still in the free period (except for Stolen Vehicle Tracking)."
    if (modelParamValue && modelParamValue != ALL_OPTION_NO_SPACE) {
      text += "\nPlease Note: The total revenue is only at market level and it is not dynamic at model or service level."
    }
    if (serviceParamValue && serviceParamValue != ALL_OPTION_NO_SPACE) {
      text += "\nPlease Note: Customer take are not at service level and you may encounter 'n/a' values."
    }
    setBannerText(text)
  }, [modelParamValue, serviceParamValue])

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));

      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "CCS Monetization",
        page: "Purchase Funnel",
      });
    }
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const handleMarketFilterSubmission = useCallback(() => {
    const parameterName = "market";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === markets?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "CCS Monetization",
    });
    setShowFilterOptions(false);
  }, [markets]);

  const handleModelFilterSubmission = useCallback(() => {
    const parameterName = "model";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === models?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "CCS Monetization",
    });
    closeFilterOptions();
  }, [models]);

  const handleServiceFilterSubmission = useCallback(() => {
    const parameterName = "service";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === services?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "CCS Monetization",
    });
    closeFilterOptions();
  }, [services]);

  //reset filter button functionality
  const resetFilters = useCallback(() => {
    dispatch(setParameters(DEFAULT_CCS_MONETIZATION_PARAMETERES));
  }, []);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    dateRangeParamValue && params.set("date_range", dateRangeParamValue);
    brandParamValue && params.set("brand", brandParamValue);
    marketParamValue && params.set("market", marketParamValue);
    modelParamValue && params.set("model", modelParamValue);
    serviceParamValue && params.set("service", serviceParamValue);

    history.push({ search: `?${params.toString()}` });
  }, [
    dateRangeParamValue,
    marketParamValue,
    modelParamValue,
    brandParamValue,
    serviceParamValue,
  ])

  return (
    <FiltersLayout resetFilters={resetFilters}>
      <>
        {/*Date range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <PurchaseFunnelDateFilter
            value={dateRangeParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
            onFilterClick={showFilterOptionsFn}
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            filterName={"brand"}
            list={brands ?? []}
            filterValue={brandParamValue}
            filterLabel={"BRAND"}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Geography*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="market"
            filterName={"GEOGRAPHY"}
            // @ts-ignore
            filterList={markets ?? []}
            value={marketFilterValue}
            parameterName={"market"}
            parameterValue={marketParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleMarketFilterSubmission}
          />
        </ErrorBoundary>

        {/*Model*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="model"
            filterName={"MODEL"}
            // @ts-ignore
            filterList={models ?? []}
            value={modelFilterValue}
            parameterName={"model"}
            parameterValue={modelParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleModelFilterSubmission}
          />
        </ErrorBoundary>

        {/*SERVICE*/}
        {dashboard == "CCS Monetization Report" ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <MultiSelectFilter
              parentKey="service"
              filterName={"SERVICE"}
              // @ts-ignore
              filterList={services ?? []}
              value={serviceFilterValue}
              parameterName={"service"}
              parameterValue={serviceParamValue}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleServiceFilterSubmission}
            />
          </ErrorBoundary>
        ) : null}
      </>
    </FiltersLayout>
  )
})

export default CcsMonetizationFilters