import React, { useContext, useEffect } from 'react'
import { Tooltip } from 'react-tippy'
import MonetizationPieChart from '../../../../components/Charts/CCSMonetizaation/PieChart'
import { ThemeContext } from '../../../../context'
import LoadingEllipsis from '../../../../components/Loading/LoadingEllipsis'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getTotalRevenueByMarketPie } from '../../../../api/ccsMonetizationApi'
import { NoDataMsg } from '../../../../components/AppMessages'

const TotalRevenuPerMarket = withRouter((props: RouteComponentProps) => {
  const { history } = props
  const { theme } = useContext(ThemeContext);

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["totalRevenuePerMarketData", history.location.search],
    queryFn: getTotalRevenueByMarketPie,
    enabled: false,
  });

  useEffect(() => {
    refetch()
  }, [history.location.search])

  return (
    <div className="tile">
      <div className="tile-header">
        <p>Total Revenue per Market</p>
      </div>
      <div className="tile-content">
        {data && data?.length ?
          <MonetizationPieChart
            chartId='totalRevenuePerMarket'
            data={data}
            valueField='revenue'
            categoryField='value'
            theme={theme}
            legendPosition='bottom'
            isLoading={isFetching}
          />
          : <NoDataMsg />}
      </div>
    </div>
  )
})

export default TotalRevenuPerMarket