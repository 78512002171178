import React, { useContext, useEffect, useMemo } from 'react'
import DeepDiveMultipleBarLineChart from '../../../../components/Charts/ExecutiveSummary/DeepDiveMultipleBarLineChart'
import { ThemeContext } from '../../../../context'
import { useQuery } from '@tanstack/react-query'
import { getMarketSplit } from '../../../../api/executiveSummaryProductPerformanceDeepDive'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { FOREST_GREEN, GOLDEN_YELLOW, PURE_ORANGE, RACING_GREEN, RED } from '../../../../constants'
import { NoDataMsg } from '../../../../components/AppMessages'
import LoadingEllipsis from '../../../../components/Loading/LoadingEllipsis'

const ratingColorMapping = {
  "Rating 1": RED,
  "Rating 2": PURE_ORANGE,
  "Rating 3": GOLDEN_YELLOW,
  "Rating 4": RACING_GREEN,
  "Rating 5": FOREST_GREEN,
};

const MarketSplitVoc = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["marketSplitData", history.location.search],
    queryFn: getMarketSplit,
    enabled: false
  });

  useEffect(() => {
    refetch();
  }, [history.location.search])

  const colouredSeries = useMemo(() => {
    if (data?.series) {
      return data.series.map((series: { name: keyof typeof ratingColorMapping }) => ({ ...series, color: ratingColorMapping[series.name] }))
    }
    return []
  }, [data?.series])

  return (
    <div className='tile'>
      <div className="tile-header">
        <div className='title-container'>
          <div className='title'>Market Split Voc</div>
        </div>
      </div>
      <div className='tile-content'>
        {isFetching ? <LoadingEllipsis isLoading={isFetching} />
          : data ? (
            <DeepDiveMultipleBarLineChart
              chartName='marketSplitVocChart'
              data={data?.data ?? []}
              seriesList={colouredSeries}
              theme={theme}
              isLoading={isFetching}
              isCategoryAxis={true}
              categoryAxisField='market'
              stackedBars={true}
            />) : <NoDataMsg />}
      </div>
    </div>
  )
});

export default MarketSplitVoc