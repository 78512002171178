import { format, lastDayOfMonth, parse } from "date-fns";
import { VOC_DATE_FILTER_OPTIONS } from "../constants";
import { fetchDataV2, newApiFetchV2 } from "./utils";

export const getParams = () => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);

  const dateRangeParam = parameters.get("date_range");
  const brand = parameters.get("brand") == "All" ? [] : parameters.get("brand")?.split(",") ?? [];
  const market = parameters.get("market") == "All" ? [] : parameters.get("market")?.split(",") ?? [];
  const dealer = parameters.get("dealer") == "All" ? [] : parameters.get("dealer")?.split(",") ?? [];
  const model = parameters.get("model") == "All" ? [] : parameters.get("model")?.split(",") ?? [];
  const model_type = parameters.get("model_type") == "All" ? [] : parameters.get("model_type")?.split(",") ?? [];

  const date = dateRangeParam
    ? VOC_DATE_FILTER_OPTIONS.includes(dateRangeParam)
      ? dateRangeParam.split(" ").join("_").toLocaleLowerCase()
      : "custom"
    : "";

  const parameterEntries: Record<string, string | null | Array<string> | undefined> = {
    date_range: date,
    brand: brand,
    market: market,
    dealer: dealer,
    model: model,
    vehicle_type: model_type,
  };

  if (date == "custom") {
    parameterEntries["start_date"] = dateRangeParam
      ? format(parse(dateRangeParam.split("-")[0].trim(), "MMMM yyyy", new Date()), "y-MM-dd")
      : "";
    parameterEntries["end_date"] = dateRangeParam ? format(lastDayOfMonth(new Date(dateRangeParam.split("-")[1].trim())), "y-MM-dd") : "";
  }

  return parameterEntries;
};

export const getQuickVocAfterSalesFilters = () => newApiFetchV2("/quick-voc-after-sales/filters", getParams());

export const getQuickVocAfterSalesLastRefreshDate = () => newApiFetchV2("/quick-voc-after-sales/last-refresh-date", getParams());

export const getPerformanceByMarketMap = () => newApiFetchV2(`/quick-voc-after-sales/performance-by-market`, getParams());

export const getMarketDeepDiveTable = () => newApiFetchV2(`/quick-voc-after-sales/market-deep-dive-table`, getParams());

export const getMarketDeepDiveTrendline = () => newApiFetchV2(`/quick-voc-after-sales/satisfaction-score-by-market`, getParams());

export const getDealerDeepDiveTable = (filterBy?: string) =>
  newApiFetchV2(`/quick-voc-after-sales/dealer-deep-dive-table?filter_by=${filterBy}`, getParams());

export const getDealerOverallPerformanceTrend = (filterBy?: string) =>
  newApiFetchV2(`/quick-voc-after-sales/satisfaction-score-by-dealer?filter_by=${filterBy}`, getParams());

export const getDealerScoreDistribution = (filterBy?: string) =>
  newApiFetchV2(`/quick-voc-after-sales/dealer-score-distribution?filter_by=${filterBy}`, getParams());

export const getModelDeepDiveTable = (filterBy?: string) =>
  newApiFetchV2(`/quick-voc-after-sales/model-deep-dive-table?filter_by=${filterBy}`, getParams());

export const getModelOverallPerformanceTrend = (filterBy?: string) =>
  newApiFetchV2(`/quick-voc-after-sales/satisfaction-score-by-model?filter_by=${filterBy}`, getParams());

export const getModelScoreDistribution = (filterBy?: string) =>
  newApiFetchV2(`/quick-voc-after-sales/model-score-distribution?filter_by=${filterBy}`, getParams());

export const getModelTypeDeepDiveTable = () => newApiFetchV2(`/quick-voc-after-sales/model-type-deep-dive-table`, getParams());

export const getModelTypeOverallPerformanceTrend = () =>
  newApiFetchV2(`/quick-voc-after-sales/satisfaction-score-by-model-type`, getParams());

export const getModelTypeScoreDistribution = () => newApiFetchV2(`/quick-voc-after-sales/model-type-score-distribution`, getParams());
