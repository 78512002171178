import React, { useContext, useEffect } from 'react'
import { ThemeContext } from '../../../context';
import { Tooltip } from 'react-tippy';
import EcommerceStackedBarChart from '../../../components/Charts/Ecommerce/EcommerceStackedBarChart';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getSaleBreakdownByModel } from '../../../api/ecommerce';
import { DIGITAL_LEADS_SOURCE_COLORS, SOV_MIX_COLORS, WEBSITE_VISITS_COLORS } from '../../../constants';
import { NoDataMsg } from '../../../components/AppMessages';

const SalesBreakdownByModel = withRouter((props: RouteComponentProps) => {
  const { history } = props
  const { theme } = useContext(ThemeContext);


  const { data, isFetching, refetch } = useQuery({
    queryKey: ["salesBreakdownByModel", history.location.search],
    queryFn: getSaleBreakdownByModel,
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [history.location.search])

  return (
    <div className="ecommerce-tile">
      <div className="tile-header">
        <div className="header-title">
          <p>Ecommerce Sales Breakdown by Model </p>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            title={
              "Number of Ecommerce Sales broken down by model"
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      {data?.data?.length > 0 ? (
        <EcommerceStackedBarChart data={data?.data ?? []} seriesList={data?.series ?? []} chartName="salesBreakdownByModelChart" theme={theme} isLoading={isFetching} />
      ) : <NoDataMsg />}
    </div>
  );
})

export default SalesBreakdownByModel