import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getEcommerceDataDefinition } from "../../../api/ecommerce";

const EcommerceData = () => {

  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    getEcommerceDataDefinition().then((response: any) => {
      if (response && "error" in response) {
        toast.error("Error fetching data updates");
      } else {
        setData(response);
      }
    });
  }, []);

  return (
    <div className="data_documentation">
      <h3>ECOMMERCE - DATA OVERVIEW</h3>
      <h4>Ecommerce KPI definitions</h4>
      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <td>KPI</td>
            <td>Definition</td>
          </tr>
        </thead>
        <tbody>
          {data?.map(item => (
            <tr key={item.Definition}>
              <td>{item.KPI}</td>
              <td>{item.KPI_definition}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EcommerceData;
