import React, { useContext, useEffect, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThemeContext } from '../../../../context';
import DeepDiveMultipleBarLineChart from '../../../../components/Charts/ExecutiveSummary/DeepDiveMultipleBarLineChart';
import { useQuery } from '@tanstack/react-query';
import { NoDataMsg } from '../../../../components/AppMessages';
import { BLUE, GRAY } from '../../../../constants';

interface Props extends RouteComponentProps {
  title: string;
  getDataFn: () => any;
}

const YoyComparisonTile = withRouter((props: Props) => {
  const { title, getDataFn, history } = props;
  const { theme } = useContext(ThemeContext);

  const { data, refetch, isFetching } = useQuery({
    queryKey: [`${title.replace(/ /g, "_")}ConfigVsOverall`, history.location.search],
    queryFn: getDataFn,
    enabled: false
  });

  useEffect(() => {
    refetch()
  }, [history.location.search])

  const coloredSeries = useMemo(() => {
    if (data?.series) {
      return data.series.map((series: any) => {
        return {
          ...series,
          color: series.type === 'bar' ? series.name.includes("previous") ? GRAY : BLUE : undefined,
        }
      });
    }
    return [];
  }, [data?.series])

  return (
    <div className='tile'>
      <div className="tile-header">
        <div className="title-container">
          {title} - YoY comparison
        </div>
      </div>
      <div className="tile-content">
        {data?.data ?
          <DeepDiveMultipleBarLineChart
            chartName={`${title.toLowerCase().replace(/ /g, '_')}YoyCmoparisonChart`}
            data={data?.data}
            seriesList={coloredSeries}
            theme={theme}
            isLoading={isFetching}
            lineAxisIsPercentage={true}
          />
          :
          <NoDataMsg />
        }
      </div>
    </div>
  )
})

export default YoyComparisonTile