import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { setPageName, setSpecificParameter } from "../../../actions";
import {
  fetchEuropeAppRatingsAvgData,
  fetchEuropeAppRatingsAvgTotalData,
  fetchEuropeAppRatingsTrendData,
} from "../../../actions/ccsCockpitActions";
import "../../../assets/styles/pages/ccsAppRatings.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import { AppRatingsBreakdownSplitGeoLineGraph } from "../../../components/Charts/CCSCockpit/AppRatingsBreakdownSplitGeoLineGraph";
import Collapsible from "../../../components/Collapsible/Collapsible";
import { CcsAppRatingsBreakdownFilters } from "../../../components/Filters/CcsAppRatingsBreakdownFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import BackButton from "../../../components/PageContent/subs/BackButtonLink";
import { ALL_OPTION_NO_SPACE, AVG_APP_RATINGS_COLORS, REGIONAL_COLORS } from "../../../constants";
import { BackgroundContext, ThemeContext } from "../../../context";
import { isCustomDate, isDigitalPerformanceDate } from "../../../utils/dateFunctions";
import { ApplicationTypeTitle } from "./subs";
import EuropeAppRatingsBreakdownTable from "./subs/EuropeAppRatingsBreakdownTable";

export const AppRatingsBreakdown = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { history, location } = props;

  const themeContext = useContext(ThemeContext);
  const { setHideBackgroundImage } = useContext(BackgroundContext);

  const ccsAppRatingsLastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ccs_appreview);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const regionParamValue = useSelector((state: RootStateOrAny) => state.parameters.region);
  const appParamValue = useSelector((state: RootStateOrAny) => state.parameters.app);
  const appStoreParamValue = useSelector((state: RootStateOrAny) => state.parameters.app_store);

  const { avg_data: europeAvgData } = useSelector((state: RootStateOrAny) => state.ccs_cockpit.europe_app_ratings_avg_data);
  const { data: europeTrendData, series: europeTrendSeries } = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.europe_app_ratings_trend_data
  );

  const [selectedApplication, setSelectedApplication] = useState<string>("");
  const [inActiveApp, setInActiveApp] = useState("");
  const [androidChecked, setAndroidChecked] = useState(true);
  const [iosChecked, setIosChecked] = useState(true);
  const [unselectedMarkets, setUnselectedMarkets] = useState<Array<string>>([]);

  const dates: Array<string> = useMemo(() => dateRangeParamValue.split(","), [dateRangeParamValue]);

  useEffect(() => {
    setHideBackgroundImage(true);
    dispatch(setPageName("App analysis"));

    return () => setHideBackgroundImage(false);
  }, []);

  // Fetching main data on filter change
  useEffect(() => {
    (!isDigitalPerformanceDate(dateRangeParamValue) || !isCustomDate(dateRangeParamValue)) &&
      Promise.all([dispatch(fetchEuropeAppRatingsAvgData())]);
  }, [history.location.search, dateRangeParamValue]);

  const filteredMarketsData = useMemo(() => {
    const markets = europeAvgData.map((item: { market: string }) => item.market);
    return markets.filter((item: string) => !unselectedMarkets.includes(item));
  }, [europeAvgData, unselectedMarkets]);

  useEffect(() => {
    (!isDigitalPerformanceDate(dateRangeParamValue) || !isCustomDate(dateRangeParamValue)) &&
      Promise.all([
        dispatch(fetchEuropeAppRatingsAvgTotalData(filteredMarketsData)),
        dispatch(fetchEuropeAppRatingsTrendData(filteredMarketsData)),
      ]);
  }, [history.location.search, dateRangeParamValue, filteredMarketsData]);

  useEffect(() => {
    if (!/all/gi.test(appParamValue)) {
      setSelectedApplication(appParamValue);
    } else {
      setSelectedApplication("");
    }
  }, [appParamValue]);

  useEffect(() => {
    if (selectedApplication.length && selectedApplication !== "EV") {
      setInActiveApp("EV");
    }
    if (selectedApplication.length && selectedApplication !== "Service") {
      setInActiveApp("Service");
    }
    if (!selectedApplication.length) setInActiveApp("");
  }, [selectedApplication]);

  useEffect(() => {
    if (appStoreParamValue === "Android") {
      setIosChecked(false);
      setAndroidChecked(true);
    } else if (appStoreParamValue === "iOS") {
      setAndroidChecked(false);
      setIosChecked(true);
    } else {
      setIosChecked(true);
      setAndroidChecked(true);
    }
  }, [appStoreParamValue]);

  const updateSelectedAppStore = (app_store: string, appChecked: boolean) => {
    if (app_store === "iOS") {
      if (!androidChecked && appChecked) {
        setAndroidChecked(true);
        setIosChecked(false);
        dispatch(setSpecificParameter("app_store", "Android"));
      } else {
        if (iosChecked) {
          dispatch(setSpecificParameter("app_store", "Android"));
        } else {
          dispatch(setSpecificParameter("app_store", ALL_OPTION_NO_SPACE));
        }
        setIosChecked(!iosChecked);
      }
    }
    if (app_store === "Android") {
      if (!iosChecked && appChecked) {
        setAndroidChecked(false);
        setIosChecked(true);
        dispatch(setSpecificParameter("app_store", "iOS"));
      } else {
        if (androidChecked) {
          dispatch(setSpecificParameter("app_store", "iOS"));
        } else {
          dispatch(setSpecificParameter("app_store", ALL_OPTION_NO_SPACE));
        }
        setAndroidChecked(!androidChecked);
      }
    }
  };

  const onHandleApplicationSelection = useCallback(
    () => (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const applicationType = dataset?.type;

      if (applicationType === selectedApplication) {
        setSelectedApplication("");
        dispatch(setSpecificParameter("app", ALL_OPTION_NO_SPACE));
      } else {
        setSelectedApplication(applicationType as string);
        dispatch(setSpecificParameter("app", applicationType as string));
      }
    },
    [selectedApplication, dispatch]
  );

  const tableData = useMemo(() => {
    const data: any = [];
    if (europeAvgData > 0) {
      return europeAvgData.map((dataItem: any, index: number) => ({ ...dataItem, color: REGIONAL_COLORS[index] }));
    }
    return data;
  }, [europeAvgData]);

  const marketColors = useMemo(() => {
    const result: Record<string, string> = {};
    if (europeAvgData) {
      europeAvgData.forEach((market: Record<string, string | number | null>, index: number) => {
        result[market.market as string] = AVG_APP_RATINGS_COLORS[index];
      });
    }
    return result;
  }, [europeAvgData]);

  const handleOnClickMarket = useCallback(
    (market: string) => {
      if (unselectedMarkets.includes(market)) {
        setUnselectedMarkets(unselectedMarkets.filter((item) => item != market));
      } else {
        setUnselectedMarkets((prevState) => [...prevState, market]);
      }
    },
    [unselectedMarkets]
  );

  const filteredSeries = useMemo(() => {
    if (europeTrendSeries) {
      return europeTrendSeries?.filter((item: { market: string }) => !unselectedMarkets.includes(item.market));
    }
    return [];
  }, [europeTrendSeries, unselectedMarkets]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<CcsAppRatingsBreakdownFilters filterBtns={[]} />}
        dataDocumentation={"ccs_data"}
        lastDataRefresh={ccsAppRatingsLastDataRefresh}
        pageTitle={
          <PageTitle
            dataDocumentation="ccs_data"
            backButton={<BackButton id={"app_ratings_brakdown"} link="/ccs_vital_signs/app_ratings" />}
          />
        }
      >
        <ErrorBoundary fallback={<ErrorMsg />}>
          <Collapsible myStyle="ccs_app_ratings" show={true} title="Customer experience">
            <div className="ratings_tile app_ratings" id="grouped_ratings" style={{ flexDirection: "row", height: "450px" }}>
              <div className="ratings_tile_content chart_tile_content" style={{ minHeight: "initial", height: "100%" }}>
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <div className="ratings_tile_header grey_info">App rating trend</div>
                  <AppRatingsBreakdownSplitGeoLineGraph
                    data={europeTrendData ? europeTrendData : []}
                    series={filteredSeries}
                    theme={themeContext.theme}
                    colorsMapping={marketColors}
                  />
                </ErrorBoundary>
              </div>
              <div className="average_app_ratings">
                <div className="ratings_tile_header grey_info">
                  <div className="title_tooltip">
                    Average app ratings
                    <Tooltip
                      position="top-end"
                      className={"normal_tippy"}
                      trigger="mouseenter"
                      animation="none"
                      interactive={true}
                      distance={50}
                      delay={1}
                      hideDelay={1}
                      duration={1}
                      title={
                        "For the individual markets, the app ratings are based on the latest month in the date range selected. The average of selected markets is a weighted average using the cumulative downloads."
                      }
                      size={"small"}
                    >
                      <span className="info_tooltip" />
                    </Tooltip>
                  </div>
                  <div className="ratings_select">
                    <div className="appstore_selection">
                      <div className="store_selection">
                        <input
                          type="checkbox"
                          id="ios"
                          data-test-id="ios_radio_btn"
                          value="iOS"
                          checked={iosChecked}
                          onChange={() => updateSelectedAppStore("iOS", iosChecked)}
                        />
                        <label htmlFor="ios">iOS</label>
                      </div>
                      <div className="store_selection">
                        <input
                          type="checkbox"
                          id="android"
                          data-test-id="android_radio_btn"
                          value="Android"
                          checked={androidChecked}
                          onChange={() => updateSelectedAppStore("Android", androidChecked)}
                        />
                        <label htmlFor="android">Android</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", height: "96%" }}>
                  <EuropeAppRatingsBreakdownTable
                    colorsMapping={marketColors}
                    unselectedMarkets={unselectedMarkets}
                    onClickMarket={handleOnClickMarket}
                  />
                  <div style={{ width: "17em" }}>
                    {regionParamValue == "Europe" && (
                      <ApplicationTypeTitle
                        title={"NissanConnect EV"}
                        applicationType={"EV"}
                        isSelected={selectedApplication === "EV"}
                        isInactive={inActiveApp === "EV"}
                        handleApplicationClick={onHandleApplicationSelection()}
                      />
                    )}
                    <ApplicationTypeTitle
                      title={"NissanConnect Services"}
                      applicationType={"Service"}
                      borderStyle={"dotted"}
                      isSelected={selectedApplication === "Service"}
                      isInactive={inActiveApp === "Service"}
                      handleApplicationClick={onHandleApplicationSelection()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Collapsible>
        </ErrorBoundary>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
