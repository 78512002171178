import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchEcommerceFilters, setParameters, setSpecificParameter } from "../../actions";
import "../../assets/styles/component/filters.scss";
import {
  DEFAULT_ECOMMERCE_PARAMETERS,
  MIN_SELECTABLE_DATE,
  OCE_DATE_FILTER_OPTIONS,
} from "../../constants";
import { FilterContext } from "../../context";
import { isCustomDate, isDigitalPerformanceDate } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DateFilter, DefaultFilter, MultiSelectFilter } from "./common";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";

const EcommerceFilters = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const dataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.digital_performance);
  const filters = useSelector((state: RootStateOrAny) => state.filters.ecommerce_filters);

  const {
    date_range: dateRangeParamValue,
    brand: brandParamValue,
    market: marketParamValue,
    model: modelParamValue,
    product: productParamValue,
    currency: currencyParamValue,
    local_currency: localCurrencyParam
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const [productFilterValue, setProductFilterValue] = useState<string>("");

  //Sets the initial start and end selectable date to be 1st April 2019 and the latest data refresh | today() - 1 (yesterday) if the last data refresh is not yet defined
  const [marketDataAvailability, setMarketDataAvailability] = useState<{ start: string; end: string }>({
    start: MIN_SELECTABLE_DATE,
    end: dataRefresh || moment().subtract(1, "days").format("DD/MM/YYYY"),
  });

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Ecommerce Performance");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_ECOMMERCE_PARAMETERS));
      currentDefaultFiltersSet("Ecommerce Performance");
    }
  }, []);

  useEffect(() => {
    dispatch(fetchEcommerceFilters());
  }, [history.location.search]);

  // Set default date range values
  useEffect(() => {
    const validDate = isDigitalPerformanceDate(dateRangeParamValue) || isCustomDate(dateRangeParamValue);

    if (!dateRangeParamValue || !validDate) dispatch(setSpecificParameter("date_range", DEFAULT_ECOMMERCE_PARAMETERS.date_range));
  }, [dateRangeParamValue, dispatch]);

  useEffect(() => {
    if (filters?.brand && filters?.brand?.length > 0 && !filters.brand.includes(brandParamValue) && brandParamValue != "All") {
      dispatch(setSpecificParameter("brand", filters.brand[0]));
    }

    if (filters?.market && filters?.market?.length > 0 && !filters.market.includes(marketParamValue) && marketParamValue != "All") {
      dispatch(setSpecificParameter("market", "All"));
    }

    if (filters?.model && filters?.model?.length > 0 && !filters.model.includes(modelParamValue) && modelParamValue != "All") {
      dispatch(setSpecificParameter("model", "All"));
    }

    if (
      (filters?.ecommerce_product &&
        filters?.ecommerce_product?.length > 0 &&
        !filters.ecommerce_product.includes(productParamValue) &&
        productParamValue != "All") ||
      !productParamValue
    ) {
      dispatch(setSpecificParameter("product", "All"));
    }
  }, [filters, brandParamValue, marketParamValue, modelParamValue, productParamValue, dispatch]);

  const marketList = useMemo(() => {
    if (filters?.market) {
      return ["All", ...filters.market];
    }
    return ["All"];
  }, [filters]);

  const modelList = useMemo(() => {
    if (filters?.model) {
      return ["All", ...filters.model];
    }
    return ["All"];
  }, [filters]);

  const productList = useMemo(() => {
    if (filters?.ecommerce_product) {
      return [...filters.ecommerce_product];
    }
    return [];
  }, [filters]);

  useEffect(() => {
    if (filters?.local_currency && localCurrencyParam != filters.local_currency) {
      dispatch(setSpecificParameter("local_currency", filters.local_currency))
    }
  }, [filters, localCurrencyParam])

  useEffect(() => {
    if (currencyParamValue == undefined || currencyParamValue == "undefined") {
      dispatch(setSpecificParameter("currency", DEFAULT_ECOMMERCE_PARAMETERS.currency))
    }
  }, [currencyParamValue])

  useEffect(() => {
    dispatch(setSpecificParameter("currency", "USD"))
  }, [marketParamValue])


  useEffect(() => {
    if (localCurrencyParam == undefined || localCurrencyParam == "undefined") {
      dispatch(setSpecificParameter("local_currency", DEFAULT_ECOMMERCE_PARAMETERS.local_currency))
    }
  }, [localCurrencyParam])

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
    }
    eventTracking(MixpanelEvents.filter_change, {
      filter: filterName,
      value: optionValue,
      dashboard: "Ecommerce",
      page: "Ecommerce",
    });
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const onRenderOptions = ({ list, filter }: { list: string[]; filter: string }) => {
    return (
      <>
        {list?.map((item: string) => {
          return (
            <li key={item} data-filter={filter} onClick={handleFilterOptionClick} data-value={item}>
              {item}
            </li>
          );
        })}
      </>
    );
  };

  const resetFilters = useCallback(() => {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(DEFAULT_ECOMMERCE_PARAMETERS)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });
    window.location.reload();
  }, [history]);

  const handleProductFilterSubmission = useCallback(() => {
    const parameterName = "product";
    const selectedCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    closeFilterOptions();
    dispatch(setSpecificParameter("product", productList?.length === selectedValues?.length ? "All" : selectedValues.join(",")));
  }, [productList]);

  useEffect(() => {
    if (productParamValue) {
      const selectedProductss =
        productList?.length === productParamValue.split(",")?.length
          ? "All"
          : productParamValue.includes(",")
            ? `${productParamValue.split(",")?.length} products selected`
            : productParamValue;
      setProductFilterValue(selectedProductss);
    }
  }, [productParamValue, productList]);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("market", marketParamValue);
    params.set("model", modelParamValue);
    params.set("product", productParamValue);
    params.set("currency", currencyParamValue);
    params.set("local_currency", localCurrencyParam)
    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, brandParamValue, marketParamValue, productParamValue, modelParamValue, currencyParamValue, localCurrencyParam]);

  return (
    <FiltersLayout resetFilters={resetFilters}>
      <>
        {/*Date Range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DateFilter
            onFilterClick={showFilterOptionsFn}
            renderOptions={onRenderOptions}
            value={dateRangeParamValue}
            maxDate={dataRefresh}
            availableDates={marketDataAvailability}
            dateOptions={OCE_DATE_FILTER_OPTIONS}
            page="Product Performance"
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={filters?.brand ?? ["Nissan"]}
            filterName={"brand"}
            filterLabel={"BRAND"}
            filterValue={brandParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Market*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={marketList}
            filterName={"market"}
            filterLabel={"MARKET"}
            filterValue={marketParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Model*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={modelList}
            filterName={"model"}
            filterLabel={"MODEL"}
            filterValue={modelParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Product*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="product"
            // @ts-ignore
            filterList={productList}
            filterName="ECOMMERCE PRODUCT"
            value={productFilterValue}
            parameterName={"product"}
            parameterValue={productParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleProductFilterSubmission}
          />
        </ErrorBoundary>

        {/*Currency*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={filters.currencies ?? []}
            filterName={"currency"}
            filterLabel={"CURRENCY"}
            filterValue={currencyParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>
      </>
    </FiltersLayout>
  );
});

export default EcommerceFilters;
