import React, { useContext, useEffect } from 'react'
import MonetizationPieChart from '../../../../components/Charts/CCSMonetizaation/PieChart'
import { ThemeContext } from '../../../../context'
import MultipleLineChart from '../../../../components/Charts/CCSMonetizaation/MiltipleLineChart'
import { MARKETING_ACTIVITY_COLORS } from '../../../../constants'
import { getSvtSubscriptionsPerModel, getSvtSubscriptionsPerModelTrendline } from '../../../../api/ccsMonetizationApi'
import { useQuery } from '@tanstack/react-query'
import { withRouter, RouteComponentProps } from 'react-router-dom'

const ModelBreakdownTile = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext)

  const { data: pieChartData, isFetching: pieChartIsFFetching, refetch: refetchPieChartData } = useQuery({
    queryKey: ["svtSubscriptionsPerModelPieData", history.location.search],
    queryFn: getSvtSubscriptionsPerModel,
    enabled: false,
  });

  const { data: trendChartData, isFetching: trendIsFetching, refetch: refetchTrendChartData } = useQuery({
    queryKey: ["svtSubscriptionsPerModelTrendData", history.location.search],
    queryFn: getSvtSubscriptionsPerModelTrendline,
    enabled: false,
  });

  useEffect(() => {
    refetchPieChartData()
    refetchTrendChartData()
  }, [history.location.search])

  return (
    <div className='breakdown-tile'>
      <div className='tile-header'>
        Model breakdown
      </div>
      <div className='tile-content'>
        <div className='chart-tile'>
          <div className='tile-header'>SVT Subscriptions Number per Model</div>
          <div className="tile-content">
            <MonetizationPieChart
              chartId='subscriptionNoPerModelPieChart'
              data={pieChartData}
              valueField='value'
              categoryField='category'
              theme={theme}
              legendPosition='bottom'
              isLoading={pieChartIsFFetching}
            />
          </div>
        </div>
        <div className='chart-tile'>
          <div className='tile-header'> SVT Subscriptions Number Trendline per Model </div>
          <div className="tile-content">
            <MultipleLineChart
              chartId='subscriptionsPerModelLineChart'
              data={trendChartData?.data ?? []}
              seriesList={trendChartData?.series ?? []}
              theme={theme}
              isLoading={trendIsFetching}
              seriesColors={MARKETING_ACTIVITY_COLORS}
              isPercentage={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export default ModelBreakdownTile