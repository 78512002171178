import React, { useContext, useMemo } from 'react'
import VocPieChart from '../../../../components/Charts/VOC/VocPieChart'
import { ThemeContext } from '../../../../context';
import LoadingEllipsis from '../../../../components/Loading/LoadingEllipsis';
import VocDonutChart from '../../../../components/Charts/VOC/VocDonutChart';
import { RED, PURE_ORANGE, GOLDEN_YELLOW, RACING_GREEN, FOREST_GREEN } from '../../../../constants';

interface Props {
  chartId: string;
  data: any;
  metric: string;
  isLoading: boolean;
  totalAvg?: string;
}

const donutColorMapping: Record<number, string> = {
  1: RED,
  2: PURE_ORANGE,
  3: GOLDEN_YELLOW,
  4: RACING_GREEN,
  5: FOREST_GREEN,
};

const DeepDiveVolume = (props: Props) => {
  const { chartId, metric, data, isLoading, totalAvg } = props;
  const { theme } = useContext(ThemeContext)

  const coloredScoreDistributionData = useMemo(() => {
    return data
      .sort((a: any, b: any) => b[metric] - a[metric])
      .map((item: any) => ({
        ...item,
        color: donutColorMapping[Number(item[metric])],
      }));
  }, [data]);

  return (
    <VocDonutChart chartId={chartId} categoryField={metric} valueField="volume" theme={theme} data={coloredScoreDistributionData} isLoading={isLoading} totalAvg={totalAvg} innerRadius={32} hideSeriesLabels={true} decimalRounding={2} />
  )
}

export default DeepDiveVolume