import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { DefaultFilter } from '../../../../components/Filters/common';
import { closeFilterOptions } from '../../../../components/Filters/subs/helpers';
import DeepDiveOveralTable from './DeepDiveOveralTable';
import DeepDiveOvertimePerformanceTrend from './DeepDiveOvertimePerformanceTrend';
import DeepDiveVolume from './DeepDiveVolume';
import { useQuery } from '@tanstack/react-query';

interface Props extends RouteComponentProps {
  mainTitle: string;
  metrics?: Array<string>;
  overallTableTitle?: string;
  overtimeTrendTitle?: string;
  volumeOfResponsesTitle?: string;
  metric: string;
  tableQueryFn: (metric?: string) => Record<string, any>;
  trendQueryFn: (metric?: string) => Record<string, any>;
  distributionQueryFn: (metric?: string) => Record<string, any>;
}

const DeepDiveBreakdown = withRouter((props: Props) => {
  const { mainTitle, metrics, overallTableTitle, overtimeTrendTitle, volumeOfResponsesTitle, metric, history, tableQueryFn, trendQueryFn, distributionQueryFn } = props

  const [metricFilterValue, setMetricFilterValue] = useState(metrics ? metrics[0] : "");

  const { data: tableData, isFetching: tableIsFetching, refetch: tableDataRefetch } = useQuery({
    queryKey: [`${metric}DeepDiveTableData`, history.location.search, metricFilterValue],
    queryFn: () => metrics ? tableQueryFn(metricFilterValue) : tableQueryFn(),
    initialData: [],
    enabled: false,
  });

  const { data: trendData, isFetching: trendIsFetching, refetch: trendDataRefetch } = useQuery({
    queryKey: [`${metric}DeepDiveTrendData`, history.location.search, metricFilterValue],
    queryFn: () => metrics ? trendQueryFn(metricFilterValue) : trendQueryFn(),
    initialData: [],
    enabled: false,
  });

  const { data: distributionData, isFetching: distributionIsFetching, refetch: distributionDataRefetch } = useQuery({
    queryKey: [`${metric}DeepDiveDistributionData`, history.location.search, metricFilterValue],
    queryFn: () => metrics ? distributionQueryFn(metricFilterValue) : distributionQueryFn(),
    initialData: [],
    enabled: false,
  });

  useEffect(() => {
    tableDataRefetch();
    trendDataRefetch()
    distributionDataRefetch()
  }, [history.location.search, metricFilterValue]);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionValue = dataset?.value;

    if (optionValue) {
      setMetricFilterValue(optionValue);
    }
    closeFilterOptions();
  }, []);

  return (
    <div className='tile'>
      <div className="tile-header">
        <div className="title-container">
          <div className="title">
            {mainTitle}
          </div>
        </div>
        <div className="filters-container">
          {metrics && (
            <DefaultFilter
              list={metrics}
              filterName={`metric_${metric}`}
              filterLabel={"FILTER DATA BY"}
              filterValue={metricFilterValue}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          )}
        </div>
      </div>
      <div className='deep-dive-row'>
        <div className='tile'>
          <div className="tile-header">
            <div className="title-container">
              <div className="title">
                {metrics ? `${metricFilterValue} overall performance` : overallTableTitle}
              </div>
            </div>
          </div>
          <DeepDiveOveralTable data={tableData} isLoading={tableIsFetching} metric={metric} />
        </div>
        <div className='tile'>
          <div className="tile-header">
            <div className="title-container">
              <div className="title">
                {metrics ? `${metricFilterValue} overtime performance` : overtimeTrendTitle}
              </div>
            </div>
          </div>
          <DeepDiveOvertimePerformanceTrend data={trendData?.data ?? []} seriesList={trendData?.series ?? []} chartId={`${metric}OverallPerformanceChart`} isLoading={trendIsFetching} />
        </div>
        <div className='tile'>
          <div className="tile-header">
            <div className="title-container">
              <div className="title">
                {metrics ? `${metricFilterValue} score distribution` : volumeOfResponsesTitle}
              </div>
            </div>
          </div>
          <DeepDiveVolume chartId={`${metric}VolumeChart`} data={distributionData?.data ?? []} metric={metric} isLoading={distributionIsFetching} totalAvg={distributionData?.overall} />
        </div>
      </div>
    </div>
  )
})

export default DeepDiveBreakdown