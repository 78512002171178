import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../../components/AppMessages";
import { ActivationTrendLineChart } from "../../../../components/Charts/EuropeActivation/ActivationTrendLineChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { ActivationTable } from "../../../../components/Tables/ActivationTable";
import { ThemeContext } from "../../../../context";

interface ActivationBreakdownProps {
  title: string;
  chartData: any;
  tableData: any;
  chartId: string;
  tableId: string;
  tableHeader: string;
  dataKey: string;
  isLoading: boolean;
  isCumulative: { cumulative: boolean; startDate: string };
}

export const ActivationBreakdown = (props: ActivationBreakdownProps): JSX.Element => {
  const { title, chartId, tableId, tableHeader, dataKey, chartData, tableData, isLoading, isCumulative } = props;

  const themeContext = useContext(ThemeContext);

  const activationFormatParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.activation_format);
  const showRates = activationFormatParamValue === "rates";

  const formattedChartData = useMemo(() => {
    const startDateOfDash = moment().subtract(2, "months").startOf("month");
    return chartData?.data?.map((item: any) => ({
      ...item,
      dash: moment(item.date, "YYYY-MM-DD").isSameOrAfter(startDateOfDash) ? 5 : 0,
    }));
  }, [chartData]);

  return (
    <div
      className="activation_container activation_breakdown"
      id={`${dataKey}_chart_table_container`}
      data-test-id={`${dataKey}_chart_table`}
    >
      <h3 className="activation_breakdown_title">{title}</h3>
      <div className="activation_breakdown_chart">
        <ErrorBoundary fallback={<ErrorMsg />}>
          <ActivationTrendLineChart
            data={formattedChartData ?? []}
            seriesList={chartData?.series ?? []}
            chartId={chartId}
            activationFormat={activationFormatParamValue}
            isCumulative={isCumulative}
            theme={themeContext.theme}
          />
        </ErrorBoundary>
      </div>
      <div className="activation_breakdown_table">
        <ErrorBoundary fallback={<ErrorMsg />}>
          {tableData ? <ActivationTable
            isKeyNumeric={true}
            data={tableData}
            tableId={tableId}
            showRates={showRates}
            header={tableHeader}
            keyAccessor={dataKey}
            showMoreBtn={tableData?.length > 5}
          /> : <NoDataMsg />}

        </ErrorBoundary>{" "}
      </div>
      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};
