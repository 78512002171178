import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { fetchVisitsDropOff } from "../../../actions/ecommerceAtions";
import VisitsDropoffChart from "../../../components/Charts/Ecommerce/VisitsDropoffChart";
import { EcommerceCheckBox } from "../../../components/Forms/common/EcommerceCheckbox";
import { ThemeContext } from "../../../context";

const VisistsDropoff = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const { data: visitsDropOffData, online_order_cvr_data } = useSelector((state: RootStateOrAny) => state.ecommerce.visits_drop_off);
  const loading = useSelector((state: RootStateOrAny) => state.loading.visits_drop_off);

  const [ecommerceCheckboxValue, setEcommerceCheckboxValue] = useState("All");

  useEffect(() => {
    dispatch(fetchVisitsDropOff(ecommerceCheckboxValue));
  }, [history.location.search, dispatch, ecommerceCheckboxValue]);

  const handleOnChangeCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEcommerceCheckboxValue(e.target.value);
    },
    [ecommerceCheckboxValue]
  );

  return (
    <div className="ecommerce-tile">
      <div className="tile-header">
        <div className="header-title">
          <p>Visits Drop-off within the Ecommerce Funnel</p>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            title={"Ecommerce quality visits to online orders. PDP visits can be higher than QV due to looping customer behaviour when viewing more than one vehicle"}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <div className="tile-content">
        <p style={{ fontWeight: "bold" }}>Online Order CVR: {online_order_cvr_data}%</p>
        <VisitsDropoffChart
          data={visitsDropOffData}
          theme={themeContext.theme}
          chartId="ecommerce_visits_droppoff_chart"
          isLoading={loading}
        />
      </div>
    </div>
  );
});

export default VisistsDropoff;
