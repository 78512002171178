import React from 'react'
import { Tooltip } from 'react-tippy'
import { isNull } from '../../../../utils/utilityFunctions';
import LoadingEllipsis from '../../../../components/Loading/LoadingEllipsis';
import { Link } from 'react-router-dom';
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import Ratings from '../../../../components/Ratings/Ratings';

interface Props {
  title: string;
  value: number;
  currency?: string;
  isPercentage?: boolean
  tooltipText: string;
  isLoading: boolean;
  formatValue?: boolean;
  prevPeriodValue?: number;
}

const MetricTile = (props: Props) => {
  const { title, value, currency, isPercentage, tooltipText, isLoading, formatValue, prevPeriodValue } = props

  const abbreviateNum = (num: number) => {
    if (num < 1e3) return num;
    if (num >= 1e3 && num < 1e6)
      return +(num / 1e3).toFixed(1) + "K";
    if (num >= 1e6 && num < 1e9)
      return +(num / 1e6).toFixed(1) + "M";
    if (num >= 1e9 && num < 1e12)
      return +(num / 1e9).toFixed(1) + "B";
    if (num >= 1e12) return +(num / 1e12).toFixed(1) + "T";
  }

  return (
    <div className="metric-tile">
      <div className="tile-header">
        <p>{title}</p>
        <Tooltip
          position="top"
          className={"normal_tippy"}
          trigger="mouseenter"
          animation="none"
          interactive={true}
          delay={1}
          hideDelay={1}
          duration={1}
          title={tooltipText}
          size={"small"}
        >
          <span className="info_tooltip" />
        </Tooltip>
        {title == "Stolen Vehicle Tracking Subscriptions" && (
          <Link className="dashboard-link" to="/ccs_vital_signs/ccs_monetization/stv_subscriptions">
            <ArrowCircleRightOutlinedIcon style={{ color: "#00a0dd" }} />
          </Link>
        )}

      </div>
      <div className="tile-content">
        <div className='current-period'>
          {isNull(value) ? "" : currency} {isNull(value) ? "n/a" : isPercentage ? value : formatValue ? abbreviateNum(value) : value?.toLocaleString()}{isPercentage && !isNull(value) ? "%" : ""}
        </div>
        {prevPeriodValue && (
          <div className='previous-period'>
            <Ratings value={prevPeriodValue} isPercentageValue={isPercentage ? true : false} testValue={false} />
            {prevPeriodValue ?? "n/a"}{prevPeriodValue ? isPercentage ? "PTS YoY" : "% YoY" : ""}
          </div>
        )}
      </div>
      <LoadingEllipsis isLoading={isLoading} />
    </div>
  )
}

export default MetricTile