import React, { useEffect } from 'react'
import { DashboardLayout } from '../../../components/Layouts'
import { DefaultPageContent } from '../../../components/PageContent'
import { PageTitle } from '../../../components/PageContent/subs'
import CcsMonetizationFilters from '../../../components/Filters/CcsMonetizationFilters'
import { setPageName } from '../../../actions'
import { useDispatch } from 'react-redux'
import MetricTile from './components/MetricTile'
import "../../../assets/styles/pages/ccsMonetizationDashboard.scss"
import BreakdownTable from './components/BreakdownTable'
import Volumes from './components/Volumes'
import TakeRates from './components/TakeRates'
import { getCcsMonetizationLastDataRefresh, getCcsMonetizationSummary } from '../../../api/ccsMonetizationApi'
import { useQuery } from '@tanstack/react-query'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import TotalRevenuPerMarket from './components/TotalRevenuPerMarket'
import TotalRevenuePerMarketTrendline from './components/TotalRevenuePerMarketTrendline'
import CustomerServiceTakeRatePieChart from './components/CustomerServiceTakeRatePieChart'
import CustomerTakeRate from './components/CustomerTakeRate'

const Monetization = withRouter((props: RouteComponentProps) => {
  const { history } = props
  const dispatch = useDispatch();

  const { data: lastDataRefreshData, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["ccsMonetizationLastDataRefresh"],
    queryFn: getCcsMonetizationLastDataRefresh,
    enabled: false,
  });

  const { data: summaryData, refetch: refetchSummaryData, isFetching } = useQuery({
    queryKey: ["ccsMonetizationSummaryData", history.location.search],
    queryFn: getCcsMonetizationSummary,
    enabled: false,
  });

  useEffect(() => {
    dispatch(setPageName("CCS Monetization Report"))
    refetchLastDataRefresh()
  }, []);

  useEffect(() => {
    refetchSummaryData()
  }, [history.location.search])

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<CcsMonetizationFilters dashboard='CCS Monetization Report' />}
        dataDocumentation="ccs_monetization"
        lastDataRefresh={lastDataRefreshData?.date ?? ""}
        pageTitle={<PageTitle dataDocumentation="ccs_monetization" />}
      >
        <div className='ccs-monetization-layout'>
          <div className='row-3'>
            <MetricTile title='Total Revenue (incl SVT)' value={summaryData?.total_revenue} tooltipText='New subscriptions + previous subscriptions yearly purchase (cumulative revenue number)' isLoading={isFetching} formatValue={true} prevPeriodValue={summaryData?.total_revenue_yoy} currency='€' />
            <TotalRevenuPerMarket />
            <TotalRevenuePerMarketTrendline />
          </div>
          <div className='row-2'>
            <MetricTile title='Average Revenue per Customer' value={summaryData?.avg_revenue} tooltipText='The total revenue divided by the number of customers' isLoading={isFetching} prevPeriodValue={summaryData?.avg_revenue_yoy} currency='€' formatValue={true} />
            <MetricTile title='Stolen Vehicle Tracking Subscriptions' value={summaryData?.svt_subscription} tooltipText='Stolen Vehicle Tracking subscriptions' isLoading={isFetching} prevPeriodValue={summaryData?.svt_subscription_yoy} />
          </div>
          <div className='row-4'>
            <div className='metric-tile-container'>
              <MetricTile title='Customer take rate' value={summaryData?.customer_take_rate} tooltipText='Number of customers SUBSCRIBED for at least one service / Total number of REACHED for the given period' isLoading={isFetching} isPercentage={true} prevPeriodValue={summaryData?.customer_take_rate_yoy} />
              <MetricTile title='Service take rate' value={summaryData?.service_take_rate} tooltipText='Total number of services SUBSCRIBED / Total number of SUBSCRIPTIONS possible for the given period​' isLoading={isFetching} isPercentage={true} prevPeriodValue={summaryData?.service_take_rate_yoy} />
            </div>
            <div className='cust-service-pie-chart'>
              <CustomerServiceTakeRatePieChart />
            </div>
            <div className='cust-service-table-container'>
              <BreakdownTable />
            </div>
          </div>
          <div className='row-3'>
            <Volumes />
            <TakeRates />
            <CustomerTakeRate />
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  )
})

export default Monetization