import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ErrorMsg } from "../../../../components/AppMessages";
import { VOC_TOPIC_CLASSIFICATION_COLORS } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import UseOwnershipTopicChart from "../../../../components/Charts/UseOwnership/UseOwnershipTopicChart";
import { useQuery } from "@tanstack/react-query";
import { getSelfServiceVocPerCategory } from "../../../../api/useOwnershipApi";

const VocPerCategory = withRouter((props: RouteComponentProps) => {
  const { history } = props
  const themeContext = useContext(ThemeContext);

  const {
    data: vocPerCategoryData,
    isLoading: vocPerCategoryLoading,
    refetch: refetchVocPerCategory,
  } = useQuery({
    queryKey: ["vocPerCategoryeData", history.location.search],
    queryFn: getSelfServiceVocPerCategory,
    enabled: false,
  });

  useEffect(() => {
    refetchVocPerCategory();
  }, [history.location.search]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="top-categories span-3">
        <div>
          <div className="tile top-category-tile" >
            <div className=" market-answers">
              <p className="bold">VOC per category</p>
            </div>
            <div className="categoryContainer">
              <UseOwnershipTopicChart
                chartId={`vocTopicChart`}
                data={vocPerCategoryData ?? []}
                theme={themeContext.theme}
                colorMapping={VOC_TOPIC_CLASSIFICATION_COLORS}
                isLoading={vocPerCategoryLoading}
                categoryField="category"
                valueField="experience_rating"
              />
            </div>
          </div>

        </div>
      </div>
    </ErrorBoundary>
  );
});

export default VocPerCategory;
