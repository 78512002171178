import moment from "moment";
import { isCustomDate } from "../utils/dateFunctions";
import { fetchDataV2, newApiFetchV2 } from "./utils";

export const ecommerceApiParamsToObject = () => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);

  const dateRangeParam = parameters?.get("date_range");
  const brandParameter = parameters?.get("brand");
  const marketParameter = parameters?.get("market") == "All" ? ["All"] : parameters?.get("market")?.split(",");
  const modelParameter = parameters?.get("model") == "All" ? ["All"] : parameters?.get("model")?.split(",");
  const productParameter = parameters?.get("product");
  const currencyParameter = parameters?.get("currency");
  const localCurrencyParameter = parameters?.get("local_currency");

  const paramObj: any = {
    brand: brandParameter,
    market: marketParameter,
    model: modelParameter,
    ecommerce_product: productParameter,
    currency: currencyParameter,
    local_currency: localCurrencyParameter,
  };
  if (dateRangeParam && isCustomDate(dateRangeParam as string)) {
    paramObj["date_range"] = "custom";
    paramObj["start_date"] = moment(dateRangeParam.split("-")[0], "DD/MM/YYYY").format("YYYY-MM-DD");
    paramObj["end_date"] = moment(dateRangeParam.split("-")[1], "DD/MM/YYYY").format("YYYY-MM-DD");
  } else if (dateRangeParam) {
    paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
  }

  return paramObj;
};

export const getVisitsDropOff = (segment: string) => newApiFetchV2("/ecom/visits-drop-off", { ...ecommerceApiParamsToObject(), segment });
export const getInfluenceOfShoppingTools = () => newApiFetchV2("/ecom/influence-of-shopping-tools", { ...ecommerceApiParamsToObject() });
export const getOrders = () => newApiFetchV2("/ecom/orders", { ...ecommerceApiParamsToObject() });
export const getTdsLeads = () => newApiFetchV2("/ecom/tds-leads", { ...ecommerceApiParamsToObject() });
export const getNonTdsLeads = () => newApiFetchV2("/ecom/non-tds-leads", { ...ecommerceApiParamsToObject() });
export const getEcommerceLastDataRefresh = () => fetchDataV2(`/ecom/last-refresh-date`);
export const getOrderSummaryData = () => newApiFetchV2("/ecom/get-order-summary-data", { ...ecommerceApiParamsToObject() });
export const getGlobalKeyIndicatorsData = (metric: string) =>
  newApiFetchV2(`/ecom/get-ecomerce-platform-on-pace-data-map?metric=${metric}`, { ...ecommerceApiParamsToObject() });
export const getSaleTrendComparedToDisData = () =>
  newApiFetchV2("/ecom/get-ecomerce-sale-compared-to-dis-trend", { ...ecommerceApiParamsToObject() });
export const getSaleBreakdownByModel = () =>
  newApiFetchV2("/ecom/get-ecomerce-breakdown-by-model-trend", { ...ecommerceApiParamsToObject() });
export const getTotalOrderValueTrend = () => newApiFetchV2("/ecom/get-total-order-value-trend", { ...ecommerceApiParamsToObject() });
export const getTotalOrderValueTable = (showValue: boolean) =>
  newApiFetchV2(`/ecom/get-total-order-value-by-market-table?show_value=${showValue}`, { ...ecommerceApiParamsToObject() });
export const getOrderValueByExtrasTrend = () => newApiFetchV2("/ecom/get-order-value-by-extras-trend", { ...ecommerceApiParamsToObject() });
export const getOrderValueByExtrasTable = (showValue: boolean) =>
  newApiFetchV2(`/ecom/get-order-value-by-extras-by-market-table?show_value=${showValue}`, { ...ecommerceApiParamsToObject() });
export const getOrderNumberWithUpsellTrend = () =>
  newApiFetchV2("/ecom/get-order-number-with-upsell-trend", { ...ecommerceApiParamsToObject() });
export const getOrderValueWithUpsellTrend = () =>
  newApiFetchV2("/ecom/get-order-value-with-upsell-trend", { ...ecommerceApiParamsToObject() });
export const getEcommerceDataDefinition = () => fetchDataV2("/ecom/data-definition");
export const getEcommerceFeedbackComments = (experience_rating: any, topics: any, touchpoints: any) =>
  newApiFetchV2("/ecom/feedback-comments", { ...ecommerceApiParamsToObject(), experience_rating, topics, touchpoints });
export const getEcommerceFeedbackCommentsFilters = () =>
  newApiFetchV2("/ecom/feedback-comments-filters", { ...ecommerceApiParamsToObject() });
