import React, { useContext, useEffect, useMemo } from 'react'
import { ThemeContext } from '../../../../context'
import { DefaultTable } from '../../../../components/Tables/subs'
import Ratings from '../../../../components/Ratings/Ratings'
import { RootStateOrAny, useSelector } from 'react-redux'
import { getVolumeBreakdownTable, getVolumeBreakdownTrend } from '../../../../api/ccsMonetizationApi'
import { useQuery } from '@tanstack/react-query'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { NoDataMsg } from '../../../../components/AppMessages'
import StackedBarChart from '../../../../components/Charts/CCSMonetizaation/StackedBarChart'
import { Tooltip } from 'react-tippy'
import LoadingEllipsis from '../../../../components/Loading/LoadingEllipsis'

const Volumes = withRouter((props: RouteComponentProps) => {
  const { history } = props
  const { theme } = useContext(ThemeContext)

  const { market: marketParamValue } = useSelector((state: RootStateOrAny) => state.parameters)

  const { data: trendData, isFetching: trendIsFetching, refetch: trendDataRefetch } = useQuery({
    queryKey: ["volumeBreakedownTrendData", history.location.search],
    queryFn: getVolumeBreakdownTrend,
    enabled: false,
  });

  const { data: tableData, isFetching: tableIsFetching, refetch: tableDataRefetch } = useQuery({
    queryKey: ["volumeBreakedownTableData", history.location.search],
    queryFn: getVolumeBreakdownTable,
    enabled: false,
  });

  useEffect(() => {
    trendDataRefetch()
    tableDataRefetch()
  }, [history.location.search])

  const columns = useMemo(
    () => [
      {
        id: "markets",
        Header: "G5 markets",
        accessor: `market`,
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
      },
      {
        Header: "Campaigns reached customers",
        accessor: `reached_volumes`,
        id: "reachedVolumes",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: ({ value }: { value: number }) => {
          return (
            <div className="table-cell">
              <Ratings value={value} isPercentageValue={false} testValue={false} /> {value ? `${Math.abs(value).toLocaleString()}` : "n/a"}
            </div>
          );
        },
      },
      {
        Header: "Subscribed customers",
        accessor: `subscriptions_volumes`,
        id: "subscriptionVolumes",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: ({ value }: { value: number }) => {
          return (
            <div className="table-cell">
              <Ratings value={value} isPercentageValue={false} testValue={false} /> {value ? `${Math.abs(value).toLocaleString()}` : "n/a"}
            </div>
          );
        },
      },
    ],
    [marketParamValue]
  );

  return (
    <div className='column-2'>
      <div className='tile'>
        <div className='tile-header'>
          Campaigns reached customers vs Subscribed customers
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={"The number of customers who received the subscription expiry emails vs the number of customers who subscribed to the service after receiving that email"}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <div className='tile-content'>
          {trendData?.data && trendData?.data?.length ?
            <StackedBarChart
              chartId='volumesStackedBarChart'
              data={trendData.data}
              seriesList={trendData.series}
              theme={theme}
              isLoading={trendIsFetching}
              categoryField="market"
            /> :
            <NoDataMsg />
          }
        </div>
      </div>
      <div className='tile'>
        <div className='tile-header'>
          Campaigns reached customers vs Subscribed customers per Market
        </div>
        <div className='tile-content'>
          {tableData && tableData.length > 0 ? (
            <DefaultTable
              columns={columns}
              data={tableData ?? []}
              id={"visits_trend_breakdown"}
              data-test-id={"visits_trend_breakdown"}
              hiddenCols={[]}
              initialSortColId={{ id: "reached_volumes", desc: true }}
              isChildComponent={false}
            />
          ) : tableIsFetching ? (
            <LoadingEllipsis isLoading={tableIsFetching} />
          ) : (
            <NoDataMsg />
          )}
        </div>
      </div>
    </div>

  )
})

export default Volumes