import { Button } from "@material-ui/core";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { ErrorBoundary } from "@sentry/react";
import moment from "moment/moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link, RouteComponentProps } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { setPageName, setSpecificParameter } from "../../../actions";
import {
  fetchAllReviewsData,
  fetchAppOpenRateData,
  fetchAppRatingsAvgData,
  fetchAppRatingsByMarket,
  fetchAppRatingsByMarketChartData,
  fetchAppRatingsDownloadsData,
  fetchAppSatisfactionData,
  fetchReviewsByTopic,
} from "../../../actions/ccsCockpitActions";
import "../../../assets/styles/pages/ccsAppRatings.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import { AppRatingsSplitGeoLineGraph } from "../../../components/Charts/CCSCockpit";
import { AppPerformanceLineChart } from "../../../components/Charts/CCSCockpit/AppPerforamanceLineChart";
import AppRatingsBarStackedChart from "../../../components/Charts/CCSCockpit/AppRatingsBarStackedChart";
import AppSatisfactionChart from "../../../components/Charts/CCSCockpit/AppSatisfactionChart";
import { LegendMarker } from "../../../components/Charts/components/LegendMarker";
import Collapsible from "../../../components/Collapsible/Collapsible";
import { CcsAppRatingsFilters } from "../../../components/Filters";
import { DashboardLayout } from "../../../components/Layouts";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { DefaultPageContent } from "../../../components/PageContent";
import { BackToCockpitButton, PageTitle } from "../../../components/PageContent/subs";
import { ReviewsTable } from "../../../components/Tables";
import { ToggleSwitch } from "../../../components/Toogle";
import { ALL_OPTION, ALL_OPTION_NO_SPACE, FOREST_GREEN, LIGHT_CYAN, ORANGE, PURPLE, RED, TURQUOISE } from "../../../constants";
import { allAppRatingsServicesTitle, defaultAppRatingsServicesTitle, europeAppRatingsServicesTitle } from "../../../constants/ccsConstants";
import { BackgroundContext, DashboardBannerContext, ThemeContext } from "../../../context";
import { isCustomDate, isDigitalPerformanceDate } from "../../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { sortMonthYear } from "../Cockpit/subs/helpers";
import { ApplicationTypeTitle, RatingsMarketBreakdown, RatingsPills } from "./subs";
import TopicFilter from "./subs/TopicFilter";

const RATINGS_COLORS: { [index: string]: string } = {
  "United States": PURPLE,
  Japan: LIGHT_CYAN,
  Europe: FOREST_GREEN,
  UAE: RED,
  ASEAN: ORANGE,
};
const APP_DASHARRAY_MAPPING: Record<string, string> = {
  EV: "0,0",
  "NissanConnect Services": "2,2",
  MyNISSAN: "15,3",
  "InTouch Services": "6,5",
  MyINFINITI: "6,5,2,4",
};
const applications = ["EV", "Service", "MyNISSAN", "InTouch Services", "MyINFINITI"];

export const AppRatings = withRouter((props: RouteComponentProps) => {
  const { history, location } = props;
  const themeContext = useContext(ThemeContext);
  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);

  const ccsAppRatingsLastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ccs_appreview);
  const {
    all_reviews: allReviewsLoading,
    app_rating_by_market: appRatingsLoading,
    app_review_by_topic: reviewsByTopicLoading,
    app_satisfaction: appSatisfactionLoading,
    downloads: appDownloadsLoading,
    open_rate: appOpenRateLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const brandParamValue = useSelector((state: RootStateOrAny) => state.parameters.brand);
  const regionParamValue = useSelector((state: RootStateOrAny) => state.parameters.region);
  const appParamValue = useSelector((state: RootStateOrAny) => state.parameters.app);
  const appStoreParamValue = useSelector((state: RootStateOrAny) => state.parameters.app_store);
  const topicParamValue = useSelector((state: RootStateOrAny) => state.parameters.topic);
  const appRatingsByMarket = useSelector((state: RootStateOrAny) => state.ccs_cockpit.app_ratings_by_market);
  const appRatingsByMarketChartData = useSelector((state: RootStateOrAny) => state.ccs_cockpit.app_ratings_by_market_chart_data);
  const reviewsData = useSelector((state: RootStateOrAny) => state.ccs_cockpit.reviews);
  const appSatisfactionData = useSelector((state: RootStateOrAny) => state.ccs_cockpit.app_satisfaction);
  const appDownloadsData = useSelector((state: RootStateOrAny) => state.ccs_cockpit.app_ratings_downloads);
  const appOpenRateData = useSelector((state: RootStateOrAny) => state.ccs_cockpit.open_rate);
  const appRatingAvgData = useSelector((state: RootStateOrAny) => state.ccs_cockpit.app_ratings_avg);
  const { all_data: reviewsByTopicData, topics } = useSelector((state: RootStateOrAny) => state.ccs_cockpit.app_review_by_topic);
  const topicOptions = useSelector((state: RootStateOrAny) => state.filters.app_ratings_filters?.topics ?? []);
  const [servicesTitle, setServicesTitle] = useState(defaultAppRatingsServicesTitle);
  const [showOriginalLanguage, setShowOriginalLanguage] = useState(false);
  const [inActiveApps, setInActiveApps] = useState<Array<string>>([]);

  const [selectedApplication, setSelectedApplication] = useState<string>("");
  const [selectedMarket, setSelectedMarket] = useState<string>("");

  const [topicFilterValue, setTopicFilterValue] = useState("");

  const [androidChecked, setAndroidChecked] = useState(true);
  const [iosChecked, setIosChecked] = useState(true);
  const dispatch = useDispatch();

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "CCS", page: "CCS App Ratings" });
    setShowBanner(true);
    setBannerText("If the data isn’t loading or being displayed, reset the filters and/or reload the page.");
  }, [location.pathname]);

  useEffect(() => {
    if (!/all/gi.test(appParamValue)) {
      setSelectedApplication(appParamValue);
    } else {
      setSelectedApplication("");
    }
  }, [appParamValue]);

  useEffect(() => {
    if (regionParamValue) {
      /all/gi.test(regionParamValue)
        ? setServicesTitle(allAppRatingsServicesTitle)
        : regionParamValue === "Europe"
        ? setServicesTitle(europeAppRatingsServicesTitle)
        : setServicesTitle(defaultAppRatingsServicesTitle);
    }
    regionParamValue && !/all/gi.test(regionParamValue) ? setSelectedMarket(regionParamValue) : setSelectedMarket("");
  }, [regionParamValue]);

  useEffect(() => {
    if (appStoreParamValue === "Android") {
      setIosChecked(false);
      setAndroidChecked(true);
    } else if (appStoreParamValue === "iOS") {
      setAndroidChecked(false);
      setIosChecked(true);
    } else {
      setIosChecked(true);
      setAndroidChecked(true);
    }
  }, [appStoreParamValue]);

  const [selectedRatings, setSelectedRatings] = useState<Array<number>>([]);
  const [marketRatings, setMarketRatings] = useState<{
    [index: string]: {
      ev: number | null;
      services: number | null;
      one_app: number | null;
      in_touch_services: number | null;
      my_infiniti: number | null;
      ev_count: number | null;
      services_count: number | null;
      one_app_count: number | null;
      in_touch_services_count: number | null;
      my_infiniti_count: number | null;
    };
  }>({});

  useEffect(() => {
    if (selectedApplication.length) {
      setInActiveApps(applications.filter((app: string) => app != selectedApplication));
    }
    if (!selectedApplication.length) setInActiveApps([]);
  }, [selectedApplication]);

  const { setHideBackgroundImage } = useContext(BackgroundContext);

  const onBtnLinkClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;

      history.push({ pathname: pageLink, search: "" });
    },
    [history]
  );

  useEffect(() => {
    setHideBackgroundImage(true);
    dispatch(setPageName("App analysis"));

    return () => setHideBackgroundImage(false);
  }, []);

  // Fetching main data on filter change
  useEffect(() => {
    (!isDigitalPerformanceDate(dateRangeParamValue) || !isCustomDate(dateRangeParamValue)) &&
      Promise.all([
        dispatch(fetchAllReviewsData()),
        dispatch(fetchAppRatingsByMarket(appStoreParamValue)),
        dispatch(fetchAppRatingsByMarketChartData(appStoreParamValue)),
        dispatch(fetchReviewsByTopic()),
        dispatch(fetchAppSatisfactionData()),
        dispatch(fetchAppRatingsDownloadsData()),
        dispatch(fetchAppOpenRateData()),
        dispatch(fetchAppRatingsAvgData()),
      ]);
  }, [history.location.search, dateRangeParamValue]);

  const avarageAppRatingsData = useMemo(() => {
    if (appRatingsByMarket?.Current) return appRatingsByMarket.Current;
    return [];
  }, [appRatingsByMarket]);

  const appRatingsChartData = useMemo(() => {
    if (appRatingsByMarketChartData?.Current) {
      const formattedChartData = appRatingsByMarketChartData?.Current.reduce(
        (result: { [index: string]: Record<string, string | number> }, row: any): any => {
          const { date, market, application, rating } = row;
          const label = moment(date).format("MMM YY");

          const applicationType: string = application == "NissanConnect EV" ? "EV" : application;

          if (Object.prototype.hasOwnProperty.call(result, label)) {
            result[label] = {
              ...result[label],
              [`${applicationType} (${market})_color`]: RATINGS_COLORS[market],
              [`${applicationType} (${market})_dashedArray`]: APP_DASHARRAY_MAPPING[applicationType],
              [`${applicationType} (${market})_rating`]: rating,
            };
          } else {
            result[label] = {
              [`${applicationType} (${market})_color`]: RATINGS_COLORS[market],
              [`${applicationType} (${market})_dashedArray`]: APP_DASHARRAY_MAPPING[applicationType],
              [`${applicationType} (${market})_rating`]: rating,
            };
          }

          return result;
        },
        {}
      );
      const activeDates = dateRangeParamValue.split(",").map((date: string) => moment(date, "MMMM YYYY").format("MMM YY"));

      const finalChartData = sortMonthYear(activeDates).map((activeDate) => {
        const label = moment(activeDate, "MMMM YYYY").format("MMM YY");
        const data = formattedChartData[label];

        return { ...data, label };
      });

      return finalChartData;
    }

    return [];
  }, [appRatingsByMarketChartData, selectedApplication, selectedMarket, dateRangeParamValue]);

  const appRatingsSeries = useMemo(() => {
    if (appRatingsByMarketChartData?.Current) {
      const seriesItems = new Set();
      appRatingsByMarketChartData?.Current.map((dataItem: any) => {
        dataItem.application == "NissanConnect EV"
          ? seriesItems.add(`EV (${dataItem.market})`)
          : seriesItems.add(`${dataItem.application} (${dataItem.market})`);
      });
      return Array.from(seriesItems) as Array<string>;
    }
    return [];
  }, [appRatingsByMarketChartData]);

  const tableData = useMemo(() => {
    if (selectedRatings && selectedRatings.length > 0) {
      return reviewsData.filter((row: { rating: number; application: string }) => {
        const isApplicationSelected = selectedApplication ? selectedApplication === row.application : true;

        return selectedRatings.includes(row.rating) && isApplicationSelected;
      });
    }
    return reviewsData?.filter((row: { rating: number; application: string }) => {
      return selectedApplication ? selectedApplication === row.application : true;
    });
  }, [reviewsData, selectedRatings, selectedApplication]);

  const onPillClick = useCallback(
    () => (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const selectedValue = dataset?.value;

      if (selectedValue) {
        const valueIndex = selectedRatings.indexOf(parseInt(selectedValue, 10));

        const stateCopy = [...selectedRatings];

        valueIndex === -1 ? stateCopy.push(parseInt(selectedValue, 10)) : stateCopy.splice(valueIndex, 1);

        setSelectedRatings(stateCopy);
      }
    },
    [selectedRatings]
  );

  const onHandleApplicationSelection = useCallback(
    () => (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const applicationType = dataset?.type;

      if (applicationType === selectedApplication) {
        setSelectedApplication("");
        dispatch(setSpecificParameter("app", ALL_OPTION_NO_SPACE));
      } else {
        setSelectedApplication(applicationType as string);
        dispatch(setSpecificParameter("app", applicationType as string));
      }
    },
    [selectedApplication, dispatch]
  );

  const onHandleRegionSelection = useCallback(
    () => (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const regionValue = dataset?.region;

      if (regionParamValue) {
        if (/all/gi.test(regionParamValue) || regionParamValue != regionValue) {
          selectedMarket === regionValue ? setSelectedMarket("") : setSelectedMarket(regionValue as string);
          dispatch(setSpecificParameter("region", regionValue as string));
        } else {
          setSelectedMarket("");
          dispatch(setSpecificParameter("region", ALL_OPTION));
          dispatch(setSpecificParameter("market", ALL_OPTION_NO_SPACE));
        }
      }
    },
    [regionParamValue, selectedMarket]
  );

  const updateSelectedAppStore = (app_store: string, appChecked: boolean) => {
    if (app_store === "iOS") {
      if (!androidChecked && appChecked) {
        setAndroidChecked(true);
        setIosChecked(false);
        dispatch(setSpecificParameter("app_store", "Android"));
      } else {
        if (iosChecked) {
          dispatch(setSpecificParameter("app_store", "Android"));
        } else {
          dispatch(setSpecificParameter("app_store", ALL_OPTION_NO_SPACE));
        }
        setIosChecked(!iosChecked);
      }
    }
    if (app_store === "Android") {
      if (!iosChecked && appChecked) {
        setAndroidChecked(false);
        setIosChecked(true);
        dispatch(setSpecificParameter("app_store", "iOS"));
      } else {
        if (iosChecked && appChecked) {
          dispatch(setSpecificParameter("app_store", "iOS"));
        } else {
          dispatch(setSpecificParameter("app_store", ALL_OPTION_NO_SPACE));
        }
        setAndroidChecked(!androidChecked);
      }
    }
  };

  const topicsData = useMemo(() => {
    if (topics && topics.length > 0) {
      const regex = /[/]|[\s+]|[-]/g;
      return topics.map((topic: string) => {
        return { name: topic, field: topic };
      });
    }
    return [];
  }, [topics]);

  const onHandleSubmitTopicFilter = useCallback(
    (values: Array<string>) => {
      if (values.length == topicOptions.length) {
        dispatch(setSpecificParameter("topic", "All"));
      } else {
        dispatch(setSpecificParameter("topic", values.join(",")));
      }
    },
    [topicParamValue]
  );

  // Sets topic value
  useEffect(() => {
    const selectedTopics =
      topicOptions?.length === topicParamValue.split(",")?.length
        ? "All"
        : topicParamValue.includes(",")
        ? `${topicParamValue.split(",")?.length} topics selected`
        : topicParamValue;
    setTopicFilterValue(selectedTopics);
  }, [topicParamValue, topicOptions]);

  const downloadsChartData = useMemo(() => {
    const data: any = [];
    let pointer = 0;
    const seriesListObj: Record<string, string> = {};

    appDownloadsData.map((app: { date: string; app_name: string; downloads: number }) => {
      // get key to be used by amcharts
      const key = app.app_name.toLowerCase().replace(/\s+/g, "_");
      // add key name pair to series list opject
      if (!(app.app_name in seriesListObj)) {
        seriesListObj[app.app_name] = key;
      }
      // check if object with data exists in data array and append app downloads
      if (data.length == 0) {
        data.push({ date: app.date, [key]: app.downloads });
      } else if (data[pointer].date == app.date) {
        data[pointer][key] = app.downloads;
      } else {
        data.push({ date: app.date, [key]: app.downloads });
        pointer += 1;
      }
    });

    const sortedSeriesListObj: Record<string, string> = Object.keys(seriesListObj)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: seriesListObj[key],
        }),
        {}
      );

    const seriesList = Object.keys(sortedSeriesListObj).map((key) => ({ name: key, dataKey: sortedSeriesListObj[key] }));

    return { data: data, seriesList: seriesList };
  }, [appDownloadsData]);

  const openRateChartData = useMemo(() => {
    const data: any = [];
    let pointer = 0;
    const seriesListObj: Record<string, string> = {};

    appOpenRateData.map((app: { date: string; app_name: string; open_rate: number }) => {
      // get key to be used by amcharts
      const key = app.app_name.toLowerCase().replace(/\s+/g, "_");
      // add key name pair to series list opject
      if (!(app.app_name in seriesListObj)) {
        seriesListObj[app.app_name] = key;
      }
      // check if object with data exists in data array and append app openrate
      if (data.length == 0) {
        data.push({ date: app.date, [key]: app.open_rate });
      } else if (data[pointer].date == app.date) {
        data[pointer][key] = app.open_rate;
      } else {
        data.push({ date: app.date, [key]: app.open_rate });
        pointer += 1;
      }
    });

    const sortedSeriesListObj: Record<string, string> = Object.keys(seriesListObj)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: seriesListObj[key],
        }),
        {}
      );

    const seriesList = Object.keys(sortedSeriesListObj).map((key) => ({ name: key, dataKey: sortedSeriesListObj[key] }));

    return { data: data, seriesList: seriesList };
  }, [appOpenRateData]);

  const handleArrowOnClick = (region: string) => {
    dispatch(setSpecificParameter("region", region));
    history.push("/ccs_vital_signs/app_ratings_breakdown");
  };

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<CcsAppRatingsFilters filterBtns={[]} />}
        dataDocumentation={"ccs_data"}
        lastDataRefresh={ccsAppRatingsLastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="ccs_data" backButton={<BackToCockpitButton id={"euro_activation"} />} />}
      >
        <ErrorBoundary fallback={<ErrorMsg />}>
          <Collapsible myStyle="ccs_app_ratings" show={true} title="Customer experience">
            <div className="ratings_tile app_ratings" id="grouped_ratings">
              <div className="average_app_ratings">
                <div className="ratings_tile_header grey_info">
                  <div className="title_tooltip">
                    Average app ratings
                    <Tooltip
                      position="top-end"
                      className={"normal_tippy"}
                      trigger="mouseenter"
                      animation="none"
                      interactive={true}
                      distance={50}
                      delay={1}
                      hideDelay={1}
                      duration={1}
                      title={`The app ratings are based on the latest available month within the date range selected <br><br>
                      Instructions:If a user selects one market then the trendlines will be filtered to show that particular market. <br><br>
                      If a user then selects a different market then the trendlines will change to reflect that individual market. <br><br>
                      If a user selects the same market that is already selected then data for all markets will appear in the graph.`}
                      size={"small"}
                    >
                      <span className="info_tooltip" />
                    </Tooltip>
                  </div>
                  <div className="ratings_select">
                    <div className="appstore_selection">
                      <div className="store_selection">
                        <input
                          type="checkbox"
                          id="ios"
                          data-test-id="ios_radio_btn"
                          value="iOS"
                          checked={iosChecked}
                          onChange={() => updateSelectedAppStore("iOS", iosChecked)}
                        />
                        <label htmlFor="ios">iOS</label>
                      </div>
                      <div className="store_selection">
                        <input
                          type="checkbox"
                          id="android"
                          data-test-id="android_radio_btn"
                          value="Android"
                          checked={androidChecked}
                          onChange={() => updateSelectedAppStore("Android", androidChecked)}
                        />
                        <label htmlFor="android">Android</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ratings_tile_content" style={{ gap: "unset", gridGap: "unset" }}>
                  <table className="average_app_ratings_table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          <div className="tile_header" data-region={"North America"} onClick={onHandleRegionSelection()}>
                            <LegendMarker shape={"square"} color={PURPLE} /> <span>United States</span>
                          </div>
                        </th>
                        <th>
                          <div className="tile_header" data-region={"Japan"} onClick={onHandleRegionSelection()}>
                            <LegendMarker shape={"square"} color={TURQUOISE} /> <span>Japan</span>
                          </div>
                        </th>
                        <th>
                          <div className="tile_header legend-container" data-region={"Europe"} onClick={onHandleRegionSelection()}>
                            <LegendMarker shape={"square"} color={FOREST_GREEN} /> <span>Europe</span>
                            {/* <Link to="/ccs_vital_signs/app_ratings_breakdown"> */}
                            <ArrowCircleRightOutlinedIcon
                              onClick={() => handleArrowOnClick("Europe")}
                              style={{ color: "#00a0dd", marginTop: "5px" }}
                            />
                            {/* </Link> */}
                          </div>
                        </th>
                        <th>
                          <div className="tile_header" data-region={"UAE"} onClick={onHandleRegionSelection()}>
                            <LegendMarker shape={"square"} color={RED} /> <span>ME</span>
                          </div>
                        </th>
                        <th>
                          <div className="tile_header legend-container" data-region={"ASEAN"} onClick={onHandleRegionSelection()}>
                            <LegendMarker shape={"square"} color={ORANGE} /> <span>ASEAN</span>
                            {/* <Link to="/ccs_vital_signs/app_ratings_breakdown"> */}
                            <ArrowCircleRightOutlinedIcon
                              onClick={() => handleArrowOnClick("ASEAN")}
                              style={{ color: "#00a0dd", marginTop: "5px" }}
                            />
                            {/* </Link> */}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(brandParamValue == "All" || brandParamValue == "Nissan") && (
                        <tr>
                          <td className={`${inActiveApps.includes("MyNISSAN") ? "disabled" : ""}`}>
                            <ApplicationTypeTitle
                              title={"MyNISSAN"}
                              applicationType={"MyNISSAN"}
                              borderStyle={"dashed"}
                              isSelected={selectedApplication === "MyNISSAN"}
                              isInactive={inActiveApps.includes("MyNISSAN")}
                              handleApplicationClick={onHandleApplicationSelection()}
                            />
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("MyNISSAN") || (regionParamValue != ALL_OPTION && regionParamValue != "North America")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "North America") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "North America");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_MyNISSAN"]}
                                      ratingCount={rating["rating_MyNISSAN"]}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("MyNISSAN") || (regionParamValue != ALL_OPTION && regionParamValue != "Japan")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "Japan") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "Japan");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_MyNISSAN"]}
                                      ratingCount={rating["rating_MyNISSAN"]}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("MyNISSAN") || (regionParamValue != ALL_OPTION && regionParamValue != "Europe")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "Europe") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "Europe");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_MyNISSAN"]}
                                      ratingCount={rating["rating_MyNISSAN"]}
                                      isDownloads={true}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("MyNISSAN") || (regionParamValue != ALL_OPTION && regionParamValue != "UAE")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "UAE") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "UAE");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_MyNISSAN"]}
                                      ratingCount={rating["rating_MyNISSAN"]}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("MyNISSAN") || (regionParamValue != ALL_OPTION && regionParamValue != "ASEAN")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "ASEAN") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "ASEAN");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_MyNISSAN"]}
                                      ratingCount={rating["rating_MyNISSAN"]}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                      {(brandParamValue == "All" || brandParamValue == "Infiniti") && (
                        <tr>
                          <td className={`${inActiveApps.includes("MyINFINITI") ? "disabled" : ""}`}>
                            <ApplicationTypeTitle
                              title={"MyINFINITI"}
                              applicationType={"MyINFINITI"}
                              borderStyle={"mixed"}
                              isSelected={selectedApplication === "MyINFINITI"}
                              isInactive={inActiveApps.includes("MyINFINITI")}
                              handleApplicationClick={onHandleApplicationSelection()}
                            />
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("MyINFINITI") || (regionParamValue != ALL_OPTION && regionParamValue != "North America")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "North America") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "North America");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_MyINFINITI"]}
                                      ratingCount={rating["rating_MyINFINITI"]}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("MyINFINITI") || (regionParamValue != ALL_OPTION && regionParamValue != "Japan")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "Japan") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "Japan");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_MyINFINITI"]}
                                      ratingCount={rating["rating_MyINFINITI"]}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("MyINFINITI") || (regionParamValue != ALL_OPTION && regionParamValue != "Europe")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "Europe") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "Europe");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_MyINFINITI"]}
                                      ratingCount={rating["rating_MyINFINITI"]}
                                      isDownloads={true}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("MyINFINITI") || (regionParamValue != ALL_OPTION && regionParamValue != "UAE")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "UAE") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "UAE");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_MyINFINITI"]}
                                      ratingCount={rating["rating_MyINFINITI"]}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("MyINFINITI") || (regionParamValue != ALL_OPTION && regionParamValue != "ASEAN")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "ASEAN") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "ASEAN");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_MyINFINITI"]}
                                      ratingCount={rating["rating_MyINFINITI"]}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                      {(brandParamValue == "All" || brandParamValue == "Nissan") && (
                        <>
                          <tr>
                            <td className={`${inActiveApps.includes("EV") ? "disabled" : ""}`}>
                              <ApplicationTypeTitle
                                title={"NissanConnect EV"}
                                applicationType={"EV"}
                                isSelected={selectedApplication === "EV"}
                                isInactive={inActiveApps.includes("EV")}
                                handleApplicationClick={onHandleApplicationSelection()}
                              />
                            </td>
                            <td
                              className={`${
                                inActiveApps.includes("EV") || (regionParamValue != ALL_OPTION && regionParamValue != "North America")
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <div>
                                {appRatingAvgData.find((rating: { region: string }) => rating.region == "North America") ? (
                                  (() => {
                                    const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "North America");
                                    return (
                                      <RatingsMarketBreakdown
                                        ratingValue={rating["rating_avg_NissanConnect EV"]}
                                        ratingCount={rating["rating_NissanConnect EV"]}
                                      />
                                    );
                                  })()
                                ) : (
                                  <p className="value is_na_text">n/a</p>
                                )}
                              </div>
                            </td>
                            <td
                              className={`${
                                inActiveApps.includes("EV") || (regionParamValue != ALL_OPTION && regionParamValue != "Japan")
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <div>
                                {appRatingAvgData.find((rating: { region: string }) => rating.region == "Japan") ? (
                                  (() => {
                                    const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "Japan");
                                    return (
                                      <RatingsMarketBreakdown
                                        ratingValue={rating["rating_avg_NissanConnect EV"]}
                                        ratingCount={rating["rating_NissanConnect EV"]}
                                      />
                                    );
                                  })()
                                ) : (
                                  <p className="value is_na_text">n/a</p>
                                )}
                              </div>
                            </td>
                            <td
                              className={`${
                                inActiveApps.includes("EV") || (regionParamValue != ALL_OPTION && regionParamValue != "Europe")
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <div>
                                {appRatingAvgData.find((rating: { region: string }) => rating.region == "Europe") ? (
                                  (() => {
                                    const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "Europe");
                                    return (
                                      <RatingsMarketBreakdown
                                        ratingValue={rating["rating_avg_NissanConnect EV"]}
                                        ratingCount={rating["rating_NissanConnect EV"]}
                                        isDownloads={true}
                                      />
                                    );
                                  })()
                                ) : (
                                  <p className="value is_na_text">n/a</p>
                                )}
                              </div>
                            </td>
                            <td
                              className={`${
                                inActiveApps.includes("EV") || (regionParamValue != ALL_OPTION && regionParamValue != "UAE")
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <div>
                                {appRatingAvgData.find((rating: { region: string }) => rating.region == "UAE") ? (
                                  (() => {
                                    const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "UAE");
                                    return (
                                      <RatingsMarketBreakdown
                                        ratingValue={rating["rating_avg_NissanConnect EV"]}
                                        ratingCount={rating["rating_NissanConnect EV"]}
                                      />
                                    );
                                  })()
                                ) : (
                                  <p className="value is_na_text">n/a</p>
                                )}
                              </div>
                            </td>
                            <td
                              className={`${
                                inActiveApps.includes("EV") || (regionParamValue != ALL_OPTION && regionParamValue != "ASEAN")
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <div>
                                {appRatingAvgData.find((rating: { region: string }) => rating.region == "ASEAN") ? (
                                  (() => {
                                    const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "ASEAN");
                                    return (
                                      <RatingsMarketBreakdown
                                        ratingValue={rating["rating_avg_NissanConnect EV"]}
                                        ratingCount={rating["rating_NissanConnect EV"]}
                                      />
                                    );
                                  })()
                                ) : (
                                  <p className="value is_na_text">n/a</p>
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className={`${inActiveApps.includes("Service") ? "disabled" : ""}`}>
                              <ApplicationTypeTitle
                                title={servicesTitle}
                                applicationType={"Service"}
                                borderStyle={"dotted"}
                                isSelected={selectedApplication === "Service"}
                                isInactive={inActiveApps.includes("Service")}
                                handleApplicationClick={onHandleApplicationSelection()}
                              />
                            </td>
                            <td
                              className={`${
                                inActiveApps.includes("Service") || (regionParamValue != ALL_OPTION && regionParamValue != "North America")
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <div>
                                {appRatingAvgData.find((rating: { region: string }) => rating.region == "North America") ? (
                                  (() => {
                                    const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "North America");
                                    return (
                                      <RatingsMarketBreakdown
                                        ratingValue={rating["rating_avg_NissanConnect Services"]}
                                        ratingCount={rating["rating_NissanConnect Services"]}
                                      />
                                    );
                                  })()
                                ) : (
                                  <p className="value is_na_text">n/a</p>
                                )}
                              </div>
                            </td>
                            <td
                              className={`${
                                inActiveApps.includes("Service") || (regionParamValue != ALL_OPTION && regionParamValue != "Japan")
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <div>
                                {appRatingAvgData.find((rating: { region: string }) => rating.region == "Japan") ? (
                                  (() => {
                                    const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "Japan");
                                    return (
                                      <RatingsMarketBreakdown
                                        ratingValue={rating["rating_avg_NissanConnect Services"]}
                                        ratingCount={rating["rating_NissanConnect Services"]}
                                      />
                                    );
                                  })()
                                ) : (
                                  <p className="value is_na_text">n/a</p>
                                )}
                              </div>
                            </td>
                            <td
                              className={`${
                                inActiveApps.includes("Service") || (regionParamValue != ALL_OPTION && regionParamValue != "Europe")
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <div>
                                {appRatingAvgData.find((rating: { region: string }) => rating.region == "Europe") ? (
                                  (() => {
                                    const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "Europe");
                                    return (
                                      <RatingsMarketBreakdown
                                        ratingValue={rating["rating_avg_NissanConnect Services"]}
                                        ratingCount={rating["rating_NissanConnect Services"]}
                                        isDownloads={true}
                                      />
                                    );
                                  })()
                                ) : (
                                  <p className="value is_na_text">n/a</p>
                                )}
                              </div>
                            </td>
                            <td
                              className={`${
                                inActiveApps.includes("Service") || (regionParamValue != ALL_OPTION && regionParamValue != "UAE")
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <div>
                                {appRatingAvgData.find((rating: { region: string }) => rating.region == "UAE") ? (
                                  (() => {
                                    const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "UAE");
                                    return (
                                      <RatingsMarketBreakdown
                                        ratingValue={rating["rating_avg_NissanConnect Services"]}
                                        ratingCount={rating["rating_NissanConnect Services"]}
                                      />
                                    );
                                  })()
                                ) : (
                                  <p className="value is_na_text">n/a</p>
                                )}
                              </div>
                            </td>
                            <td
                              className={`${
                                inActiveApps.includes("Service") || (regionParamValue != ALL_OPTION && regionParamValue != "ASEAN")
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <div>
                                {appRatingAvgData.find((rating: { region: string }) => rating.region == "ASEAN") ? (
                                  (() => {
                                    const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "ASEAN");
                                    return (
                                      <RatingsMarketBreakdown
                                        ratingValue={rating["rating_avg_NissanConnect Services"]}
                                        ratingCount={rating["rating_NissanConnect Services"]}
                                      />
                                    );
                                  })()
                                ) : (
                                  <p className="value is_na_text">n/a</p>
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                      {(brandParamValue == "All" || brandParamValue == "Infiniti") && (
                        <tr>
                          <td className={`${inActiveApps.includes("InTouch Services") ? "disabled" : ""}`}>
                            <ApplicationTypeTitle
                              title={"InTouch Services"}
                              applicationType={"InTouch Services"}
                              borderStyle={"dashed"}
                              isSelected={selectedApplication === "InTouch Services"}
                              isInactive={inActiveApps.includes("InTouch Services")}
                              handleApplicationClick={onHandleApplicationSelection()}
                            />
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("InTouch Services") ||
                              (regionParamValue != ALL_OPTION && regionParamValue != "North America")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "North America") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "North America");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_InTouch Services"]}
                                      ratingCount={rating["rating_InTouch Services"]}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("InTouch Services") || (regionParamValue != ALL_OPTION && regionParamValue != "Japan")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "Japan") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "Japan");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_InTouch Services"]}
                                      ratingCount={rating["rating_InTouch Services"]}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("InTouch Services") || (regionParamValue != ALL_OPTION && regionParamValue != "Europe")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "Europe") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "Europe");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_InTouch Services"]}
                                      ratingCount={rating["rating_InTouch Services"]}
                                      isDownloads={true}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("InTouch Services") || (regionParamValue != ALL_OPTION && regionParamValue != "UAE")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "UAE") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "UAE");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_InTouch Services"]}
                                      ratingCount={rating["rating_InTouch Services"]}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                          <td
                            className={`${
                              inActiveApps.includes("InTouch Services") || (regionParamValue != ALL_OPTION && regionParamValue != "ASEAN")
                                ? "disabled"
                                : ""
                            }`}
                          >
                            <div>
                              {appRatingAvgData.find((rating: { region: string }) => rating.region == "ASEAN") ? (
                                (() => {
                                  const rating = appRatingAvgData.find((rating: { region: string }) => rating.region == "ASEAN");
                                  return (
                                    <RatingsMarketBreakdown
                                      ratingValue={rating["rating_avg_InTouch Services"]}
                                      ratingCount={rating["rating_InTouch Services"]}
                                    />
                                  );
                                })()
                              ) : (
                                <p className="value is_na_text">n/a</p>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="ratings_tile_content chart_tile_content">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <div className="ratings_tile_header grey_info">App rating trend</div>
                  <AppRatingsSplitGeoLineGraph data={appRatingsChartData} series={appRatingsSeries} theme={themeContext.theme} />
                </ErrorBoundary>
              </div>

              <LoadingEllipsis isLoading={appRatingsLoading} />
            </div>

            <div className="ratings_tile app_reviews" id="app_reviews">
              <div className="app_review_charts">
                <div className="app_satisfaction_container">
                  <div className="ratings_tile_header grey_info">
                    <div className="title_tooltip">
                      Volume of reviews by app satisfaction level
                      <Tooltip
                        position="top-end"
                        className={"normal_tippy"}
                        trigger="mouseenter"
                        animation="none"
                        interactive={true}
                        distance={50}
                        delay={1}
                        hideDelay={1}
                        duration={1}
                        title={"1-2 - negative <br>3 - neutral <br>4-5 - positive"}
                        size={"small"}
                      >
                        <span className="info_tooltip" />
                      </Tooltip>
                    </div>
                  </div>
                  <ErrorBoundary fallback={<ErrorMsg />}>
                    <AppSatisfactionChart
                      data={appSatisfactionData}
                      chartName="app_satisfaction_chart"
                      theme={themeContext.theme}
                      isLoading={appSatisfactionLoading}
                    />
                  </ErrorBoundary>
                </div>
                <div className="reviews_by_topic_container">
                  <div className="ratings_tile_header grey_info">Volume of reviews by topic</div>
                  <ErrorBoundary fallback={<ErrorMsg />}>
                    <AppRatingsBarStackedChart
                      data={reviewsByTopicData}
                      chartName="reviews_by_topic_graph"
                      isLoading={reviewsByTopicLoading}
                      theme={themeContext.theme}
                      topics={topicsData}
                    />
                  </ErrorBoundary>
                </div>
              </div>

              <div className="app_review_comments">
                <div className="ratings_tile_header grey_info">
                  <p>App review comments</p>
                  <ErrorBoundary fallback={<ErrorMsg />}>
                    <TopicFilter
                      options={topicOptions}
                      paramValue={topicParamValue}
                      handleSubmit={onHandleSubmitTopicFilter}
                      filterValue={topicFilterValue}
                    />
                  </ErrorBoundary>
                </div>

                <div className="ratings_tile_content">
                  <div className="ratings_selector">
                    <RatingsPills
                      title={"Rating"}
                      values={[1, 2, 3, 4, 5]}
                      selectedValues={selectedRatings}
                      handlePillClick={onPillClick()}
                    />
                  </div>
                  <div className="review_language_toggle">
                    <ToggleSwitch
                      inactiveToggleLabel={"Original"}
                      activeToggleLabel={"English"}
                      active={!showOriginalLanguage}
                      handleToggleClick={() => setShowOriginalLanguage(!showOriginalLanguage)}
                      toggleTitle={"See comments in"}
                      toggleClassName="activation_form"
                    />
                  </div>

                  <div className="comments_table">
                    <ErrorBoundary fallback={<ErrorMsg />}>
                      <ReviewsTable showOriginalLanguage={showOriginalLanguage} data={tableData} />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>

              <LoadingEllipsis isLoading={allReviewsLoading} />
            </div>
          </Collapsible>

          <Collapsible myStyle="ccs_app_ratings" show={true} title="App performance">
            <div className="ratings_tile app_performance">
              <div className="ratings_tile_content chart_tile_content">
                <div className="ratings_tile_header grey_info">
                  <div className="title_tooltip">
                    Anonymized downloads
                    <Tooltip
                      position="top-end"
                      className={"normal_tippy"}
                      trigger="mouseenter"
                      animation="none"
                      interactive={true}
                      distance={50}
                      delay={1}
                      hideDelay={1}
                      duration={1}
                      html={
                        <div>
                          <p>Anonymized unique download volume from iOS and Google Play app stores among benchmarking apps share.</p>
                          <p>Source: Data.ai</p>
                        </div>
                      }
                      size={"small"}
                    >
                      <span className="info_tooltip" />
                    </Tooltip>
                  </div>
                </div>
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <AppPerformanceLineChart
                    chartId="appDownloadsChart"
                    data={downloadsChartData?.data ? downloadsChartData.data : []}
                    series={downloadsChartData?.seriesList ? downloadsChartData.seriesList : []}
                    isLoading={appDownloadsLoading}
                    theme={themeContext.theme}
                    showTooltip={true}
                  />
                </ErrorBoundary>
              </div>
              <div className="ratings_tile_content chart_tile_content">
                <div className="ratings_tile_header grey_info">
                  <div className="title_tooltip">
                    Open rate
                    <Tooltip
                      position="top-end"
                      className={"normal_tippy"}
                      trigger="mouseenter"
                      animation="none"
                      interactive={true}
                      distance={50}
                      delay={1}
                      hideDelay={1}
                      duration={1}
                      html={
                        <div>
                          <p>
                            Open rate is the percentage of devices, that have the app installed, which open the app at least once per month.
                          </p>
                          <p>US/Japan: Nissan data is Android data only.</p>
                          <p>EUR = UK: iOS＋Android</p>
                          <p>Source: Data.ai</p>
                        </div>
                      }
                      size={"small"}
                    >
                      <span className="info_tooltip" />
                    </Tooltip>
                  </div>
                </div>
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <AppPerformanceLineChart
                    chartId="appOpenRateChart"
                    data={openRateChartData?.data ? openRateChartData.data : []}
                    series={openRateChartData?.seriesList ? openRateChartData.seriesList : []}
                    theme={themeContext.theme}
                    isLoading={appOpenRateLoading}
                    isPercentage={true}
                    showTooltip={true}
                  />
                </ErrorBoundary>
              </div>
            </div>
          </Collapsible>

          <div className="container_footer hide_on_desktop">
            <Button variant={"outlined"} data-page={"/ccs_vital_signs/ccs_cockpit"} onClick={(evt) => onBtnLinkClick(evt)}>
              See CCS vital signs
            </Button>
            <Button variant={"outlined"} data-page={"/ccs_vital_signs/ccs_journey"} onClick={(evt) => onBtnLinkClick(evt)}>
              See CCS journey
            </Button>
          </div>
        </ErrorBoundary>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
