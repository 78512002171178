import { PieChart, PieSeries } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { SOV_MIX_COLORS, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { legendConfiguration } from "../helpers/configurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  theme: ThemeInt;
  chartId: string;
  totalAvg?: string | null;
  valueField: string;
  categoryField: string;
  isLoading: boolean;
  radius?: number;
  innerRadius?: number;
  legendPosition?: "right" | "left" | "bottom" | "top" | "absolute"
  tooltipValuesMapping?: Record<string, string>;
  tooltipValueField?: string;
  metric?: string;
}

class ExecutiveSummaryDonutChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: PieChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme, totalAvg, valueField, categoryField, radius, legendPosition, innerRadius, tooltipValuesMapping, tooltipValueField, metric } = this.props;
    this.chart = create(this.chartId, PieChart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.";
    this.chart.radius = radius ? radius : 60;

    this.chart.layout = "vertical";
    this.chart.innerRadius = percent(innerRadius ?? 25);

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.valueLabels.template.disabled = true;
    this.chart.legend.position = legendPosition ?? "right";
    this.chart.legend.itemContainers.template.paddingBottom = 10;
    // this.chart.legend.paddingRight = 150;
    //@ts-ignore
    this.chart.legend.maxWidth = undefined;
    this.chart.legend.labels.template.truncate = false;
    this.chart.legend.scrollable = true;
    // this.chart.legend.itemContainers.template.clickable = false;
    // this.chart.legend.itemContainers.template.focusable = false;

    const series = this.chart.series.push(new PieSeries());
    series.dataFields.value = valueField;
    series.dataFields.category = categoryField;
    series.ticks.template.disabled = true;
    series.labels.template.disabled = true;
    //@ts-ignore
    series.slices.template.states.getKey("active").properties.shiftRadius = 0;
    series.slices.template.propertyFields.fill = "color";
    series.slices.template.tooltipText = "{category}: {value}%";
    series.colors.list = SOV_MIX_COLORS.map((item: string) => color(item));
    series.slices.template.adapter.add("tooltipText", (value: any, target: any) => {
      const dataValue = target?.tooltipDataItem?.dataContext as Record<string, any>;
      let text = `${dataValue[categoryField]}\n`;
      if (tooltipValuesMapping) {
        Object.keys(tooltipValuesMapping).forEach((key) => {
          text += `${key}: ${dataValue[tooltipValuesMapping[key]]?.toLocaleString()}\n`;
        });
        return text
      } else {
        text += `Visits: ${dataValue.visits?.toLocaleString()}\n`;
        text += `Percentage of total visits: ${dataValue.visits_perc}%\n`;
        return text;
      }
    });

    if (series.tooltip) {
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = color(WHITE);
      series.tooltip.label.fontSize = 15;
    }
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default ExecutiveSummaryDonutChart;
