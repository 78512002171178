import { PieChart, PieSeries } from "@amcharts/amcharts4/charts";
import { color, create, Label, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { AMBER, LIME_GREEN, RED, SOV_MIX_COLORS, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { legendConfiguration } from "../helpers/configurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  theme: ThemeInt;
  chartId: string;
  totalAvg?: string | null;
  valueField: string;
  categoryField: string;
  isLoading: boolean;
  innerRadius?: number;
  hideSeriesLabels?: boolean;
  decimalRounding?: number
}

class VocDonutChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: PieChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme, totalAvg, valueField, categoryField, innerRadius, hideSeriesLabels, decimalRounding } = this.props;
    this.chart = create(this.chartId, PieChart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.";
    this.chart.radius = 110;

    this.chart.layout = "vertical";
    this.chart.innerRadius = innerRadius ? percent(innerRadius) : percent(40);
    this.chart.colors.list = SOV_MIX_COLORS.map(((item: string) => color(item)))

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.valueLabels.template.disabled = true;
    this.chart.legend.position = "left";
    this.chart.legend.itemContainers.template.paddingBottom = 10;
    this.chart.legend.maxWidth = 200;
    this.chart.legend.labels.template.truncate = false;
    this.chart.legend.labels.template.wrap = true;


    const series = this.chart.series.push(new PieSeries());
    series.numberFormatter.numberFormat = `#.${"0".repeat(decimalRounding ?? 1)}`
    series.dataFields.value = valueField;
    series.dataFields.category = categoryField;
    series.labels.template.text = "{value}%";
    series.labels.template.fill = color(WHITE);
    series.labels.template.fontSize = 15;
    series.ticks.template.disabled = true;
    series.alignLabels = false;
    series.labels.template.radius = percent(-25);
    series.labels.template.hidden = hideSeriesLabels ?? false
    series.slices.template.propertyFields.fill = "color";
    series.slices.template.tooltipText = "{category}: {value}%";
    series.colors.list = SOV_MIX_COLORS.map(((item: string) => color(item)))

    if (series.tooltip) {
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = color(WHITE);
      series.tooltip.label.fontSize = 15;
    }

    if (totalAvg != undefined) {
      const label = series.createChild(Label);
      label.text = totalAvg ? totalAvg : "n/a";
      label.adapter.add("fill", (value, target) => {
        if (target?.currentText == "n/a") {
          value = color(WHITE);
        } else if (Number(target?.currentText) >= 4) {
          value = color(LIME_GREEN);
        } else if (Number(target?.currentText) >= 3.9) {
          value = color(AMBER);
        } else {
          value = color(RED);
        }
        return value;
      });
      label.horizontalCenter = "middle";
      label.verticalCenter = "middle";
      label.fontSize = 40;
    }
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default VocDonutChart;
