import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../../context'
import ExecutiveSummaryDonutChart from '../../../../components/Charts/ExecutiveSummary/ExecutiveSummaryDonutChart'
import { InAppMultiSelectFilter } from '../../../../components/Filters/common/InAppMultiselectFilter'
import { closeFilterOptions } from '../../../../components/Filters/subs/helpers'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getExperienceRatings, getVerbatimSplit } from '../../../../api/executiveSummaryProductPerformanceDeepDive'

const VerbatimSplit = withRouter((props: RouteComponentProps) => {
  const { theme } = useContext(ThemeContext)
  const { history } = props;
  const [experienceRatingFilterValue, setExperienceRatingFilterValue] = useState("All");

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["verbatimSplitData", history.location.search, experienceRatingFilterValue],
    queryFn: () => getVerbatimSplit(experienceRatingFilterValue),
    enabled: false
  });

  const { data: experienceRatings, refetch: experienceRatingsRefetch } = useQuery({
    queryKey: ["experienceRatings"],
    queryFn: getExperienceRatings,
    enabled: false
  });

  useEffect(() => {
    refetch();
  }, [history.location.search, experienceRatingFilterValue])

  useEffect(() => {
    experienceRatingsRefetch();
  }, [history.location.search])

  const handleExperienceRatingMultiselectSubmit = useCallback(() => {
    const parameterName = "experience_rating";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = experienceRatings?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    setExperienceRatingFilterValue(filterValue);
  }, [experienceRatings]);

  return (
    <div className='tile'>
      <div className="tile-header">
        <div className='title-container'>
          Verbatim Split
        </div>
        <div className="filte-container">
          <InAppMultiSelectFilter
            filterLabel="Select Experience Rating"
            optionsList={experienceRatings?.experience_rating?.map((rating: number) => String(rating)) ?? []}
            filterValue={experienceRatingFilterValue}
            parameterName={"experience_rating"}
            parameterValue={experienceRatingFilterValue}
            handleFilterSubmission={handleExperienceRatingMultiselectSubmit}
          />
        </div>
      </div>
      <div className="tile-content">
        <ExecutiveSummaryDonutChart
          chartId='verbatimSpiltDonutChart'
          data={data}
          theme={theme}
          isLoading={isFetching}
          valueField='value'
          categoryField='topic'
          radius={80}
          tooltipValuesMapping={{ "Contribution % ": "value", "# of feedback": "volume" }}
        />
      </div>
    </div>
  )
})

export default VerbatimSplit