import React, { useEffect, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { getMarketFlagClass } from '../../../../utils/utilityFunctions'
import { getServiceLeadLeadsBreakdownByMarket } from '../../../../api/useOwnershipApi'
import { useQuery } from '@tanstack/react-query'
import { DefaultTable } from '../../../../components/Tables/subs'
import parse from 'date-fns/parse'
import { format } from 'date-fns'
import LoadingEllipsis from '../../../../components/Loading/LoadingEllipsis'

const ServiceLeadsTable = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["serviceLeadsBreakdownByMarket", history.location.search],
    queryFn: getServiceLeadLeadsBreakdownByMarket,
    enabled: false,
  });

  useEffect(() => {
    refetch()
  }, [history.location.search])

  const getPeriodMonths = (startDate: string, endDate: string) => {
    const startMonth = format(parse(startDate, "y-MM-dd", new Date()), "MMM")
    const endMonth = format(parse(endDate, "y-MM-dd", new Date()), "MMM")
    return `(${startMonth} - ${endMonth})`
  }

  const columns = useMemo(
    () => [
      {
        id: "marketFlag",
        Header: "",
        accessor: "market",
        disableSortBy: true,
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return <span className={`flagIcon ${getMarketFlagClass(value)}`}></span>
        }
      },
      {
        id: "market",
        Header: "Market/Region",
        accessor: "market",
        Footer: () => { return <div>Total</div> }
      },
      {
        id: "leads_yoy",
        Header: `Service Leads Previous Year`,
        accessor: "leads_yoy",
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return value?.toLocaleString() ?? "n/a"
        },
        Footer: () => {
          const rowData = data?.data?.filter((dataItem: any) => dataItem.market == "total")
          return <div>{rowData[0].leads_yoy?.toLocaleString()} leads</div>
        }
      },
      {
        id: "leads_yoy_selected_period",
        Header: `Service Leads Previous Year YTD`,
        accessor: "leads_yoy_selected_period",
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return value?.toLocaleString() ?? "n/a"
        },
        Footer: () => {
          const rowData = data?.data?.filter((dataItem: any) => dataItem.market == "total")
          return <div>{rowData[0].leads_yoy_selected_period?.toLocaleString()} leads</div>
        }
      },
      {
        id: "leads",
        Header: `Service Leads YTD`,
        accessor: "leads",
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return value?.toLocaleString() ?? "n/a"
        },
        Footer: () => {
          const rowData = data?.data?.filter((dataItem: any) => dataItem.market == "total")
          return (
            <>
              <div>{rowData[0].leads?.toLocaleString()} leads</div>
              <div className={`${data?.yoy > 0 ? "text-green" : data?.yoy < 0 ? "text-red" : ""}`}>{data?.yoy > 0 ? "+" : ""}{data?.yoy}% vs LY</div>
            </>
          )
        }
      },
    ],
    [data]
  );

  return (
    <div className='tile service-leads-tile'>
      {isFetching ?
        <LoadingEllipsis isLoading={isFetching} />
        :
        data?.data && (
          <DefaultTable
            columns={columns}
            data={data?.data?.filter((dataItem: any) => dataItem.market != "total") ?? []}
            id="serviceLeadsTable"
            hiddenCols={[]}
            isChildComponent={false}
            initialSortColId={{ id: "leads", desc: true }}
          />
        )
      }

    </div>

  )
})

export default ServiceLeadsTable