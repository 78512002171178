import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FilterContext } from '../../../context';
import { FiltersLayout } from '../../Layouts';
import { setParameters, setSpecificParameter } from '../../../actions';
import { ALL_OPTION_NO_SPACE, DEFAULT_EXECUTIVE_SUMMARY_PRODUCT_PERFORMANCE } from '../../../constants';
import { ErrorBoundary } from '@sentry/react';
import { ErrorMsg } from '../../AppMessages';
import PurchaseFunnelDateFilter from '../common/PurchaseFunnelDateFilter';
import { eventTracking, MixpanelEvents } from '../../../utils/userTracking';
import { closeFilterOptions, closeMarketOptions } from '../subs/helpers';
import { DefaultFilter, MultiSelectFilter } from '../common';
import { useQuery } from '@tanstack/react-query';
import { getExecutiveSummaryProductPerformanceDeepDiveFilters } from '../../../api';
import { currentDefaultFiltersSet, defaultFiltersSet } from '../../../utils/utilityFunctions';

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
}

const ExecutiveSummaryProductPerformanceDeepDiveFilters = withRouter((props: RouteComponentProps & Props) => {
  const { history, filterBtns } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const {
    date_range: dateRangeParamValue,
    market: marketParamValue,
    brand: brandParamValue,
    model: modelParamValue,
    product: productParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const [marketFilterValue, setMarketFilterValue] = useState(ALL_OPTION_NO_SPACE);
  const [productFilterValue, setProductFilterValue] = useState(productParamValue);
  const [modelFilterValue, setModelFilterValue] = useState(modelParamValue);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Executive Summary - Digitally Influenced Sales");
    if (setDefaultFilters) {
      dispatch(setParameters({ ...DEFAULT_EXECUTIVE_SUMMARY_PRODUCT_PERFORMANCE, market: ALL_OPTION_NO_SPACE }));
      currentDefaultFiltersSet("Executive Summary - Digitally Influenced Sales");
    }
  }, []);

  const { data, refetch } = useQuery({
    queryKey: ["executiveSummaryProductPerformanceDeepDiveFiltersData", history.location.search],
    queryFn: getExecutiveSummaryProductPerformanceDeepDiveFilters,
    initialData: {},
    enabled: false,
  });

  const { brand, market: markets, product: products, model: models } = data;

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  // Set market filter value
  useEffect(() => {
    if (markets && marketParamValue) {
      const selectedMarketsArr = marketParamValue.split(",");
      const marketSourceValue =
        selectedMarketsArr?.length === markets.length
          ? "All"
          : selectedMarketsArr?.length > 1
            ? `${selectedMarketsArr?.length} market selected`
            : marketParamValue;

      setMarketFilterValue(marketSourceValue);
    }
  }, [markets, marketParamValue]);

  // Set product filter value
  useEffect(() => {
    if (products && productParamValue) {
      const selectedProductsArr = productParamValue.split(",");
      const productSourceValue =
        selectedProductsArr?.length === products.length
          ? "All"
          : selectedProductsArr?.length > 1
            ? `${selectedProductsArr?.length} product selected`
            : productParamValue;

      setProductFilterValue(productSourceValue);
    }
  }, [products, productParamValue]);

  // Set model filter value
  useEffect(() => {
    if (models && modelParamValue) {
      const selectedModelsArr = modelParamValue.split(",");
      const modelSourceValue =
        selectedModelsArr?.length === models.length
          ? "All"
          : selectedModelsArr?.length > 1
            ? `${selectedModelsArr?.length} model selected`
            : modelParamValue;

      setModelFilterValue(modelSourceValue);
    }
  }, [models, modelParamValue]);

  const handleMarketFilterSubmission = useCallback(() => {
    const parameterName = "market";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === markets?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Executive Summary",
      page: "Product Performance - deep dive",
    });
    closeFilterOptions();
  }, [markets]);

  const handleProductFilterSubmission = useCallback(() => {
    const parameterName = "product";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === products?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Executive Summary",
      page: "Product Performance - deep dive",
    });
    closeFilterOptions();
  }, [products]);

  const handleModelFilterSubmission = useCallback(() => {
    const parameterName = "model";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === models?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Executive Summary",
      page: "Product Performance - deep dive",
    });
    closeFilterOptions();
  }, [models]);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));

      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "Executive Summary",
        page: "Product Performance - deep dive",
      });
    }
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  //reset filter button functionality
  const resetFilters = useCallback(() => {
    dispatch(setParameters(DEFAULT_EXECUTIVE_SUMMARY_PRODUCT_PERFORMANCE));
  }, []);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    dateRangeParamValue && params.set("date_range", dateRangeParamValue);
    brandParamValue && params.set("brand", brandParamValue);
    marketParamValue && params.set("market", marketParamValue);
    modelParamValue && params.set("model", modelParamValue);
    productParamValue && params.set("product", productParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, brandParamValue, marketParamValue, modelParamValue, productParamValue]);

  return (
    <FiltersLayout extraClass={"iframeFilters-midas"} resetFilters={resetFilters} filterBtns={filterBtns}>
      <div className="filte">
        {/*Date range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <PurchaseFunnelDateFilter
            value={dateRangeParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
            onFilterClick={showFilterOptionsFn}
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            filterName="brand"
            filterLabel="BRAND"
            filterValue={brandParamValue}
            list={brand ?? []}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/* MARKET */}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="market"
            filterName={"MARKET"}
            // @ts-ignore
            filterList={markets ?? []}
            value={marketFilterValue}
            parameterName={"market"}
            parameterValue={marketParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleMarketFilterSubmission}
          />
        </ErrorBoundary>

        {/* MODEL */}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="model"
            filterName={"MODEL"}
            // @ts-ignore
            filterList={models ?? []}
            value={modelFilterValue}
            parameterName={"model"}
            parameterValue={modelParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleModelFilterSubmission}
          />
        </ErrorBoundary>

        {/* Product */}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="product"
            filterName={"PRODUCT"}
            // @ts-ignore
            filterList={products ?? []}
            value={productFilterValue}
            parameterName={"product"}
            parameterValue={productParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleProductFilterSubmission}
          />
        </ErrorBoundary>
      </div>
    </FiltersLayout>
  )
})

export default ExecutiveSummaryProductPerformanceDeepDiveFilters