import { CategoryAxis, ColumnSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { MARKETING_ACTIVITY_COLORS } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { columnSeriesConfiguration, configureCategoryAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  seriesList: Array<{ name: string; field: string; }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
  categoryField: string;
}

class StackedBarChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme, categoryField } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#a";

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.position = "bottom";

    // Create Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    categoryAxis.dataFields.category = categoryField;
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.maxWidth = 150;
    categoryAxis.renderer.labels.template.wrap = true;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.minGridDistance = 20;

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList?.forEach(({ name, field }, idx) => {
      const series = this.chart.series.push(new ColumnSeries());
      columnSeriesConfiguration(series, {
        name: name,
        valueY: field,
      });
      series.dataFields.categoryX = categoryField;
      series.stacked = true;
      series.fill = color(MARKETING_ACTIVITY_COLORS[idx]);
      series.stroke = color(MARKETING_ACTIVITY_COLORS[idx]);
      series.columns.template.tooltipText = "[bold font-size: var(--regular_font_size)]{name}: {valueY.formatNumber('###,###')}";
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default StackedBarChart;