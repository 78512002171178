import { ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import { connect, RootStateOrAny } from "react-redux";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { CCS_ANALYSIS_COLORS, CYAN, HUMMING_BIRD, MEDIA_MIX_CHART_COLORS, ORANGE, TOP_FIVE_MODEL_EXTRA_COLOR } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  seriesList: Array<{ name: string; field: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
  isRating: boolean;
}

const barSeriesColors = [CYAN];

class SummaryBarChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme, isRating } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.0a";

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme, true);
    dateAxis.renderer.minGridDistance = 10;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;

    if (isRating) {
      valueAxis.max = 5;
    }

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList?.forEach(({ name, field }, idx) => {
      const series = this.chart.series.push(new ColumnSeries());
      series.dataFields.dateX = "date";
      series.name = name;
      series.dataFields.valueY = field;
      series.xAxis = dateAxis;
      series.numberFormatter = new NumberFormatter();
      series.numberFormatter.numberFormat = isRating ? "#.00" : "###,###"
      series.columns.template.tooltipText = "[bold font-size: var(--regular_font_size)]{name}: {valueY}";
      series.fill = color(barSeriesColors[idx]);
      series.stroke = color(barSeriesColors[idx]);
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default SummaryBarChart;
