import React, { useContext } from 'react'
import QuickVocLineChart from '../../../../components/Charts/QuickVocSales/QuickVocSalesLineChart';
import { SOV_MIX_COLORS } from '../../../../constants';
import { NoDataMsg } from '../../../../components/AppMessages';
import { ThemeContext } from '../../../../context';

interface Props {
  chartId: string;
  data: any;
  seriesList: any;
  isLoading: boolean;
}

const DeepDiveOvertimePerformanceTrend = (props: Props) => {
  const { theme } = useContext(ThemeContext);

  const { chartId, data, seriesList, isLoading } = props;

  return data?.length > 0 ? (
    <QuickVocLineChart
      chartId={chartId}
      data={data ?? []}
      isLoading={isLoading}
      seriesList={seriesList ?? []}
      theme={theme}
      seriesColors={SOV_MIX_COLORS}
      minGridDistance={30}
    />
  ) : (
    <NoDataMsg />
  );
}

export default DeepDiveOvertimePerformanceTrend