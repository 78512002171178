import {
  AppReviewInt,
  CCSBusinessRevenueChartData,
  CCSBusinessRevenueData,
  IReviews,
  VOCCallCentreInt,
} from "../constants/interface/ccsCockpit";
import { IActivationOverallData } from "../constants/interface/europeActivation/activationData";
import {
  FETCH_ALL_REVIEWS,
  FETCH_APP_ANALYSIS_FEATURE_USAGE_DATA,
  FETCH_APP_ANALYSIS_WEB_USAGE_DATA,
  FETCH_APP_OPEN_RATE_DATA,
  FETCH_APP_RATINGS,
  FETCH_APP_RATINGS_AVG_DATA,
  FETCH_APP_RATINGS_BY_MARKET,
  FETCH_APP_RATINGS_BY_MARKET_CHART_DATA,
  FETCH_APP_RATINGS_CHART_DATA,
  FETCH_APP_RATINGS_DOWNLOADS_DATA,
  FETCH_APP_REVIEW_BREAKDOWN,
  FETCH_APP_REVIEW_BY_TOPIC,
  FETCH_APP_SATISFACTION_DATA,
  FETCH_BUSINESS_REVENUE_CHART_DATA,
  FETCH_BUSINESS_REVENUE_DATA,
  FETCH_CCS_JOURNEY_BANNER_DATA,
  FETCH_CCS_JOURNEY_DATA,
  FETCH_CONNECTED_CARS_CHART_DATA,
  FETCH_CONNECTED_CARS_DATA,
  FETCH_ENGAGEMENT_APP_DOWNLOADS_CHART_DATA,
  FETCH_ENGAGEMENT_APP_DOWNLOADS_DATA,
  FETCH_ENROLLED_CUSTOMERS_CHART_DATA,
  FETCH_ENROLLED_CUSTOMERS_DATA,
  FETCH_EUROPE_APP_RATINGS_AVG_DATA,
  FETCH_EUROPE_APP_RATINGS_AVG_TOTAL_DATA,
  FETCH_EUROPE_APP_RATINGS_TREND_DATA,
  FETCH_G8_PRIVATE_ACTIVATION_RATIO_CHART_DATA,
  FETCH_G8_PRIVATE_ACTIVATION_RATIO_DATA,
  FETCH_RENEWAL_CHURN_RATE,
  FETCH_RENEWAL_CHURN_RATE_CHART_DATA,
  FETCH_SALES_REVENUE_CHART_DATA,
  FETCH_SALES_REVENUE_DATA,
  FETCH_SALES_REVENUE_SOURCE,
  FETCH_SUBSCRIPTION_SALES_CHART_DATA,
  FETCH_SUBSCRIPTION_SALES_DATA,
  FETCH_TOP_USED_SERVICES,
  FETCH_USER_ENGAGEMENT,
  FETCH_USER_ENGAGEMENT_CHART_DATA,
  FETCH_VOC_CALL_CENTRE,
  FETCH_VOC_CALL_CENTRE_CHART_DATA,
  FETCH_WARRANTY_START_DATE,
  SET_ALL_REVIEWS,
  SET_APP_ANALYSIS_FEATURE_USAGE_DATA,
  SET_APP_ANALYSIS_WEB_USAGE_DATA,
  SET_APP_OPEN_RATE_DATA,
  SET_APP_RATINGS,
  SET_APP_RATINGS_AVG_DATA,
  SET_APP_RATINGS_BY_MARKET,
  SET_APP_RATINGS_BY_MARKET_CHART_DATA,
  SET_APP_RATINGS_CHART_DATA,
  SET_APP_RATINGS_DOWNLOADS_DATA,
  SET_APP_REVIEW_BREAKDOWN,
  SET_APP_REVIEW_BY_TOPIC,
  SET_APP_SATISFACTION_DATA,
  SET_BUSINESS_REVENUE_CHART_DATA,
  SET_BUSINESS_REVENUE_DATA,
  SET_CCS_JOURNEY_BANNER_DATA,
  SET_CCS_JOURNEY_DATA,
  SET_CONNECTED_CARS_CHART_DATA,
  SET_CONNECTED_CARS_DATA,
  SET_ENGAGEMENT_APP_DOWNLOADS_CHART_DATA,
  SET_ENGAGEMENT_APP_DOWNLOADS_DATA,
  SET_ENROLLED_CUSTOMERS_CHART_DATA,
  SET_ENROLLED_CUSTOMERS_DATA,
  SET_EUROPE_APP_RATINGS_AVG_DATA,
  SET_EUROPE_APP_RATINGS_AVG_TOTAL_DATA,
  SET_EUROPE_APP_RATINGS_TREND_DATA,
  SET_G8_PRIVATE_ACTIVATION_RATIO_CHART_DATA,
  SET_G8_PRIVATE_ACTIVATION_RATIO_DATA,
  SET_RENEWAL_CHURN_RATE,
  SET_RENEWAL_CHURN_RATE_CHART_DATA,
  SET_SALES_REVENUE_CHART_DATA,
  SET_SALES_REVENUE_DATA,
  SET_SALES_REVENUE_SOURCE,
  SET_SUBSCRIPTION_SALES_CHART_DATA,
  SET_SUBSCRIPTION_SALES_DATA,
  SET_TOP_USED_SERVICES,
  SET_USER_ENGAGEMENT,
  SET_USER_ENGAGEMENT_CHART_DATA,
  SET_VOC_CALL_CENTRE,
  SET_VOC_CALL_CENTRE_CHART_DATA,
  SET_WARRANTY_START_DATE,
} from "./actionTypes";

export const fetchUserEngagementData = (markets: string) => ({ type: FETCH_USER_ENGAGEMENT, payload: markets });
export const setUserEngagementData = (data: any) => ({ type: SET_USER_ENGAGEMENT, payload: data });

export const fetchUserEngagementChartData = (markets: string) => ({ type: FETCH_USER_ENGAGEMENT_CHART_DATA, payload: markets });
export const setUserEngagementChartData = (data: any) => ({ type: SET_USER_ENGAGEMENT_CHART_DATA, payload: data });

export const fetchEnrolledCustomersData = (markets: string) => ({ type: FETCH_ENROLLED_CUSTOMERS_DATA, payload: markets });
export const setEnrolledCustomersData = (data: any) => ({ type: SET_ENROLLED_CUSTOMERS_DATA, payload: data });

export const fetchEnrolledCustomersChartData = (markets: string) => ({ type: FETCH_ENROLLED_CUSTOMERS_CHART_DATA, payload: markets });
export const setEnrolledCustomersChartData = (data: any) => ({ type: SET_ENROLLED_CUSTOMERS_CHART_DATA, payload: data });

export const fetchBusinessRevenueData = (markets: string) => ({ type: FETCH_BUSINESS_REVENUE_DATA, payload: markets });
export const setBusinessRevenueData = (data: CCSBusinessRevenueData) => ({ type: SET_BUSINESS_REVENUE_DATA, payload: data });

export const fetchBusinessRevenueChartData = (markets: string) => ({ type: FETCH_BUSINESS_REVENUE_CHART_DATA, payload: markets });
export const setBusinessRevenueChartData = (data: CCSBusinessRevenueChartData) => ({
  type: SET_BUSINESS_REVENUE_CHART_DATA,
  payload: data,
});

export const fetchSubscriptionSalesData = () => ({ type: FETCH_SUBSCRIPTION_SALES_DATA });
export const setSubscriptionSalesData = (data: CCSBusinessRevenueData) => ({ type: SET_SUBSCRIPTION_SALES_DATA, payload: data });

export const fetchSubscriptionSalesChartData = (breakdown?: boolean) => ({ type: FETCH_SUBSCRIPTION_SALES_CHART_DATA, payload: breakdown });
export const setSubscriptionSalesChartData = (data: CCSBusinessRevenueChartData) => ({
  type: SET_SUBSCRIPTION_SALES_CHART_DATA,
  payload: data,
});

export const fetchConnectedCarsData = () => ({ type: FETCH_CONNECTED_CARS_DATA });
export const setConnectedCarsData = (data: CCSBusinessRevenueData) => ({ type: SET_CONNECTED_CARS_DATA, payload: data });

export const fetchConnectedCarsChartData = (breakdown?: boolean) => ({ type: FETCH_CONNECTED_CARS_CHART_DATA, payload: breakdown });
export const setConnectedCarsChartData = (data: CCSBusinessRevenueChartData) => ({
  type: SET_CONNECTED_CARS_CHART_DATA,
  payload: data,
});

export const fetchTopUsedServices = () => ({ type: FETCH_TOP_USED_SERVICES });
export const setTopUsedServices = (data: any) => ({ type: SET_TOP_USED_SERVICES, payload: data });

export const fetchAppRatings = (app_store = "") => ({ type: FETCH_APP_RATINGS, payload: app_store });
export const setAppRatings = (data: any) => ({ type: SET_APP_RATINGS, payload: data });

export const fetchAppRatingsByMarket = (app_store = "") => ({ type: FETCH_APP_RATINGS_BY_MARKET, payload: app_store });
export const setAppRatingsByMarket = (data: any) => ({ type: SET_APP_RATINGS_BY_MARKET, payload: data });

export const fetchAppRatingsByMarketChartData = (app_store = "") => ({ type: FETCH_APP_RATINGS_BY_MARKET_CHART_DATA, payload: app_store });
export const setAppRatingsByMarketChartData = (data: any) => ({ type: SET_APP_RATINGS_BY_MARKET_CHART_DATA, payload: data });

export const fetchAppRatingsChartData = (app_store = "") => ({
  type: FETCH_APP_RATINGS_CHART_DATA,
  payload: app_store,
});
export const setAppRatingsChartData = (data: any) => ({ type: SET_APP_RATINGS_CHART_DATA, payload: data });

export const fetchReviewsByTopic = () => ({ type: FETCH_APP_REVIEW_BY_TOPIC });
export const setReviewsByTopic = (data: any) => ({ type: SET_APP_REVIEW_BY_TOPIC, payload: data });

export const fetchAppReviewBreakdown = (app_store = "") => {
  return { type: FETCH_APP_REVIEW_BREAKDOWN, payload: app_store };
};
export const setAppReviewBreakdown = (data: AppReviewInt) => ({ type: SET_APP_REVIEW_BREAKDOWN, payload: data });

export const fetchVOCCallCentreData = () => ({ type: FETCH_VOC_CALL_CENTRE });
export const setVOCCallCentreData = (data: VOCCallCentreInt) => ({ type: SET_VOC_CALL_CENTRE, payload: data });

export const fetchVOCCallCentreChartData = (breakdown?: boolean) => ({ type: FETCH_VOC_CALL_CENTRE_CHART_DATA, payload: breakdown });
export const setVOCCallCentreChartData = (data: VOCCallCentreInt) => ({ type: SET_VOC_CALL_CENTRE_CHART_DATA, payload: data });

export const fetchRenewalChurnRateData = () => ({ type: FETCH_RENEWAL_CHURN_RATE });
export const setRenewalChurnRateData = (data: any) => ({ type: SET_RENEWAL_CHURN_RATE, payload: data });

export const fetchRenewalChurnRateChartData = () => ({ type: FETCH_RENEWAL_CHURN_RATE_CHART_DATA });
export const setRenewalChurnRateChartData = (data: any) => ({ type: SET_RENEWAL_CHURN_RATE_CHART_DATA, payload: data });

export const fetchAllReviewsData = (appStore = "") => ({ type: FETCH_ALL_REVIEWS, payload: appStore });
export const setAllReviewsData = (data: IReviews) => ({ type: SET_ALL_REVIEWS, payload: data });

export const fetchWarrantyStartDateData = () => ({ type: FETCH_WARRANTY_START_DATE });
export const setWarrantyStartDateData = (data: IActivationOverallData) => ({ type: SET_WARRANTY_START_DATE, payload: data });

export const fetchAppSatisfactionData = () => ({ type: FETCH_APP_SATISFACTION_DATA });
export const setAppSatisfactionData = (data: any) => ({ type: SET_APP_SATISFACTION_DATA, payload: data });

export const fetchAppAnalysisFeatureUsageData = () => ({ type: FETCH_APP_ANALYSIS_FEATURE_USAGE_DATA });
export const setAppAnalysisFeatureUsageData = (data: any) => ({ type: SET_APP_ANALYSIS_FEATURE_USAGE_DATA, payload: data });

export const fetchAppAnalysisWebUsage = () => ({ type: FETCH_APP_ANALYSIS_WEB_USAGE_DATA });
export const setAppAnalysisWebUsageData = (data: any) => ({ type: SET_APP_ANALYSIS_WEB_USAGE_DATA, payload: data });

export const fetchAppRatingsDownloadsData = () => ({ type: FETCH_APP_RATINGS_DOWNLOADS_DATA });
export const setAppRatingsDownloadsData = (data: any) => ({ type: SET_APP_RATINGS_DOWNLOADS_DATA, payload: data });

export const fetchEngagementAppDownloadsData = () => ({ type: FETCH_ENGAGEMENT_APP_DOWNLOADS_DATA });
export const setEngagementAppDownloadsData = (data: any) => ({ type: SET_ENGAGEMENT_APP_DOWNLOADS_DATA, payload: data });

export const fetchEngagementAppDownloadsChartData = () => ({ type: FETCH_ENGAGEMENT_APP_DOWNLOADS_CHART_DATA });
export const setEngagementAppDownloadsChartData = (data: any) => ({ type: SET_ENGAGEMENT_APP_DOWNLOADS_CHART_DATA, payload: data });

export const fetchAppOpenRateData = () => ({ type: FETCH_APP_OPEN_RATE_DATA });
export const setAppOpenRateData = (data: any) => ({ type: SET_APP_OPEN_RATE_DATA, payload: data });

export const fetchCcsJourneyData = () => ({ type: FETCH_CCS_JOURNEY_DATA });
export const setCcsJourneyData = (data: any) => ({ type: SET_CCS_JOURNEY_DATA, payload: data });

export const fetchG8PrivateActivationRatioData = () => ({ type: FETCH_G8_PRIVATE_ACTIVATION_RATIO_DATA });
export const setG8PrivateActivationRatioData = (data: any) => ({ type: SET_G8_PRIVATE_ACTIVATION_RATIO_DATA, payload: data });

export const fetchG8PrivateActivationRatioChartData = () => ({ type: FETCH_G8_PRIVATE_ACTIVATION_RATIO_CHART_DATA });
export const setG8PrivateActivationRatioChartData = (data: any) => ({ type: SET_G8_PRIVATE_ACTIVATION_RATIO_CHART_DATA, payload: data });
export const fetchEuropeAppRatingsAvgData = () => ({ type: FETCH_EUROPE_APP_RATINGS_AVG_DATA });
export const setEuropeAppRatingsAvgData = (data: any) => ({ type: SET_EUROPE_APP_RATINGS_AVG_DATA, payload: data });

export const fetchEuropeAppRatingsAvgTotalData = (markets?: Array<string>) => ({
  type: FETCH_EUROPE_APP_RATINGS_AVG_TOTAL_DATA,
  payload: markets,
});
export const setEuropeAppRatingsAvgTotalData = (data: any) => ({ type: SET_EUROPE_APP_RATINGS_AVG_TOTAL_DATA, payload: data });

export const fetchEuropeAppRatingsTrendData = (markets?: Array<string>) => ({
  type: FETCH_EUROPE_APP_RATINGS_TREND_DATA,
  payload: markets,
});
export const setEuropeAppRatingsTrendData = (data: any) => ({ type: SET_EUROPE_APP_RATINGS_TREND_DATA, payload: data });

export const fetchAppRatingsAvgData = () => ({ type: FETCH_APP_RATINGS_AVG_DATA });
export const setAppRatingsAvgData = (data: any) => ({ type: SET_APP_RATINGS_AVG_DATA, payload: data });

export const fetchCcsJourneyBannerData = () => ({ type: FETCH_CCS_JOURNEY_BANNER_DATA });
export const setCcsJourneyBannerData = (data: any) => ({ type: SET_CCS_JOURNEY_BANNER_DATA, payload: data });

export const fetchSalesRevenueSource = () => ({ type: FETCH_SALES_REVENUE_SOURCE });
export const setSalesRevenueSource = (data: any) => ({ type: SET_SALES_REVENUE_SOURCE, payload: data });

export const fetchSalesRevenueData = () => ({ type: FETCH_SALES_REVENUE_DATA });
export const setSalesRevenueData = (data: any) => ({ type: SET_SALES_REVENUE_DATA, payload: data });

export const fetchSalesRevenueChartData = () => ({ type: FETCH_SALES_REVENUE_CHART_DATA });
export const setSalesRevenueChartData = (data: any) => ({ type: SET_SALES_REVENUE_CHART_DATA, payload: data });
