import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link, RouteComponentProps } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { fetchActivationRatio } from "../../../../actions/activationRatioActions";
import { EuropeActivationChart } from "../../../../components/Charts/CCSCockpit/EuropeActivationChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { ToggleSwitch } from "../../../../components/Toogle";
import { DashboardBannerContext, ThemeContext } from "../../../../context";
import { ActivationOverviewContent } from "../../EuropeActivation/subs";

interface ActivationTileProps extends RouteComponentProps {
  isOperational?: boolean;
  showMetric?: boolean;
  onClick?: () => void;
}

export const ActivationTile = withRouter((props: ActivationTileProps) => {
  const { history, isOperational, showMetric, onClick: handleClick } = props;
  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();

  const {
    region: regionParamValue,
    date_range: dateRangeParamValue,
    market: marketParamValue,
    brand: brandParamValue,
    model: modelParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);
  const { cockpit_activation: cockpitActivationLoading } = useSelector((state: RootStateOrAny) => state.loading);

  const { bannerHovered } = useContext(DashboardBannerContext);

  const [showCumulativeData, setShowCumulativeData] = useState(false);
  const [title, setTitle] = useState("G8 private activation ratio");

  const { overall } = useSelector((state: RootStateOrAny) => state.europe_activation.overall_data);

  const { breakdown, total } = useSelector((state: RootStateOrAny) => state.activation_ratio.activation_ratio);

  const chartData = useMemo(() => {
    if (breakdown) {
      return breakdown.map((item: any) => {
        const totalKey = regionParamValue == "UAE" || regionParamValue == "ASEAN" ? "car_sold" : "warranty_start";
        return {
          date: item.date,
          rate: showCumulativeData ? item.cumulative_activation_ratio : item.activation_ratio,
          volume: showCumulativeData ? item.cumulative_service_activated : item.service_activated,
          total: showCumulativeData ? item[`cumulative_${totalKey}`] : item[totalKey],
          bp_g8_private_activation_ratio: item.bp_target,
        };
      });
    }
    return [];
  }, [breakdown, showCumulativeData]);

  useEffect(() => {
    if (regionParamValue == "UAE") {
      setTitle("UAE private activation ratio");
    } else if (regionParamValue == "ASEAN") {
      setTitle("ASEAN private activation ratio");
    } else {
      setTitle("G8 private activation ratio");
    }
  }, [regionParamValue]);

  useEffect(() => {
    if (regionParamValue === "Europe" || regionParamValue === "UAE" || regionParamValue === "ASEAN" || regionParamValue === "All") {
      setTimeout(() => {
        dispatch(fetchActivationRatio());
      }, 500);
    }
  }, [history.location.search, regionParamValue, showCumulativeData]);

  return (
    <div className={`tile ${bannerHovered ? "isBlue" : ""}`} id={"akv_activation_ratio"} onClick={handleClick}>
      <Link
        className="dashboard-link"
        to={`${regionParamValue == "UAE"
            ? "/ccs_journey/uae_activation"
            : regionParamValue == "ASEAN"
              ? "/ccs_journey/asean_activation"
              : "/ccs_journey/europe_activation"
          }`}
      >
        <ArrowCircleRightOutlinedIcon style={{ color: "#00a0dd" }} />
      </Link>

      <div className={`${showMetric ? "isMetric" : ""} ${isOperational ? "isOperational" : ""}`}>
        <div className="tile_header">
          {title}{" "}
          {regionParamValue != "UAE" && (
            <Tooltip
              position="top"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              title={"The last 3 months data represents the activation ratio based on the latest report available and is likely to change"}
              size={"small"}
            >
              <span className="info_tooltip" />
            </Tooltip>
          )}
        </div>

        <div className="warranty_start">
          {ActivationOverviewContent({
            rate: total ? total.activation_ratio : "n/a",
            volume: total ? total?.service_activated : "n/a",
            warrantyStart: overall && overall[0]?.total_warranty_start ? overall[0].total_warranty_start : "n/a",
            cumulative: showCumulativeData,
            bpG8ActivationTarget:
              overall && overall[0]?.bp_g8_private_activation_ratio_total ? overall[0].bp_g8_private_activation_ratio : "n/a",
            isOperational,
          })}
        </div>
        {(isOperational || showMetric) && (
          <>
            <div className="chart_area">
              <ToggleSwitch
                inactiveToggleLabel={"Cumulative"}
                activeToggleLabel={"Non-cumulative"}
                active={!showCumulativeData}
                handleToggleClick={() => setShowCumulativeData(!showCumulativeData)}
                toggleTitle={"See chart data: "}
                toggleClassName="activation_form"
              />

              <EuropeActivationChart
                data={chartData}
                isCumulative={{ cumulative: showCumulativeData, startDate: dateRangeParamValue?.split(",")[0] }}
                legendTop={true}
                theme={themeContext.theme}
                isActivationPage={false}
                region={regionParamValue}
                isUae={regionParamValue == "UAE"}
                isASEAN={regionParamValue == "ASEAN"}
              />
            </div>
          </>
        )}
      </div>
      <LoadingEllipsis isLoading={cockpitActivationLoading} />
    </div>
  );
});
