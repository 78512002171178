import React, { Fragment, useCallback } from "react";
import { useDispatch } from "react-redux";
import { MultiSelectFilter } from ".";
import { setSpecificParameter } from "../../../actions";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { closeFilterOptions } from "../subs/helpers";

interface Props {
  filterValue: string;
  marketsList: string[];
  showFilterOptions: (evt: React.MouseEvent<HTMLElement>) => void;
  marketParamValue: string;
  marketGroupParamValue: string;
  marketGroupsList?: string[];
}

export const EuropeActivationMarketFilter = (props: Props) => {
  const dispatch = useDispatch();

  const { filterValue, marketsList, showFilterOptions, marketGroupsList, marketParamValue } = props;
  const handleMarketMultiselectSubmit = useCallback(() => {
    const parameterName = "market";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = marketsList?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, filterValue));

    eventTracking(MixpanelEvents.filter_change, { filter: "date_range", value: filterValue, dashboard: "CCS", page: "Europe activation" });
    dispatch(setSpecificParameter("market_group", ""));
  }, []);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;
    closeFilterOptions();
    if (filterName && optionValue) {
      dispatch(setSpecificParameter("market_group", optionValue));
      eventTracking(MixpanelEvents.filter_change, {
        filter: "market_group",
        value: optionValue,
        dashboard: "CCS",
        page: "Europe activation",
      });
      dispatch(setSpecificParameter("market", ""));
    }
  }, []);

  return (
    <div className={"filter"} id="market_filter_div">
      <label className="filter_header" htmlFor="market_filter">
        GEOGRAPHY
      </label>
      <div className="input_arrow" onClick={showFilterOptions} data-options-ul={"market_ul"}>
        <input readOnly type="text" name="market" id="market_filter" className={"filter_input"} data-test-id="market" value={filterValue} />
        <span className="arrow_down " />
      </div>
      <ul className="filter_options" id="market_ul" data-test-id="market_list">
        {marketsList?.length > 0 && (
          <Fragment>
            {marketGroupsList &&
              marketGroupsList?.map((marketGroup) => {
                return (
                  <li data-filter={"market_group"} data-value={marketGroup} onClick={handleFilterOptionClick} key={marketGroup}>
                    {marketGroup}
                  </li>
                );
              })}
            <li className={"filter_divider market_group_divider"} />
            <MultiSelectFilter
              parentKey="market"
              showOptionsOnly={true}
              // @ts-ignore
              filterList={marketsList}
              value={filterValue}
              parameterName={"market"}
              parameterValue={marketParamValue.includes("All") ? marketsList?.join(",") : marketParamValue}
              onShowFilterOptions={showFilterOptions}
              handleFilterSubmission={handleMarketMultiselectSubmit}
            />
          </Fragment>
        )}
      </ul>
    </div>
  );
};
