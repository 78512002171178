import AddLinkRoundedIcon from "@mui/icons-material/AddLinkRounded";
import AdsClickRoundedIcon from "@mui/icons-material/AdsClickRounded";
import ChecklistRtlRoundedIcon from "@mui/icons-material/ChecklistRtlRounded";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import TocRoundedIcon from "@mui/icons-material/TocRounded";
import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { setPageName } from "../../../actions";
import {
  fetchOnlineAccAndEngAccountCreationAdjusted,
  fetchOnlineAccAndEngAccountCreationTrend,
  fetchOnlineAccAndEngActiveUsers,
  fetchOnlineAccAndEngAvgVocRating,
  fetchOnlineAccAndEngCompleteAccountCreation,
  fetchOnlineAccAndEngEngagementVsActiveUsers,
  fetchOnlineAccAndEngEnrolledUsersLinked,
  fetchOnlineAccAndEngEnrolledUsersTrend,
  fetchOnlineAccAndEngFirstEngagement,
  fetchOnlineAccAndEngLinkedCars,
  fetchOnlineAccAndEngPrivateSales,
  fetchOnlineAccAndEngStartAccountCreation,
  fetchOnlineAccAndEngVehiclesAdded,
} from "../../../actions/useOwnershipTypes";
import LineTrendChart from "../../../components/Charts/UseOwnership/LineTrendChart";
import MultipleBarLineTrendChart from "../../../components/Charts/UseOwnership/MultipleBarLineTrendChart";
import UseOwnershipFilters from "../../../components/Filters/UseOwnershipFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { ToggleSwitch } from "../../../components/Toogle";
import { CYAN, GREEN, INDIGO, PURPLE } from "../../../constants";
import { USE_OWNERSHIP_BTN_FILTERS } from "../../../constants/useOwnershipConstants";
import { DashboardBannerContext, ThemeContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import MetricTileWithDonutChart from "./components/MetricTileWithDonutChart";
import MetricTileWithIcon from "./components/MetricTileWithIcon";
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';
import MetricTile from "../components/MetricTile";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { useQuery } from "@tanstack/react-query";
import { getOnlineAccAndEngUsersClickedAppDownload, getOnlineAccAndEngUsersClickedAppDownloadTrend, getOnlineAccAndEngUsersClickedViewOrderDetails, getOnlineAccAndEngUsersClickedViewOrderDetailsTrend } from "../../../api/useOwnershipApi";
import CarRentalIcon from '@mui/icons-material/CarRental';

export const OnlineAccountAndEngagement = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.use_ownership_last_data_refresh);
  const { setShowBanner, setBannerText } = useContext(DashboardBannerContext);

  const {
    online_acc_and_eng_private_sales: privateSales,
    online_acc_and_eng_start_account_creation: startAccCreation,
    online_acc_and_eng_complete_account_creation: completeAccCreation,
    online_acc_and_eng_first_engagement: firstEngagement,
    online_acc_and_eng_active_users: activeUsers,
    online_acc_and_eng_linked_cars: linkedCarsTrend,
    online_acc_and_eng_enrolled_users_trend: enrolledUsersTrend,
    online_acc_and_eng_account_creation_trend: accountCreationTrend,
    online_acc_and_eng_engagement_vs_active_users: firstEngagementVsActiveUsers,
    online_acc_and_eng_avg_voc_rating: avgVocRating,
    online_acc_and_eng_enrolled_users_linked: enrolleUsersLinked,
    online_acc_and_eng_vehicles_added: vehiclesAdded,
    online_acc_and_eng_account_creation_adjusted: accountCreationAdjusted,
  } = useSelector((state: RootStateOrAny) => state.use_ownership);

  const {
    online_acc_and_eng_private_sales: privateSalesLoading,
    online_acc_and_eng_start_account_creation: startAccCreationLoading,
    online_acc_and_eng_complete_account_creation: completeAccCreationLoading,
    online_acc_and_eng_first_engagement: firstEngagementLoading,
    online_acc_and_eng_active_users: activeUsersLoading,
    online_acc_and_eng_linked_cars: linkedCarsTrendLoading,
    online_acc_and_eng_enrolled_users_trend: enrolledUsersTrendLoading,
    online_acc_and_eng_account_creation_trend: accountCreationTrendLoading,
    online_acc_and_eng_engagement_vs_active_users: firstEngagementVsActiveUsersLoading,
    online_acc_and_eng_avg_voc_rating: avgVocRatingLoading,
    online_acc_and_eng_enrolled_users_linked: enrolleUsersLinkedLoading,
    online_acc_and_eng_vehicles_added: vehiclesAddedLoading,
    online_acc_and_eng_account_creation_adjusted: accountCreationAdjustedLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);

  const [enrolledUsersSwitch, setEnrolledUsersSwitch] = useState(true);

  useEffect(() => {
    dispatch(setPageName("USE Ownership Dashboard - Online Account & Engagement"));
    setShowBanner(true);
    setBannerText(
      " Please note that data availability differs across markets in scope. N/A is displayed when data is not available. For more information on data availability by market view the complete data dictionary by clicking on the book sing on bottom left."
    );

    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, []);

  const {
    data: usersclickedAppDownloadData,
    isLoading: usersclickedAppDownloadLoading,
    refetch: refetchUsersclickedAppDownload,
  } = useQuery({
    queryKey: ["usersclickedAppDownloadData", history.location.search],
    queryFn: getOnlineAccAndEngUsersClickedAppDownload,
    enabled: false,
  });

  const {
    data: usersclickedAppDownloadTrendData,
    isLoading: usersclickedAppDownloadTrendLoading,
    refetch: refetchUsersclickedAppDownloadTrend,
  } = useQuery({
    queryKey: ["usersclickedAppDownloadTrendData", history.location.search],
    queryFn: getOnlineAccAndEngUsersClickedAppDownloadTrend,
    enabled: false,
  });

  const {
    data: usersClickedViewOrderDetailsData,
    isLoading: usersClickedViewOrderDetailsLoading,
    refetch: refetchUsersClickedViewOrderDetails,
  } = useQuery({
    queryKey: ["usersClickedViewOrderDetails", history.location.search],
    queryFn: getOnlineAccAndEngUsersClickedViewOrderDetails,
    enabled: false,
  });

  const {
    data: usersClickedViewOrderDetailsTrendData,
    isLoading: usersClickedViewOrderDetailsTrendLoading,
    refetch: refetchUsersClickedViewOrderDetailsTrend,
  } = useQuery({
    queryKey: ["usersClickedViewOrderDetailsTrend", history.location.search],
    queryFn: getOnlineAccAndEngUsersClickedViewOrderDetailsTrend,
    enabled: false,
  });

  useEffect(() => {
    dispatch(fetchOnlineAccAndEngPrivateSales());
    dispatch(fetchOnlineAccAndEngEnrolledUsersTrend());
    dispatch(fetchOnlineAccAndEngAccountCreationTrend());
    dispatch(fetchOnlineAccAndEngLinkedCars());
    dispatch(fetchOnlineAccAndEngEngagementVsActiveUsers());
    dispatch(fetchOnlineAccAndEngStartAccountCreation());
    dispatch(fetchOnlineAccAndEngCompleteAccountCreation());
    dispatch(fetchOnlineAccAndEngAccountCreationAdjusted());
    dispatch(fetchOnlineAccAndEngActiveUsers());
    dispatch(fetchOnlineAccAndEngFirstEngagement());
    dispatch(fetchOnlineAccAndEngAvgVocRating());
    dispatch(fetchOnlineAccAndEngEnrolledUsersLinked());
    dispatch(fetchOnlineAccAndEngVehiclesAdded());
    refetchUsersclickedAppDownload();
    refetchUsersclickedAppDownloadTrend();
    refetchUsersClickedViewOrderDetails();
    refetchUsersClickedViewOrderDetailsTrend();
  }, [history.location.search]);

  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Use Ownership", page: props.history.location.pathname });
  }, [window.location]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<UseOwnershipFilters filterBtns={USE_OWNERSHIP_BTN_FILTERS} page="Online Account and Engagement" />}
        dataDocumentation="use_ownership"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="use_ownership/online_account_and_engagement" />}
      >
        <div className="online-account-engagement-engagement-page">
          <div className="row">
            <div className="section-title tile">Total Cars Sold</div>
            <div className="metric-tiles">
              <MetricTileWithIcon
                Icon={CarRentalIcon}
                title="Private Sales"
                titleTooltipText="Digitally influenced sales + Others (dealer walk ins + other)"
                mainValue={privateSales?.Current?.private_sales}
                previousValue={privateSales?.YoY?.private_sales}
                yoyValue={privateSales?.YoY?.perc_private_sales}
                popValue={privateSales?.PoP?.perc_private_sales}
                isLoading={privateSalesLoading}
              />
            </div>
          </div>
          <div className="row">
            <div className="section-title tile">Account creation</div>
            <div className="metric-tiles">
              <MetricTileWithIcon
                Icon={TocRoundedIcon}
                title="Users who start account creation form"
                titleTooltipText="Users who lands on the owner portal registration form"
                mainValue={startAccCreation?.Current?.account_creation_form_start_users}
                previousValue={startAccCreation?.YoY?.account_creation_form_start_users}
                yoyValue={startAccCreation?.YoY?.perc_account_creation_form_start_users}
                popValue={startAccCreation?.PoP?.perc_account_creation_form_start_users}
                isLoading={startAccCreationLoading}
              />

              <MetricTileWithIcon
                Icon={ChecklistRtlRoundedIcon}
                title="Users who complete account creation form"
                titleTooltipText="Users who complete the owner portal registration form"
                mainValue={completeAccCreation?.Current?.account_creation_form_complete_users}
                previousValue={completeAccCreation?.YoY?.account_creation_form_complete_users}
                yoyValue={completeAccCreation?.YoY?.perc_account_creation_form_complete_users}
                popValue={completeAccCreation?.PoP?.perc_account_creation_form_complete_users}
                isLoading={completeAccCreationLoading}
              />

              <MetricTileWithIcon
                Icon={PercentRoundedIcon}
                title="Account activation"
                titleTooltipText="Account activation is the number of account activated divided by the private new car sales in the selected period"
                mainValue={accountCreationAdjusted?.Current?.account_creation_adjusted}
                previousValue={accountCreationAdjusted?.YoY?.account_creation_adjusted}
                yoyValue={accountCreationAdjusted?.YoY?.pts_account_creation_adjusted}
                popValue={accountCreationAdjusted?.PoP?.pts_account_creation_adjusted}
                isLoading={accountCreationAdjustedLoading}
                isPercentage={true}
              />
            </div>
            <div className="chart-tile tile">
              <div className="title-container">
                <div className="title">
                  {enrolledUsersSwitch ? "Sales and Account creation overtime" : "Account activation"}
                  {!enrolledUsersSwitch && (
                    <Tooltip
                      position="top-end"
                      className={"normal_tippy"}
                      trigger="mouseenter"
                      animation="none"
                      interactive={true}
                      delay={1}
                      hideDelay={1}
                      duration={1}
                      title={
                        "Account activation is the number of account activated divided by the private new car sales in the selected month"
                      }
                      size={"small"}
                    >
                      <span className="info_tooltip" />
                    </Tooltip>
                  )}
                </div>
                <div>
                  <ToggleSwitch
                    activeToggleLabel="Absolute numbers"
                    inactiveToggleLabel="Account activation"
                    active={enrolledUsersSwitch}
                    handleToggleClick={() => setEnrolledUsersSwitch((prevState) => !prevState)}
                  />
                </div>
              </div>
              <LineTrendChart
                chartId="EnrolledUsersTrendChart"
                theme={theme}
                isLoading={enrolledUsersSwitch ? enrolledUsersTrendLoading : accountCreationTrendLoading}
                seriesColors={[CYAN, PURPLE, GREEN, INDIGO]}
                data={(enrolledUsersSwitch ? enrolledUsersTrend.data : accountCreationTrend.data) ?? []}
                seriesList={(enrolledUsersSwitch ? enrolledUsersTrend.series : accountCreationTrend.series) ?? []}
                isPercentage={enrolledUsersSwitch ? false : true}
              />
            </div>
          </div>
          <div className="row">
            <div className="section-title tile">Engagement</div>
            <div className="metric-tile-chart-container">
              <div className="metric-tile-chart-row">
                <div className="metric-tiles">
                  <MetricTileWithIcon
                    Icon={AdsClickRoundedIcon}
                    title="Users with first engagement"
                    titleTooltipText=" % of users who interacted for the first time with the ownership page after logging in, in comparison with the total number of visits to the ownership pages"
                    mainValue={firstEngagement?.Current?.first_engagement_users}
                    previousValue={firstEngagement?.YoY?.first_engagement_users}
                    yoyValue={firstEngagement?.YoY?.perc_first_engagement_users}
                    popValue={firstEngagement?.PoP?.perc_first_engagement_users}
                    isLoading={firstEngagementLoading}
                    isPercentage={true}
                  />
                  <MetricTileWithIcon
                    Icon={GroupAddRoundedIcon}
                    title="Repeat Engagement Rate"
                    titleSubtext="Please note that this metric is only displayed when a single month, quarter or financial year is selected"
                    titleTooltipText="% of users who visited the Owner Portal page more than one time divided by the total visits in the selected period"
                    mainValue={activeUsers?.Current?.repeat_engagement_rate}
                    previousValue={activeUsers?.YoY?.repeat_engagement_rate}
                    yoyValue={activeUsers?.YoY?.pts_repeat_engagement_rate}
                    popValue={activeUsers?.PoP?.pts_repeat_engagement_rate}
                    isLoading={activeUsersLoading}
                    isPercentage={true}
                  />
                </div>
                <div className="chart-tile tile">
                  <div className="title-container">
                    <div className="title"> Engagement trends</div>
                  </div>
                  <LineTrendChart
                    chartId="firstEngagementChart"
                    data={firstEngagementVsActiveUsers.data ?? []}
                    seriesList={firstEngagementVsActiveUsers.series ?? []}
                    theme={theme}
                    isLoading={firstEngagementVsActiveUsersLoading}
                    seriesColors={[CYAN, PURPLE, GREEN, INDIGO]}
                    isPercentage={true}
                  />
                </div>
              </div>
              <div className="metric-tile-chart-row">
                <div className="metric-tiles">
                  <div className="tile">
                    <div className="tile-header">
                      <SecurityUpdateIcon fontSize="large" />
                      Users who clicked through to App Download
                    </div>
                    <div className="tile-content">
                      <MetricTile
                        title="Apple App Store"
                        titleTooltipText={""}
                        mainValue={usersclickedAppDownloadData?.Current?.apple_store_users}
                        previousValue={usersclickedAppDownloadData?.YoY?.apple_store_users}
                        yoyValue={usersclickedAppDownloadData?.YoY?.perc_apple_store_users}
                        popValue={usersclickedAppDownloadData?.PoP?.perc_apple_store_users}
                        showPoP={true}
                        isLoading={usersclickedAppDownloadLoading}
                        hasPreviousYear={true}
                      />
                      <MetricTile
                        title="Google Play"
                        titleTooltipText={""}
                        mainValue={usersclickedAppDownloadData?.Current?.google_play_users}
                        previousValue={usersclickedAppDownloadData?.YoY?.google_play_users}
                        yoyValue={usersclickedAppDownloadData?.YoY?.perc_google_play_users}
                        popValue={usersclickedAppDownloadData?.PoP?.perc_google_play_users}
                        showPoP={true}
                        isLoading={usersclickedAppDownloadLoading}
                        hasPreviousYear={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="chart-tile tile">
                  <div className="title-container">
                    <div className="title">Users who clicked through to App Download over time</div>
                  </div>
                  <LineTrendChart
                    chartId="usersWhoClickedAppDownloadChart"
                    data={usersclickedAppDownloadTrendData?.data ?? []}
                    seriesList={usersclickedAppDownloadTrendData?.series ?? []}
                    theme={theme}
                    isLoading={usersclickedAppDownloadTrendLoading}
                    seriesColors={[CYAN, PURPLE, GREEN, INDIGO]}
                    isPercentage={true}
                  />
                </div>
              </div>
              <div className="metric-tile-chart-row">
                <div className="metric-tiles">
                  <div className="tile">
                    <div className="tile-header">
                      <SettingsSuggestIcon fontSize="large" />
                      Users who clicked through to view Order Details
                    </div>
                    <div className="tile-content">
                      <MetricTile
                        title=""
                        titleTooltipText={""}
                        mainValue={usersClickedViewOrderDetailsData?.Current?.order_detail_clicks}
                        previousValue={usersClickedViewOrderDetailsData?.YoY?.order_detail_clicks}
                        yoyValue={usersClickedViewOrderDetailsData?.YoY?.perc_order_detail_clicks}
                        popValue={usersClickedViewOrderDetailsData?.PoP?.perc_order_detail_clicks}
                        showPoP={true}
                        isLoading={usersClickedViewOrderDetailsLoading}
                        hasPreviousYear={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="chart-tile tile">
                  <div className="title-container">
                    <div className="title">Users who clicked through to view Order Details over time</div>
                  </div>
                  <LineTrendChart
                    chartId="usersWhoClickedThroughOderDetailsChart"
                    data={usersClickedViewOrderDetailsTrendData?.data ?? []}
                    seriesList={usersClickedViewOrderDetailsTrendData?.series ?? []}
                    theme={theme}
                    isLoading={usersClickedViewOrderDetailsTrendLoading}
                    seriesColors={[CYAN, PURPLE, GREEN, INDIGO]}
                    isPercentage={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="section-title tile">Key Owner Actions</div>
            <div className="metric-tiles">
              <MetricTileWithIcon
                Icon={AddLinkRoundedIcon}
                title="Users who added their vehicles to the Owner Portal"
                titleTooltipText="Users who went online on the Owner Portal and have added their vehicle (visits for the hits where e38 exists)"
                mainValue={vehiclesAdded?.Current?.vehicle_addition_visits}
                previousValue={vehiclesAdded?.YoY?.vehicle_addition_visits}
                yoyValue={vehiclesAdded?.YoY?.perc_vehicle_addition_visits}
                popValue={vehiclesAdded?.PoP?.perc_vehicle_addition_visits}
                isLoading={vehiclesAddedLoading}
              />
              <MetricTileWithDonutChart
                data={avgVocRating?.chart ?? []}
                title="Avg VOC rating account information                "
                titleTooltipText="Average out of 5 of all ratings given by users to evaluate their experience on Ownership pages"
                mainValue={avgVocRating?.avg?.Current?.average_rating}
                yoyValue={avgVocRating?.avg?.YoY?.pts_average_rating}
                popValue={avgVocRating?.avg?.PoP?.pts_average_rating}
                isLoading={avgVocRatingLoading}
              />
            </div>
            <div className="chart-tile tile">
              <div className="title-container">
                <div className="title">Vehicle addition to Owner portal</div>
              </div>
              <MultipleBarLineTrendChart
                chartId="linkedCarUsersChart"
                data={linkedCarsTrend?.data ?? []}
                seriesList={linkedCarsTrend?.series ?? []}
                theme={theme}
                isLoading={linkedCarsTrendLoading}
                lineAxisIsPercentage={true}
              />
            </div>
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
