import { CategoryAxis, ColumnSeries, DateAxis, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent, Sprite } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, MEDIA_MIX_CHART_COLORS, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { columnSeriesConfiguration, configureCategoryAxis, configureDateAxis, configureValueAxis } from "../helpers/configurations";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";

interface Props {
  data: any;
  theme: ThemeInt;
  chartName: string;
  isLoading: boolean;
  seriesList: Array<{ name: string; field: string }>;
  barColorMapping?: Array<string>;
}

interface ISeries {
  name: string;
  field: string;
}

class EcommerceStackedBarChart extends Component<Props> {
  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, theme, seriesList, barColorMapping } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.fontSize = "var(--filter_font)";
    this.chart.numberFormatter.numberFormat = "#.##";
    const barColors = barColorMapping || MEDIA_MIX_CHART_COLORS
    this.chart.colors.list = barColors.map((colorItem: string) => color(colorItem));
    this.chart.colors.list.reverse()

    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 10;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);

    seriesList?.map(({ name, field }: ISeries) => {
      return this.createSeries({
        name: name,
        field: field,
      });
    });
  }

  createSeries({ name, field }: ISeries) {
    const series = this.chart.series.push(new ColumnSeries());
    columnSeriesConfiguration(series, {
      name: name,
      valueY: field,
    });
    series.stacked = true;
    series.dataFields.dateX = "date";
    series.columns.template.width = percent(75);
    series.columns.template.tooltipText = "{name}: {valueY.formatNumber('###,###')}[/]";
    if (series.tooltip) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.autoTextColor = false;
      series.tooltip.background.fill = color(WHITE);
      series.tooltip.label.fill = color(BLACK);
    }
    series.columns.template.adapter.add("tooltipText", (value: string | undefined, target: Sprite) => {
      const dataValue = target?.tooltipDataItem?.dataContext as { date: string;[index: string]: string | number };

      let text = `[bold]${formatDateAxisLabelToMonthly(dataValue?.date)}[/]\n`;

      this.chart.series.each((item) => {
        const value = item?.dataFields?.valueY && dataValue ? (dataValue[item.dataFields.valueY] as number) : null;
        text += `[${item.stroke}]●[/] ${item.name}: ${value ? Number(value).toLocaleString() : "n/a"}\n`;
      });

      return text;
    });
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default EcommerceStackedBarChart;
