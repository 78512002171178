export const FETCH_OVERVIEW_AVG_VOC_RATING_ACROSS_PAGES = "FETCH_OVERVIEW_AVG_VOC_RATING_ACROSS_PAGES";
export const SET_OVERVIEW_AVG_VOC_RATING_ACROSS_PAGES = "SET_OVERVIEW_AVG_VOC_RATING_ACROSS_PAGES";

export const FETCH_OVERVIEW_ONLINE_ACCOUNT_AND_ENGAGEMENT = "FETCH_OVERVIEW_ONLINE_ACCOUNT_AND_ENGAGEMENT";
export const SET_OVERVIEW_ONLINE_ACCOUNT_AND_ENGAGEMENT = "SET_OVERVIEW_ONLINE_ACCOUNT_AND_ENGAGEMENT";

export const FETCH_OVERVIEW_REPEAT_ENGAGEMENT_RATE = "FETCH_OVERVIEW_REPEAT_ENGAGEMENT_RATE";
export const SET_OVERVIEW_REPEAT_ENGAGEMENT_RATE = "SET_OVERVIEW_REPEAT_ENGAGEMENT_RATE";

export const FETCH_OVERVIEW_SERVICE_LEADS = "FETCH_OVERVIEW_SERVICE_LEADS";
export const SET_OVERVIEW_SERVICE_LEADS = "SET_OVERVIEW_SERVICE_LEADS";

export const FETCH_OVERVIEW_ENROLLED_CSS_CUSTOMERS = "FETCH_OVERVIEW_ENROLLED_CSS_CUSTOMERS";
export const SET_OVERVIEW_ENROLLED_CSS_CUSTOMERS = "SET_OVERVIEW_ENROLLED_CSS_CUSTOMERS";

export const FETCH_OVERVIEW_AVG_MONTHLY_ACTIVE_USERS = "FETCH_OVERVIEW_AVG_MONTHLY_ACTIVE_USERS";
export const SET_OVERVIEW_AVG_MONTHLY_ACTIVE_USERS = "SET_OVERVIEW_AVG_MONTHLY_ACTIVE_USERS";

export const FETCH_OVERVIEW_CONNECTED_CARS_SOLD = "FETCH_OVERVIEW_CONNECTED_CARS_SOLD";
export const SET_OVERVIEW_CONNECTED_CARS_SOLD = "SET_OVERVIEW_CONNECTED_CARS_SOLD";

export const FETCH_OVERVIEW_USER_ENGAGEMENT = "FETCH_OVERVIEW_USER_ENGAGEMENT";
export const SET_OVERVIEW_USER_ENGAGEMENT = "SET_OVERVIEW_USER_ENGAGEMENT";

export const FETCH_OVERVIEW_RENEWAL_RATE = "FETCH_OVERVIEW_RENEWAL_RATE";
export const SET_OVERVIEW_RENEWAL_RATE = "SET_OVERVIEW_RENEWAL_RATE";

export const FETCH_OVERVIEW_CHURN_RATE = "FETCH_OVERVIEW_CHURN_RATE";
export const SET_OVERVIEW_CHURN_RATE = "SET_OVERVIEW_CHURN_RATE";

export const FETCH_OVERVIEW_ENGAGEMENT_TREND = "FETCH_OVERVIEW_ENGAGEMENT_TREND";
export const SET_OVERVIEW_ENGAGEMENT_TREND = "SET_OVERVIEW_ENGAGEMENT_TREND";

export const FETCH_OVERVIEW_RENEWAL_TREND = "FETCH_OVERVIEW_RENEWAL_TREND";
export const SET_OVERVIEW_RENEWAL_TREND = "SET_OVERVIEW_RENEWAL_TREND";

export const FETCH_ONLINE_ACC_AND_ENG_PRIVATE_SALES = "FETCH_ONLINE_ACC_AND_ENG_PRIVATE_SALES";
export const SET_ONLINE_ACC_AND_ENG_PRIVATE_SALES = "SET_ONLINE_ACC_AND_ENG_PRIVATE_SALES";

export const FETCH_ONLINE_ACC_AND_ENG_ENROLLED_USERS_TREND = "FETCH_ONLINE_ACC_AND_ENG_ENROLLED_USERS_TREND";
export const SET_ONLINE_ACC_AND_ENG_ENROLLED_USERS_TREND = "SET_ONLINE_ACC_AND_ENG_ENROLLED_USERS_TREND";

export const FETCH_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_TREND = "FETCH_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_TREND";
export const SET_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_TREND = "SET_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_TREND";

export const FETCH_ONLINE_ACC_AND_ENG_LINKED_CARS = "FETCH_ONLINE_ACC_AND_ENG_LINKED_CARS";
export const SET_ONLINE_ACC_AND_ENG_LINKED_CARS = "SET_ONLINE_ACC_AND_ENG_LINKED_CARS";

export const FETCH_ONLINE_ACC_AND_ENG_ENGAGEMENT_VS_ACTIVE_USERS = "FETCH_ONLINE_ACC_AND_ENG_ENGAGEMENT_VS_ACTIVE_USERS";
export const SET_ONLINE_ACC_AND_ENG_ENGAGEMENT_VS_ACTIVE_USERS = "SET_ONLINE_ACC_AND_ENG_ENGAGEMENT_VS_ACTIVE_USERS";

export const FETCH_ONLINE_ACC_AND_ENG_START_ACCOUNT_CREATION = "FETCH_ONLINE_ACC_AND_ENG_START_ACCOUNT_CREATION";
export const SET_ONLINE_ACC_AND_ENG_START_ACCOUNT_CREATION = "SET_ONLINE_ACC_AND_ENG_START_ACCOUNT_CREATION";

export const FETCH_ONLINE_ACC_AND_ENG_COMPLETE_ACCOUNT_CREATION = "FETCH_ONLINE_ACC_AND_ENG_COMPLETE_ACCOUNT_CREATION";
export const SET_ONLINE_ACC_AND_ENG_COMPLETE_ACCOUNT_CREATION = "SET_ONLINE_ACC_AND_ENG_COMPLETE_ACCOUNT_CREATION";

export const FETCH_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_ADJUSTED = "FETCH_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_ADJUSTED";
export const SET_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_ADJUSTED = "SET_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_ADJUSTED";

export const FETCH_ONLINE_ACC_AND_ENG_ACTIVE_USERS = "FETCH_ONLINE_ACC_AND_ENG_ACTIVE_USERS";
export const SET_ONLINE_ACC_AND_ENG_ACTIVE_USERS = "SET_ONLINE_ACC_AND_ENG_ACTIVE_USERS";

export const FETCH_ONLINE_ACC_AND_ENG_FIRST_ENGAGEMENT = "FETCH_ONLINE_ACC_AND_ENG_FIRST_ENGAGEMENT";
export const SET_ONLINE_ACC_AND_ENG_FIRST_ENGAGEMENT = "SET_ONLINE_ACC_AND_ENG_FIRST_ENGAGEMENT";

export const FETCH_ONLINE_ACC_AND_ENG_AVG_VOC_RATING = "FETCH_ONLINE_ACC_AND_ENG_AVG_VOC_RATING";
export const SET_ONLINE_ACC_AND_ENG_AVG_VOC_RATING = "SET_ONLINE_ACC_AND_ENG_AVG_VOC_RATING";

export const FETCH_ONLINE_ACC_AND_ENG_ENROLLED_USERS_LINKED = "FETCH_ONLINE_ACC_AND_ENG_ENROLLED_USERS_LINKED";
export const SET_ONLINE_ACC_AND_ENG_ENROLLED_USERS_LINKED = "SET_ONLINE_ACC_AND_ENG_ENROLLED_USERS_LINKED";

export const FETCH_ONLINE_ACC_AND_ENG_VEHICLES_ADDED = "FETCH_ONLINE_ACC_AND_ENG_VEHICLES_ADDED";
export const SET_ONLINE_ACC_AND_ENG_VEHICLES_ADDED = "SET_ONLINE_ACC_AND_ENG_VEHICLES_ADDED";

export const FETCH_SERVICE_LEAD_SERVICE_RECOMMENDATION_VISITS = "FETCH_SERVICE_LEAD_SERVICE_RECOMMENDATION_VISITS";
export const SET_SERVICE_LEAD_SERVICE_RECOMMENDATION_VISITS = "SET_SERVICE_LEAD_SERVICE_RECOMMENDATION_VISITS";

export const FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS = "FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS";
export const SET_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS = "SET_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS";

export const FETCH_SERVICE_LEAD_CONVERSION_RATE = "FETCH_SERVICE_LEAD_CONVERSION_RATE";
export const SET_SERVICE_LEAD_CONVERSION_RATE = "SET_SERVICE_LEAD_CONVERSION_RATE";

export const FETCH_SERVICE_LEAD_SERVICE_LEADS = "FETCH_SERVICE_LEAD_SERVICE_LEADS";
export const SET_SERVICE_LEAD_SERVICE_LEADS = "SET_SERVICE_LEAD_SERVICE_LEADS";

export const FETCH_SERVICE_LEAD_BOOKING_FORMS_VISITS_VS_SERVICE_LEADS = "FETCH_SERVICE_LEAD_BOOKING_FORMS_VISITS_VS_SERVICE_LEADS";
export const SET_SERVICE_LEAD_BOOKING_FORMS_VISITS_VS_SERVICE_LEADS = "SET_SERVICE_LEAD_BOOKING_FORMS_VISITS_VS_SERVICE_LEADS";

export const FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS_BY_CHANNEL_OR_TREND =
  "FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS_BY_CHANNEL_OR_TREND";
export const SET_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS_BY_CHANNEL_OR_TREND =
  "SET_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS_BY_CHANNEL_OR_TREND";

export const FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORMS_OPENED = "FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORMS_OPENED";
export const SET_SERVICE_LEAD_SERVICE_BOOKING_FORMS_OPENED = "SET_SERVICE_LEAD_SERVICE_BOOKING_FORMS_OPENED";

export const FETCH_SELF_SERVICE_USERS_VISITING_SELF_LEARNING_PAGE = "FETCH_SELF_SERVICE_USERS_VISITING_SELF_LEARNING_PAGE";
export const SET_SELF_SERVICE_USERS_VISITING_SELF_LEARNING_PAGE = "SET_SELF_SERVICE_USERS_VISITING_SELF_LEARNING_PAGE";

export const FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_AND_TIME_SPENT = "FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_AND_TIME_SPENT";
export const SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_AND_TIME_SPENT = "SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_AND_TIME_SPENT";

export const FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_CHANNEL = "FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_CHANNEL";
export const SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_CHANNEL = "SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_CHANNEL";

export const FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_ENTRY_POINT = "FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_ENTRY_POINT";
export const SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_ENTRY_POINT = "SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_ENTRY_POINT";

export const FETCH_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE = "FETCH_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE";
export const SET_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE = "SET_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE";

export const FETCH_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE_OVER_TIME =
  "FETCH_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE_OVER_TIME";
export const SET_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE_OVER_TIME = "SET_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE_OVER_TIME";

export const FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_VERBATIM_DATA = "FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_VERBATIM_DATA";
export const SET_SELF_SERVICE_TOPIC_CLASSIFICATION_VERBATIM_DATA = "SET_SELF_SERVICE_TOPIC_CLASSIFICATION_VERBATIM_DATA";

export const FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_DISSATISFACTION = "FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_DISSATISFACTION";
export const SET_SELF_SERVICE_TOPIC_CLASSIFICATION_DISSATISFACTION = "SET_SELF_SERVICE_TOPIC_CLASSIFICATION_DISSATISFACTION";

export const FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_SATISFACTION = "FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_SATISFACTION";
export const SET_SELF_SERVICE_TOPIC_CLASSIFICATION_SATISFACTION = "SET_SELF_SERVICE_TOPIC_CLASSIFICATION_SATISFACTION";

export const FETCH_SELF_SERVICE_POPULAR_SEARCH_TERMS_VIA_GOOGLE = "FETCH_SELF_SERVICE_POPULAR_SEARCH_TERMS_VIA_GOOGLE";
export const SET_SELF_SERVICE_POPULAR_SEARCH_TERMS_VIA_GOOGLE = "SET_SELF_SERVICE_POPULAR_SEARCH_TERMS_VIA_GOOGLE";
