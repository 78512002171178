import { ErrorBoundary } from "@sentry/react";
import { saveAs } from "file-saver";
import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import { setSpecificParameter } from "../../actions";
import {
  cpoOperationalParams,
  getCpoParams,
  getPmoQueryParams,
  getPurchaseFunnelParameters,
  getShowTouchPoints,
  midasApiParamsToObject,
  msrpApiParamsToObject,
  oceNewParameter,
  pmoParamsIncludeAvgModels,
} from "../../api";
import { brandPerformanceApiParamsToObject } from "../../api/brandPerformanceApi";
import { getEuropeActivationV2Params } from "../../api/europeActivationApi";
import { getEuropeActivationCsvFIlter, getEuropeActivationParametersDownload } from "../../api/europeActivationParameters";
import { getParams as getExecutiveSummaryProductPerformanceParams } from "../../api/executiveSummaryProductPerformanceApi";
import { getParams as getExecutiveSummaryDisParams } from "../../api/executiveSummaryWebsiteApi";
import { getParams as getMaketingPerformanceSummaryParams } from "../../api/JourneyExcellenceMarketPerformanceSummary";
import { journeyExcellenceApiParamsToObject } from "../../api/journeyExcellenceApi";
import { getQueryParams as getQueryParamsProductPerformance } from "../../api/productPerformanceApi";
import { postDataV2, postDataZipFileV2 } from "../../api/utils";
import { getVOCParams } from "../../api/vocApi";
import "../../assets/styles/component/pageContainer.scss";
import { DATE_FILTER_VALUE_MAPPING, DATE_FILTER_VALUE_MAPPING_MSRP } from "../../constants";
import { DashboardBannerContext, DocumentationContext, FilterContext, ThemeContext } from "../../context";
import { JourneyExcellenceContext } from "../../pages/Journey Excellence/DeepDive/context/JourneyExcellenceContext";
import { getStartEnd } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { ErrorMsg } from "../AppMessages";
import { Documentation } from "../Documentation/Documentation";
import MenuOptions from "../MenuOptions/MenuOptions";
import { handlePDFDownload } from "../MenuOptions/subs";
import { SaveHomePage } from "../Modals";
import { MetricBreadcrumb } from "./subs";
import { getParams } from "../../api/dmeoApi";
import { getParams as getQuickVocSalesParams } from "../../api/quickVocSalesApi";
import { getParams as getQuickVocAfterSalesParams } from "../../api/quickVocAfterSales";
import { getParams as getQuickVocCecParams } from "../../api/quickVocCecApi";
import { getParams as getCustomerJourneyVocParams } from "../../api/customerJourneyVocApi";
import { getParams as getExecutiveSummaryProductPerformanceDeepDiveParams } from "../../api/executiveSummaryProductPerformanceDeepDive";
import { ecommerceApiParamsToObject } from "../../api/ecommerce";

interface Props {
  filter: JSX.Element;
  children: JSX.Element;
  lastDataRefresh: string;
  dataDocumentation: string;
  showLastDataRefreshAlert?: boolean;
  hideDownloadPdf?: boolean;
  isMTM?: boolean;
  pageTitle: JSX.Element;
  extraClass?: string;
}

export const DefaultPageContent = (props: Props): JSX.Element => {
  const { children, extraClass, lastDataRefresh, filter, dataDocumentation, showLastDataRefreshAlert, hideDownloadPdf, pageTitle } = props;

  const { setShowMobileFilter } = useContext(FilterContext);
  const { detailText, bannerText, showBanner, setBannerHovered } = useContext(DashboardBannerContext);
  const { theme } = useContext(ThemeContext);

  const [metricActive] = useState<boolean>(false);
  const [showSaveHomePage, setShowSaveHomePage] = useState<boolean>(false);
  const [path] = useState(window.location.href);

  useEffect(() => {
    // hide and show metric breadcrumb
    const metricButtonId = "metric_button";
    const metricBreadcrumbElement = document.getElementById(metricButtonId);

    const shownClass = "isVisible";
    if (metricActive) metricBreadcrumbElement?.classList.add(shownClass);
    else metricBreadcrumbElement?.classList.remove(shownClass);
  }, [metricActive]);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const appStoreParamValue = useSelector((state: RootStateOrAny) => state.parameters.app_store);
  const marketParamValue = useSelector((state: RootStateOrAny) => state.parameters.market);
  const experienceRatingParamValue = useSelector((state: RootStateOrAny) => state.parameters.experience_rating);
  const topicParamValue = useSelector((state: RootStateOrAny) => state.parameters.topic);
  const touchpointParamValue = useSelector((state: RootStateOrAny) => state.parameters.touchpoint);
  const {
    digitally_influenced_sales_cumulative,
    private_sales_breakdown_cumulative,
    digital_leads_by_source_digitally_influenced_leads,
    sales_breakdown_by_source_digitally_influenced_sales,
  } = useSelector((state: RootStateOrAny) => state.parameters);
  const vocCsvExperienceRating = useSelector((state: RootStateOrAny) => state.voc.experience_rating);

  const [hideAppRatingsBanner, setHideAppRatingsBanner] = useState<boolean>(true);

  useEffect(() => {
    const dates = dateRangeParamValue.split(",");
    const banner_dates = ["December 2021", "January 2022", "February 2022", "March 2022"];
    let isInRange = false;
    for (const date of banner_dates) {
      if (dates.includes(date)) {
        isInRange = true;
        break;
      }
    }
    isInRange && !appStoreParamValue.includes("iOS") ? setHideAppRatingsBanner(false) : setHideAppRatingsBanner(true);
  }, [dateRangeParamValue, appStoreParamValue]);

  const handleSaveHomePageClick = () => {
    setShowSaveHomePage(true);
  };

  const handleCloseSaveHomePage = () => {
    setShowSaveHomePage(false);
  };

  function s2ab(s: any) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf); //create uint8array as viewer
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  }

  const dispatch = useDispatch();
  const verts = useSelector((state: RootStateOrAny) => state.parameters.verticalScroll);
  const handleScroll = (event: { currentTarget: any }) => {
    const pagePath = location?.pathname?.split("/")[2]?.replace(/_/g, "-");
    if (pagePath === "mtp-category") {
      const verticalScroll2 = document.getElementById("container");
      if (verticalScroll2 && verticalScroll2?.scrollTop > 20 && !verts) {
        dispatch(setSpecificParameter("verticalScroll", true));
      }
      if (verticalScroll2 && verticalScroll2?.scrollTop < 20 && verts) {
        dispatch(setSpecificParameter("verticalScroll", false));
      }
    }
  };

  const handleMidasCSVDownload = () => {
    const searchParams = window.location.search;
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 1000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "Fmi run rate" });
    const touchpointPaths = ["strategic_dealer_support", "strategic_ce", "fixed"];
    const pagePath = location.pathname.split("/")[2].replace(/_/g, "-");

    postDataZipFileV2(
      `/midas/${pagePath}/csv-download${touchpointPaths.includes(location.pathname.split("/")[2]) ? "?touchpoints=" + getShowTouchPoints(searchParams) : ""
      }`,
      { ...midasApiParamsToObject(searchParams) }
    )
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), `${pagePath}.csv`);
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleCpoOperationalCSVDownload = () => {
    const searchParams = window.location.search;
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 1000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "CPO Operational" });

    postDataZipFileV2(`/cpo-operational/get-csv-data`, {
      ...cpoOperationalParams(searchParams),
    })
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), `cpo_operational.csv`);
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleEXCELDownload = () => {
    postDataV2(`/europe-activation-ratio-reporting/get-activation-download-data`, { ...getEuropeActivationParametersDownload() }).then(
      (res) => {
        const wb = XLSX.utils.book_new();
        const params = { ...getEuropeActivationCsvFIlter() };
        const keys = Object.keys(params);
        const vals = Object.values(params);
        const keyData: { key: string }[] = [];
        const valData: { val: string | boolean | string[] | undefined }[] = [];
        for (let i = 0; i < keys.length; i++) {
          if (keys[i] === "market_groups") {
            keyData.push({ key: "Geography" });
          } else {
            keyData.push({ key: keys[i] });
          }
          valData.push({ val: vals[i]?.toString().split(/[,]+/).join(" ; ") });
        }

        const summarySheet = XLSX.utils.json_to_sheet(res);
        XLSX.utils.book_append_sheet(wb, summarySheet, "Data-Sheet");

        //filters worksheet
        const filtersSheet = XLSX.utils.json_to_sheet(keyData, { skipHeader: 1 });
        XLSX.utils.sheet_add_json(filtersSheet, valData, { skipHeader: 1, origin: "B1" });
        XLSX.utils.book_append_sheet(wb, filtersSheet, "Filters-Selected");

        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        toast.success("download started");
        saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), "ccs_europe_activation.xlsx");
      }
    );
  };

  const handleOCECSVDownload = (csv_type: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    const dateRangeVal = searchParams.get("date_range");
    const marketVal = searchParams.get("market")?.split(",");
    const startEndDate = getStartEnd(dateRangeVal);

    searchParams.append("start_date", startEndDate.start);
    searchParams.append("end_date", startEndDate.end);
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "OCE" });

    postDataZipFileV2(`/digital-performance/get-${csv_type}-data-csv-download`, { ...oceNewParameter(`?${searchParams.toString()}`) })
      .then((res: any) => {
        if ("error" in res) {
          toast.error("Model and channel data is only available if your geography selection contains up to 5 countries");
        } else {
          saveAs(new Blob([res], { type: "text/csv" }), `online_ce_performance.csv`);
        }
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleCPOCSVDownload = (csv_type: string) => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "CPO Online" });

    postDataZipFileV2(`/certified-pre-owned/get-${csv_type}-data-csv-download`, { ...getCpoParams() })
      .then((res: any) => {
        if ("error" in res) {
          toast.error("Model and channel data is only available if your geography selection contains up to 5 countries");
        } else {
          saveAs(new Blob([res], { type: "text/csv" }), `cpo_online.csv`);
        }
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleVOCWebsiteCSVDownload = (csv_type: string) => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    const params: Record<string, any> = { ...getVOCParams() };

    if (csv_type == "top-ten-categories") {
      params["experience_ratings"] = vocCsvExperienceRating;
    }

    if (csv_type == "feedback-comments") {
      params["experience_rating"] =
        !experienceRatingParamValue || experienceRatingParamValue == "All" ? [] : experienceRatingParamValue.split(",");
      params["topics"] = !topicParamValue || topicParamValue == "All" ? [] : topicParamValue.split(",");
      params["touchpoints"] = !touchpointParamValue || touchpointParamValue == "All" ? [] : touchpointParamValue.split(",");
    }

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "VOC" });

    postDataZipFileV2(`/voc/${csv_type}-csv-download`, params)
      .then((res: any) => {
        if ("error" in res) {
          toast.error("An error occurred while downloading CSV file");
        } else {
          saveAs(new Blob([res], { type: "text/csv" }), `${csv_type}.csv`);
        }
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleQuickVocSalesCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "VOC" });

    postDataZipFileV2(`/quick-voc-sales/csv-download`, getQuickVocSalesParams())
      .then((res: any) => {
        if ("error" in res) {
          toast.error("An error occurred while downloading CSV file");
        } else {
          saveAs(new Blob([res], { type: "text/csv" }), `quick_voc_sales.csv`);
        }
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleQuickVocAfterSalesCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "VOC" });

    postDataZipFileV2(`/quick-voc-after-sales/csv-download`, getQuickVocAfterSalesParams())
      .then((res: any) => {
        if ("error" in res) {
          toast.error("An error occurred while downloading CSV file");
        } else {
          saveAs(new Blob([res], { type: "text/csv" }), `quick_voc_after_sales.csv`);
        }
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleQuickVocCecCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "VOC" });

    postDataZipFileV2(`/quick-voc-cec/csv-download`, getQuickVocCecParams())
      .then((res: any) => {
        if ("error" in res) {
          toast.error("An error occurred while downloading CSV file");
        } else {
          saveAs(new Blob([res], { type: "text/csv" }), `cec_voc.csv`);
        }
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleCustomerJourneyVocCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "VOC" });

    postDataZipFileV2(`/voc-360/csv-download`, getCustomerJourneyVocParams())
      .then((res: any) => {
        if ("error" in res) {
          toast.error("An error occurred while downloading CSV file");
        } else {
          saveAs(new Blob([res], { type: "text/csv" }), `customer_journey_voc.csv`);
        }
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleEuropeActivationCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "Europe Activation" });

    postDataZipFileV2(`/europe-activation-ratio-reporting/get-excel-download`, { ...getEuropeActivationV2Params() })
      .then((res: any) => {
        if ("error" in res) {
          toast.error("An error occurred while downloading CSV file");
        } else {
          saveAs(new Blob([res], { type: "text/csv" }), `ccs_europe_activation.xlsx`);
        }
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleVOCExcelDownload = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const dateValue = DATE_FILTER_VALUE_MAPPING[searchParams.get("date_range") as string];
    const marketValue = searchParams.get("market")?.split(",")[0];
    toast.success(
      <div>
        XLSX downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "VOC" });

    postDataZipFileV2(`/voc/excel-download`, { ...getVOCParams() })
      .then((res: any) => {
        if ("error" in res) {
          if (res.error.includes("422")) {
            toast.error("Excel download must have only one market");
          } else {
            toast.error("An error occurred while downloading XLSX file");
          }
        } else {
          saveAs(
            new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
            `VOC ${marketValue} ${dateValue}.xlsx`
          );
        }
      })
      .catch(() => toast.error("An error occurred while downloading XLSX file"));
  };

  const handlePMOCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "PMO" });

    postDataZipFileV2(`/product-marketing-optimization/get-csv-data`, { ...pmoParamsIncludeAvgModels() })
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), "pmo.csv");
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleMarketingCalendarCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "PMO" });

    postDataZipFileV2(`/product-marketing-optimization/get-marketing-activity-csv-data`, { ...pmoParamsIncludeAvgModels() })
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), "marketing calendar.csv");
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleDFPCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "DFP" });

    postDataZipFileV2(`/drivers-for-flash-performance/get-csv-data`, { ...getPmoQueryParams() })
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), "dfp.csv");
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleProductPerformanceCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );
    const searchParams = Object.fromEntries(new URLSearchParams(window.location.search));
    const dataParam = searchParams.date_range.split(" ").join("_").toLocaleLowerCase();

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "Product Performance" });

    postDataZipFileV2(`/product-performance/csv-download`, { ...getQueryParamsProductPerformance() })
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), "product_performance.csv");
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleMSRPCSVDownload = () => {
    const searchParams = Object.fromEntries(new URLSearchParams(window.location.search));
    const dateValue = Object.keys(DATE_FILTER_VALUE_MAPPING_MSRP).includes(searchParams.date_range)
      ? DATE_FILTER_VALUE_MAPPING_MSRP[searchParams.date_range]
      : searchParams.date_range;

    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "MSRP" });

    postDataZipFileV2(`/msrp/csv-download`, { ...msrpApiParamsToObject() })
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), `msrp ${dateValue}.csv`);
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleDMEOCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "DMEO" });

    postDataZipFileV2(
      `/dmeo/csv-download?page=${window.location.pathname?.includes("landing-page-performance") ? "landing_page" : "dmeo"}`,
      {
        ...getParams(),
      }
    )
      .then((res: any) => {
        saveAs(
          new Blob([res], { type: "application/zip" }),
          `${window.location.pathname?.includes("landing-page-performance") ? "dmeo_landing_page.csv" : "dmeo.csv"}`
        );
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const { metric: journeyExcellenceMetric } = useContext(JourneyExcellenceContext);

  const handleJourneyExcellenceCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "Journey Excellence" });

    let csvEndpoint = "/journey-excellence/csv-download";

    if (journeyExcellenceMetric == "FMI") {
      csvEndpoint = "/journey-excellence/fmi/csv-download";
    } else if (journeyExcellenceMetric == "Online Journey") {
      csvEndpoint = "/journey-excellence/online-journey/csv-download";
    } else if (journeyExcellenceMetric == "Leads Management System") {
      csvEndpoint = "/journey-excellence/lms/csv-download";
    } else if (journeyExcellenceMetric == "Product Journey") {
      csvEndpoint = "/journey-excellence/product-journey/csv-download";
    }

    postDataZipFileV2(`${csvEndpoint}`, { ...journeyExcellenceApiParamsToObject() })
      .then((res: any) => {
        saveAs(
          new Blob([res], { type: "application/zip" }),
          `Journey Excellence${journeyExcellenceMetric ? " - " + journeyExcellenceMetric : ""}.csv`
        );
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleUseOwnershipCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "Use Ownership" });

    let csvEndpoint = "/use-ownership/overview/get-csv-download";

    if (location.pathname.includes("online_account_and_engagement")) {
      csvEndpoint = "/use-ownership/online-account-and-engagement/get-csv-download";
    } else if (location.pathname.includes("service_lead")) {
      csvEndpoint = "/use-ownership/service-lead/get-csv-download";
    } else if (location.pathname.includes("self_service")) {
      csvEndpoint = "/use-ownership/self-service/get-csv-download";
    }

    const page = location.pathname.split("/").pop();

    postDataZipFileV2(`${csvEndpoint}`, { ...journeyExcellenceApiParamsToObject() })
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), `Use ownership${page ? " " + page.split("_").join(" ") : ""}.csv`);
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleBrandPerformanceCSVDownload = () => {
    const searchParams = Object.fromEntries(new URLSearchParams(window.location.search));
    const page = searchParams?.performance_page;
    const nissan_competitor = searchParams?.nissan_competitor;

    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: `${location.pathname}-${page?.replace("_", "-")}`, dashboard: "Brand Performance" });

    postDataZipFileV2(
      `/brand-performance/brand-${page?.replace("_", "-")}/brand-${page?.replace("_", "-")}-trend-csv-download?nissan_competitor=${nissan_competitor == "nissan_competitor" ? "true" : "false"
      }`,
      {
        ...brandPerformanceApiParamsToObject(window.location.search),
      }
    )
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), `brand_performance_${page}.csv`);
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleExecutiveSummaryCSVDownload = (isChannelMix?: boolean) => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    let params = {};

    const page = location.pathname?.split("/")?.at(-1)?.replaceAll("_", "-");
    const path = isChannelMix
      ? `/executive-summary/${page}/channel-mix-performance-csv-download`
      : `/executive-summary/${page}/csv-download`;
    let filename = "executive_summary_dis.csv";

    if (page == "dis") {
      params = { ...getExecutiveSummaryDisParams() };
      eventTracking(MixpanelEvents.csv_download, { dashboard: "Executive Summary", page: "Digitally Influenced Sales" });
    } else if (page == "product-performance") {
      params = { ...getExecutiveSummaryProductPerformanceParams() };
      filename = "executive_summary_product_performance.csv";
      eventTracking(MixpanelEvents.csv_download, { dashboard: "Executive Summary", page: "Product Performance" });
    } else if (page == "product-performance-deep-dive") {
      params = { ...getExecutiveSummaryProductPerformanceDeepDiveParams() };
      filename = "executive_summary_product_performance_deep_dive.csv";
      eventTracking(MixpanelEvents.csv_download, { dashboard: "Executive Summary", page: "Product Performance Deep Dive" });
    }

    postDataZipFileV2(path, params)
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), filename);
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleDISCSVDownload = (csvType: string) => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "DIS" });

    let endpoint = "/dis/get-csv-download";

    if (csvType == "car model") {
      endpoint = "/dis/get-sales-by-car-model-csv-download";
    }

    postDataZipFileV2(endpoint, { ...getPurchaseFunnelParameters() })
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), `dis ${csvType}.csv`);
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handlMarketPerformanceSummaryCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "Marketing Performance Summary" });

    postDataZipFileV2(`/mtm/csv-download`, { ...getMaketingPerformanceSummaryParams() })
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), "marketing_performance_summary.csv");
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const handleEcommerceCSVDownload = () => {
    toast.success(
      <div>
        CSV downloading <br />
      </div>,
      { autoClose: 10000, pauseOnHover: true }
    );

    eventTracking(MixpanelEvents.csv_download, { page: location.pathname, dashboard: "Ecommerce" });

    postDataZipFileV2(`/ecom/csv-download`, { ...ecommerceApiParamsToObject() })
      .then((res: any) => {
        saveAs(new Blob([res], { type: "application/zip" }), "ecommerce.csv");
      })
      .catch(() => toast.error("An error occurred while downloading CSV file"));
  };

  const { isVisible, setIsVisible, documentation } = useContext(DocumentationContext);

  return (
    <>
      <ErrorBoundary fallback={<ErrorMsg />}>{filter}</ErrorBoundary>

      {!hideAppRatingsBanner && (path.includes("ccs_cockpit") || path.includes("app_ratings") || path.includes("ccs_analysis")) && (
        <div
          className={`dashboard_banner hide_on_mobile ${showBanner || (path.includes("ccs_cockpit") && !hideAppRatingsBanner) || path.includes("app_ratings") ? "" : "isHidden"
            }`}
          onMouseEnter={() => setBannerHovered(true)}
          onMouseLeave={() => setBannerHovered(false)}
        >
          <p>
            {bannerText}
            <span className={"detail"}> {detailText}</span>
          </p>

          <p className={`${path.includes("ccs_cockpit") || path.includes("app_ratings") ? "" : "isHidden"}`}>
            App data for <span style={{ fontWeight: "bold" }}>Android</span> is not available between December 2021 and March 14th 2022
          </p>

          <p
            className={`${(path.includes("ccs_cockpit") || path.includes("ccs_analysis")) && marketParamValue == "United States" ? "" : "isHidden"
              }`}
          >
            NNA has updated their KPI for active users from June &apos;22, YoY comparison is not applicable
          </p>
        </div>
      )}

      {showBanner &&
        (path.includes("cpo_digital_performance") ||
          path.includes("mtm_metrics") ||
          path.includes("msrp") ||
          path.includes("ccs_journey") ||
          path.includes("midas") ||
          path.includes("use_ownership") ||
          path.includes("top_flop") ||
          path.includes("journey_excellence") ||
          path.includes("executive_summary/product_performance") ||
          path.includes("cpo_operational") ||
          path.includes("voc/quick_voc_after_sales") ||
          path.includes("voc/cec_voc") ||
          path.includes("voc/customer_journey_voc") ||
          path.includes("voc/quick_voc_sales") ||
          path.includes("voc/website") ||
          path.includes("ccs_vital_signs/ccs_monetization")) && (
          <div
            className={`dashboard_banner hide_on_mobile `}
            onMouseEnter={() => setBannerHovered(true)}
            onMouseLeave={() => setBannerHovered(false)}
          >
            <p style={{ whiteSpace: "pre-line" }}>
              {bannerText}
              <span className={"detail"}> {detailText}</span>
            </p>
          </div>
        )}

      {path.includes("seo_dashboard") && (
        <div
          className={`dashboard_banner hide_on_mobile `}
          onMouseEnter={() => setBannerHovered(true)}
          onMouseLeave={() => setBannerHovered(false)}
        >
          <p style={{ whiteSpace: "pre-line" }}>
            Please note that we&apos;ve updated the data source to Bulk export so you&apos;d see an uptick in the numbers that now reflect
            Search Console accurately.
          </p>
          <p style={{ fontWeight: "bold" }}>NOTE:</p>
          <ul style={{ marginLeft: "1.5rem" }}>
            <li>Bulk exports for Japan are in progress and we&apos;ll update this banner once available.</li>
            <li>Please avoid YoY comparisons for Search Console data.</li>
            <li>Please avoid comparison for Search Console data between data pre- September 2023 and September 2023 onwards.</li>
          </ul>
        </div>
      )}

      <div id="container" onScroll={handleScroll}>
        <div id={"dashboard_items"} className={extraClass}>
          {/*Mobile Filter btn*/}
          <div className="hide_on_desktop filter_float" id="filter_float">
            <MetricBreadcrumb />
            <button
              className={"filter_button"}
              onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                evt.preventDefault();
                setShowMobileFilter(true);
              }}
            >
              Filters
            </button>
          </div>
          {pageTitle}
          <MenuOptions
            onHandleCSVDownload={handleEXCELDownload}
            onHandleEuropeActivationCSVDownload={handleEuropeActivationCSVDownload}
            onHandleOCECSVDownload={handleOCECSVDownload}
            onHandleCPOCSVDownload={handleCPOCSVDownload}
            onHandlePMOCSVDownload={handlePMOCSVDownload}
            onHandleDFPCSVDownload={handleDFPCSVDownload}
            onHandleDMEOCSVDownload={handleDMEOCSVDownload}
            onHandleMarketingCalendarCSVDownload={handleMarketingCalendarCSVDownload}
            onHandleMidasCsvDownload={handleMidasCSVDownload}
            onHandleProductPerformanceCSVDownload={handleProductPerformanceCSVDownload}
            onHandleVOCWebsiteCSVDownload={handleVOCWebsiteCSVDownload}
            onHandleVOCExcelDownload={handleVOCExcelDownload}
            onHandleQuickVocSalesCSVDownload={handleQuickVocSalesCSVDownload}
            onHandleQuickVocAfterSalesCSVDownload={handleQuickVocAfterSalesCSVDownload}
            onHandleQuickVocCecCSVDownload={handleQuickVocCecCSVDownload}
            onHandleCustomerJourneyVocCSVDownload={handleCustomerJourneyVocCSVDownload}
            onHandleJourneyExcellenceCSVDownload={handleJourneyExcellenceCSVDownload}
            onHandleCpoOperationalCSVDownload={handleCpoOperationalCSVDownload}
            onHandleMSRPCSVDownload={handleMSRPCSVDownload}
            onHandleExecutiveSummaryCSVDownload={handleExecutiveSummaryCSVDownload}
            onHandlePDFDownload={() => handlePDFDownload(theme)}
            onHandleSaveHomePageClick={handleSaveHomePageClick}
            onHandleBrandPerformanceCSVDownload={handleBrandPerformanceCSVDownload}
            onHandleUseOwnershipCSVDownload={handleUseOwnershipCSVDownload}
            onHandleMarketingPerformanceSummaryDownload={handlMarketPerformanceSummaryCSVDownload}
            onHandleDISCsvDownload={handleDISCSVDownload}
            onHandleEcommerceCSVDownload={handleEcommerceCSVDownload}
            lastDataRefresh={lastDataRefresh}
            dataDocumentation={dataDocumentation}
            showLastDataRefreshAlert={showLastDataRefreshAlert}
            hideDownloadPdf={hideDownloadPdf}
          />
          {/*Main content*/}
          <div className={!props.isMTM ? "contentWrapper" : "contentWrapperExtend"}>{children}</div>
        </div>
      </div>

      <Documentation isVisible={isVisible} documentation={documentation} onCloseClick={() => setIsVisible(false)} />
      <SaveHomePage isVisible={showSaveHomePage} onCloseClick={handleCloseSaveHomePage} />
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={true}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
};
