import { OCE_DATE_FILTER_OPTIONS } from "../constants";
import moment from "moment";
import { fetchDataV2, newApiFetchV2 } from "./utils";

export const getParams = () => {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters.get("date_range");
  const marketParam = parameters.get("market") == "All" ? [] : parameters.get("market")?.split(",");
  const brandParam = parameters.get("brand") == "All" ? [] : parameters.get("brand")?.split(",");
  const modelParam = parameters.get("model") == "All" ? [] : parameters.get("model")?.split(",");
  const serviceParam = parameters.get("service") ? (parameters.get("service") == "All" ? [] : parameters.get("service")?.split(",")) : null;

  const paramObj: any = {
    market: marketParam,
    brand: brandParam,
    model: modelParam,
    ...(serviceParam && { service: serviceParam }),
  };

  if (dateRangeParam) {
    if (OCE_DATE_FILTER_OPTIONS.includes(dateRangeParam)) {
      paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
    } else {
      paramObj["date_range"] = "custom";
      paramObj["start_date"] = moment(dateRangeParam.split("-")[0], "MMMM YYYY").format("YYYY-MM-DD");
      paramObj["end_date"] = moment(dateRangeParam.split("-")[1], "MMMM YYYY").endOf("month").format("YYYY-MM-DD");
    }
  }

  return paramObj;
};

export const getCcsMonetizationLastDataRefresh = () => fetchDataV2("/ccs_monetization/get-last-refresh-date");

export const getCcsMonetizationSummary = () => newApiFetchV2("/ccs_monetization/summary", getParams());
export const getCustomerAndServiceTakeRateBreakdown = () => newApiFetchV2("/ccs_monetization/take-rate-breakdown", getParams());
export const getVolumeBreakdownTrend = () => newApiFetchV2("/ccs_monetization/volume-breakdown-trend", getParams());
export const getVolumeBreakdownTable = () => newApiFetchV2("/ccs_monetization/volume-breakdown-table", getParams());
export const getTakeRateBreakdownTrend = (cumulative: boolean) =>
  newApiFetchV2(`/ccs_monetization/take-rate-breakdown-trend?cumulative=${cumulative}`, getParams());
export const getTakeRateBreakdownTable = () => newApiFetchV2(`/ccs_monetization/take_rate-breakdown-table`, getParams());
export const getG5TakeRateBreakdownTrend = () => newApiFetchV2("/ccs_monetization/g5-take-rate-breakdown-trend", getParams());
export const getG5TakeRateBreakdownTable = () => newApiFetchV2("/ccs_monetization/g5_take_rate-breakdown-table", getParams());
export const getTotalRevenueByMarketPie = () => newApiFetchV2("/ccs_monetization/total-revenue-by-market-pie", getParams());
export const getTotalRevenueByMarketTrend = () => newApiFetchV2("/ccs_monetization/total-revenue-by-market-trend", getParams());
export const getCustomerServiceTakeRatePieChartData = () => newApiFetchV2("/ccs_monetization/service-take-rate-pie", getParams());

// SVT
export const getSvtSubscriptionsSummary = () => newApiFetchV2("/ccs_monetization/svt-summary", getParams());
export const getSvtSubscriptionsPerMarket = () => newApiFetchV2("/ccs_monetization/svt-subscriptions-market-breakdown-pie", getParams());
export const getSvtSubscriptionsPerMarketTrendline = () =>
  newApiFetchV2("/ccs_monetization/svt-subscriptions-market-breakdown", getParams());
export const getSvtSubscriptionsPerModel = () => newApiFetchV2("/ccs_monetization/svt-subscriptions-model-breakdown-pie", getParams());
export const getSvtSubscriptionsPerModelTrendline = () => newApiFetchV2("/ccs_monetization/svt-subscriptions-model-breakdown", getParams());

export const getCcsMonetizationDataDefinition = () => newApiFetchV2("/ccs_monetization/get-data-definition", getParams());
