import React, { useContext, useEffect } from "react";
import { Tooltip } from "react-tippy";
import { ThemeContext } from "../../../context";
import EcommerceBarLineTrendChart from "../../../components/Charts/Ecommerce/EcommerceStackedBarLineChart";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSaleTrendComparedToDisData } from "../../../api/ecommerce";
import { CYAN, GRAY, ORANGE, ORANGE_RED } from "../../../constants";
import { NoDataMsg } from "../../../components/AppMessages";

const SaleTrendCompatedToDis = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["saleTrendComparedToDis", history.location.search],
    queryFn: getSaleTrendComparedToDisData,
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [history.location.search])

  return (
    <div className="ecommerce-tile">
      <div className="tile-header">
        <div className="header-title">
          <p>Ecommerce Sale Trend compared to DIS</p>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            title={
              "Number of Ecommerce Sales compared to other DIS"
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      {data?.data?.length > 0 ? (
        <EcommerceBarLineTrendChart data={data?.data ?? []} seriesList={data?.series ?? []} chartId="salesTrendComparedToDisChart" theme={theme} isLoading={isFetching} barColorMapping={[CYAN, ORANGE]} lineColorMapping={[GRAY]} lineIsPercentage={true} percentageMax={5} />
      ) : <NoDataMsg />}
    </div>
  );
});

export default SaleTrendCompatedToDis;
