import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../components/Ratings/Ratings";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import { getGranularityFromDate, isNull } from "../../../utils/utilityFunctions";

interface Props {
  title?: string;
  titleSubtext?: string;
  titleTooltipText?: string;
  subText?: string;
  subTextTooltipText?: string;
  mainValue: number | null;
  previousValue?: number | null;
  yoyValue: number | null;
  popValue?: number | null;
  isRating?: boolean;
  maxRating?: number;
  isPercentage?: boolean;
  abbreviateMainValue?: boolean;
  showPoP?: boolean;
  isLoading: boolean;
  hasPreviousYear?: boolean;
}

const PoPLabelMapping: Record<string, string> = {
  monthly: "MoM",
  quarterly: "QoQ",
};

const MetricTile = (props: Props) => {
  const {
    title,
    titleSubtext,
    mainValue,
    previousValue,
    yoyValue,
    popValue,
    isRating,
    maxRating,
    titleTooltipText,
    subText,
    subTextTooltipText,
    isPercentage,
    abbreviateMainValue,
    showPoP,
    isLoading,
    hasPreviousYear
  } = props;
  const dateRangeParam = useSelector((state: RootStateOrAny) => state.parameters.date_range);

  const PoPLabel = useMemo(() => {
    const dateGranularity = getGranularityFromDate(dateRangeParam, "monthly");
    return PoPLabelMapping[dateGranularity];
  }, [dateRangeParam]);

  const hidePoP = useMemo(() => {
    if (dateRangeParam.includes("month") || dateRangeParam.includes("quarter")) {
      return false;
    }
    return true;
  }, [dateRangeParam]);

  const showTitleSubtext = useMemo(() => {
    if (dateRangeParam) {
      const isCustomMonth = dateRangeParam.includes(" - ")
      return dateRangeParam.includes("quarter") || dateRangeParam.includes("financial year") || dateRangeParam.includes("month") || (isCustomMonth && dateRangeParam.split("-")[0].trim() == dateRangeParam.split("-")[1].trim())
    }
    return false
  }, [dateRangeParam])

  return (
    <div className="tile">
      <div className="title-container">
        {title && <div className="title"><div>{title}</div> {titleSubtext && showTitleSubtext && <div className="title-subtext">({titleSubtext})</div>}</div>}
        {titleTooltipText && (
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={titleTooltipText}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        )}
      </div>

      <div className={`${hasPreviousYear ? "metrics" : ""} tile-content`}>
        <div className={`${hasPreviousYear ? "right-border" : ""} values`}>
          {hasPreviousYear && <div>Selected period</div>}

          <div className="value">
            {!isNull(mainValue)
              ? abbreviateMainValue
                ? formatToThreeDigits(mainValue as number)
                : isPercentage
                  ? mainValue?.toLocaleString(undefined, { minimumFractionDigits: 1 })
                  : mainValue?.toLocaleString()
              : "n/a"}
            {mainValue && maxRating ? `/${maxRating}` : ""}
            {mainValue && isPercentage ? "%" : ""}
          </div>

          {subText && (
            <div className="sub-text-container">
              <div className="sub-text">{subText}</div>
              {subTextTooltipText && (
                <Tooltip
                  position="top-end"
                  className={"normal_tippy"}
                  trigger="mouseenter"
                  animation="none"
                  interactive={true}
                  delay={1}
                  hideDelay={1}
                  duration={1}
                  title={subTextTooltipText}
                  size={"small"}
                >
                  <span className="info_tooltip" />
                </Tooltip>
              )}
            </div>
          )}

          <div className="variance">
            <div className="yoy-variance">
              <div>
                <Ratings value={yoyValue as number} isPercentageValue={false} testValue={false} />
              </div>
              {yoyValue ? (isPercentage ? yoyValue?.toLocaleString(undefined, { minimumFractionDigits: 1 }) : yoyValue) : "n/a"}
              {yoyValue ? (isRating || isPercentage ? "" : "%") : ""} {(isRating || isPercentage) && yoyValue ? "PTS" : ""} YoY
            </div>
            {showPoP && !hidePoP && !isNull(popValue) && (
              <div className="pop-variance">
                <Ratings value={popValue ?? 0} isPercentageValue={false} testValue={false} />
                {popValue ? (isPercentage ? popValue?.toLocaleString(undefined, { minimumFractionDigits: 1 }) : popValue) : "n/a"}
                {popValue ? (isRating || isPercentage ? "" : "%") : ""} {(isRating || isPercentage) && popValue ? "PTS" : ""} {PoPLabel}
              </div>
            )}
          </div>
        </div>

        {hasPreviousYear && (
          <div>
            <div>Previous period</div>

            <div className="value">
              {!isNull(previousValue)
                ? abbreviateMainValue
                  ? formatToThreeDigits(previousValue as number)
                  : isPercentage
                    ? previousValue?.toLocaleString(undefined, { minimumFractionDigits: 1 })
                    : previousValue?.toLocaleString()
                : "n/a"}
              {previousValue && maxRating ? `/${maxRating}` : ""}
              {previousValue && isPercentage ? "%" : ""}
            </div>
          </div>
        )}
      </div>

      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};

export default MetricTile;
