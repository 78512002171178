import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchEcommerceLastDataRefresh, setPageName } from "../../actions";
import { fetchInfluenceOfShoppingTools, fetchNonTdsLeads, fetchOrders, fetchTdsLeads } from "../../actions/ecommerceAtions";
import "../../assets/styles/pages/ecommerce.scss";
import EcommerceFilters from "../../components/Filters/EcommerceFilters";
import { DashboardLayout } from "../../components/Layouts";
import { DefaultPageContent } from "../../components/PageContent/DefaultPageContent";
import { PageTitle } from "../../components/PageContent/subs";
import EcommerceOrders from "./components/EcommerceOrders";
import InfluenceShoppingTools from "./components/InfluenceShoppingTools";
import Leads from "./components/Leads";
import VisistsDropoff from "./components/VisistsDropoff";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import OrderSummary from "./components/OrderSummary";
import GlobalKeyIndicators from "./components/GlobalKeyIndicators";
import SaleTrendCompatedToDis from "./components/SaleTrendCompatedToDis";
import SalesBreakdownByModel from "./components/SalesBreakdownByModel";
import TotalOrderValueTrend from "./components/TotalOrderValueTrend";
import TotalOrderValueTable from "./components/TotalOrderValueTable";
import OrderValueByExtras from "./components/OrderValueByExtras";
import TotalExtrasOrdersTable from "./components/TotalExtrasOrdersTable";
import OrderNumberWithUpsell from "./components/OrderNumberWithUpsell";
import OrderValueWithUpsell from "./components/OrderValueWithUpsell";
import VerbatimTable from "./components/VerbatimTable";

const Ecommerce = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  const { market: marketParam } = useSelector((state: RootStateOrAny) => state.parameters)
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ecommerce_last_data_refresh);

  useEffect(() => {
    dispatch(setPageName("Ecommerce Performance"));
    dispatch(fetchEcommerceLastDataRefresh());
    eventTracking(MixpanelEvents.page_view, { dashboard: "Ecommerce" });
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchInfluenceOfShoppingTools());
    dispatch(fetchOrders());
    dispatch(fetchTdsLeads());
    dispatch(fetchNonTdsLeads());
  }, [history.location.search, dispatch]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<EcommerceFilters />}
        dataDocumentation="ecommerce"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="ecommerce" />}
      >
        <div className="ecommerce-page-container">
          <OrderSummary />
          <div className="row map-row">
            <GlobalKeyIndicators />
            <div className="row-2">
              <VisistsDropoff />
              <EcommerceOrders />
            </div>
          </div>
          {marketParam != "All" && (
            <div className="row">
              <SaleTrendCompatedToDis />
              <SalesBreakdownByModel />
            </div>
          )}
          <div className="row">
            <TotalOrderValueTrend />
            <TotalOrderValueTable />
          </div>
          <div className="row">
            <OrderValueByExtras />
            <TotalExtrasOrdersTable />
          </div>
          <div className="row">
            <InfluenceShoppingTools />
            <Leads />
          </div>
          <div className="row">
            <OrderNumberWithUpsell />
            <OrderValueWithUpsell />
          </div>
          <VerbatimTable />
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default Ecommerce;
